import useCategoriesTree from '../../brand/queries/useCategoriesTree';
import useProductsCategoriesTree from '../../brand/hooks/useProductsCategoriesTree';
import type Category from '../types/category';

const useStockListCategories = ({
    filters,
    categoryId,
}: {
    filters: {
        attribute: string;
        value: string;
    }[];
    categoryId: string;
}) => {
    const mappedFilters = filters.reduce(
        (result, f) => {
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            if (result[f.attribute]) {
                result[f.attribute].push(f.value);
                return result;
            }
            return {
                ...result,
                [f.attribute]: [f.value],
            };
        },
        {} as Record<string, string[]>
    );

    const categoriesTree = useCategoriesTree();
    const { loading, items } = useProductsCategoriesTree({
        categoryId,
        filters: Object.entries(mappedFilters).map(([name, values]) => ({
            name,
            values,
        })),
        skip: false,
        categoriesTree: categoriesTree.tree,
    });

    return {
        items: items[0]?.children as Category[] | undefined,
        loading,
    };
};

export default useStockListCategories;
