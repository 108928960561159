/* eslint-disable no-underscore-dangle */
import type { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import parseSearch from '../../core/util/parseSearch';
import filterByVisibility from '../util/filterByVisibility';
import { getAccessToken } from '../../core/util/auth/credentialsStorage';
import loadStoryblokBridge from './loadStoryblokBridge';

const Storyblok: {
    storeSlug: Application | 'staging';
    status: {
        isPreview: boolean | undefined;
        version: 'draft' | 'published';
        language: string | undefined;
    };
    formatSlug(store: string, language: string, slug: string): string;
} = {
    // eslint-disable-next-line no-restricted-syntax
    get storeSlug() {
        if (sessionStorage.getItem('_storyblok_environment')) {
            return 'staging';
        }
        return APP_ENV.APPLICATION;
    },
    // eslint-disable-next-line no-restricted-syntax
    get status() {
        const urlParams = parseSearch<{
            _storyblok?: string;
            _storyblok_published?: string;
            _storyblok_lang?: 'default' | string;
        }>(window.location.search);

        const isPreview =
            urlParams._storyblok || urlParams._storyblok_published;

        return {
            isPreview: !!isPreview,
            version: urlParams._storyblok
                ? 'draft'
                : ('published' as 'draft' | 'published'),
            language:
                urlParams._storyblok_lang === 'default'
                    ? ''
                    : urlParams._storyblok_lang,
        };
    },
    formatSlug: (store: string, language: string, slug: string) => {
        const storyblokLanguage = Storyblok.status.isPreview
            ? Storyblok.status.language
            : language;
        const languagePrefix = storyblokLanguage ? `${storyblokLanguage}/` : '';
        return `${languagePrefix}${Storyblok.storeSlug}/${store}/${slug}`;
    },
};

/// We use reference to client because we can't unsubscribe but need current content client in handlers
export const loadStoryblokPreviewMode = (clientRef: {
    current: ApolloClient<NormalizedCacheObject>;
}) => {
    if (Storyblok.status.isPreview) {
        loadStoryblokBridge(() => {
            const storyblokInstance = new StoryblokBridge();

            if (Storyblok.status.isPreview) {
                storyblokInstance.pingEditor(() => {
                    if (storyblokInstance.inEditor) {
                        storyblokInstance.enterEditmode();
                    }
                });

                storyblokInstance.on(['change', 'published'], async () => {
                    await clientRef.current.cache.reset();
                    await clientRef.current.reFetchObservableQueries();
                });

                storyblokInstance.on(['input'], async (payload: unknown) => {
                    const { story } = payload as {
                        story: {
                            id: string;
                            lang: string;
                            content: { component: 'page' | 'post' };
                        };
                    };
                    const {
                        id,
                        lang,
                        content,
                        content: { component },
                    } = story;
                    const componentUpperCase =
                        component.charAt(0).toUpperCase() + component.slice(1);
                    const identity = {
                        __typename: `${componentUpperCase}Item`,
                        lang: lang || 'default',
                        id,
                    };

                    const isLogged = !!getAccessToken();
                    const now = new Date();

                    clientRef.current.cache.modify({
                        id: clientRef.current.cache.identify(identity),
                        fields: {
                            content: () =>
                                filterByVisibility(content, {
                                    isLogged,
                                    now,
                                }),
                        },
                    });
                });
            }
        });
    }
};

export default Storyblok;
