import type { ReactNode } from 'react';
import { memo } from 'react';
import { clsx } from 'clsx';
import { Stack } from '@library';
import type { StackProps } from '../layout/Stack';
import styles from './AlertBanner.module.scss';

interface AlertBannerProps {
    /**
     * Information banner
     */
    information?: boolean;

    /**
     * Danger banner
     */
    danger?: boolean;
    /**
     * Optional icon to show in the banner
     */
    icon?: ReactNode;
    children: ReactNode;
    /**
     * Actionable content to show in the alert
     */
    action?: ReactNode;
}

const AlertBanner = ({
    information,
    danger,
    icon,
    children,
    action,
    align,
    ...props
}: AlertBannerProps & Pick<StackProps, 'align'>) => {
    return (
        <div
            className={clsx(styles.alertBanner, {
                [styles.information]: information,
                [styles.danger]: danger,
            })}
            role="alert"
            {...props}
        >
            <Stack
                gap="small"
                direction="responsiveColumnTabletRow"
                align="responsiveDefaultTabletCenter"
            >
                <Stack
                    gap="small"
                    direction="responsiveColumnTabletRow"
                    align={align}
                >
                    {icon && <div className={styles.icon}>{icon}</div>}
                    <div className={styles.content}>{children}</div>
                </Stack>
                {action && <div className={styles.actionButton}>{action}</div>}
            </Stack>
        </div>
    );
};

export default memo(AlertBanner);
