import { useMatchMedia } from '@library';

export const MOBILE = 'only screen and (max-width: 1100px)';

export const IPAD_LANDSCAPE = `only screen and (min-device-width: 834px) and (max-device-width: 834px) and (min-device-height: 1112px) and (max-device-height: 1112px) and (-webkit-device-pixel-ratio: 2),
     only screen and (min-device-width: 1024px) and (max-device-width: 1024px)  and (min-device-height: 1366px) and (max-device-height: 1366px) and (-webkit-device-pixel-ratio: 2)`;

const useTabletViewMatchMedia = () => {
    return useMatchMedia(`${MOBILE}, ${IPAD_LANDSCAPE}`);
};

export default useTabletViewMatchMedia;
