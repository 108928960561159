import { memo } from 'react';
import type { BaseButtonProps } from './BaseButton';
import BaseButton from './BaseButton';
interface LinkProps extends Omit<BaseButtonProps, 'loading' | 'component'> {
    /**
     * The url link
     */
    href?: string;
    /**
     * The html target attribute
     */
    target?: '_blank' | '_self';
    /**
     * The html download attribute
     */
    download?: string | true;
    /**
     * The loading state for downloads
     */
    loading?: boolean;
}
const LinkButton = ({
    children,
    href,
    target,
    download,
    ...props
}: LinkProps) => {
    return (
        <BaseButton
            {...props}
            component={
                // eslint-disable-next-line jsx-a11y/anchor-has-content
                <a
                    href={href}
                    target={target}
                    download={download}
                    rel="noreferrer"
                />
            }
        >
            {children}
        </BaseButton>
    );
};

export default memo(LinkButton);
