import { memo } from 'react';
import type { ChangeEvent, ReactNode } from 'react';
import { clsx } from 'clsx';
import { HStack } from '@library';
import styles from './Switch.module.css';

interface SwitchProps {
    /**
     * The name of the switch
     */
    name: string;
    /**
     * The value of switch
     */
    value?: boolean;
    onChange(value: boolean): void;
    /**
     * The disabled state
     */
    disabled?: boolean;
    children?: ReactNode;
}

const Switch = ({
    value = false,
    disabled = false,
    name,
    onChange,
    children,
    ...ariaProps
}: SwitchProps) => {
    return (
        <HStack align="center" gap="micro">
            {children}
            <label
                {...ariaProps}
                htmlFor={name}
                className={clsx(styles.switch, {
                    [styles.active]: value,
                    [styles.disabled]: disabled,
                })}
            >
                <input
                    id={name}
                    name={name}
                    checked={value}
                    disabled={disabled}
                    className={styles.input}
                    type="checkbox"
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        onChange(e.target.checked)
                    }
                />
                <span className={clsx(styles.slider)} />
            </label>
        </HStack>
    );
};

export default memo(Switch);
