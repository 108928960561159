import type { ReactNode } from 'react';
import { memo, useEffect, useRef, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { Settings } from 'luxon';
import type { Language } from '../../types';
import { isLanguage } from '../../types';
import useStoreAndLanguageParams from '../../hooks/useStoreAndLanguageParams';
import initIntlNumber from '../../polyfills/initIntlNumber';
import assert from '../../util/assert';
import useCurrentStore from '../../hooks/useCurrentStore';

const loadedMessages: {
    [key in Language]?: Record<string, string>;
} = {};

export const fetchMessagesForLanguage = async (language: Language) => {
    const messages = (await import(`../../../intl/locales/${language}.json`))
        .default;

    loadedMessages[language] = messages;
    return messages;
};

const IntlStoreProvider = memo(({ children }: { children: ReactNode }) => {
    const { language: languageFromParam } = useStoreAndLanguageParams();
    const store = useCurrentStore();

    const [messages, setMessages] = useState<
        Record<string, string> | undefined
    >(undefined);

    const envLanguage = sessionStorage.getItem('language');
    const language = isLanguage(envLanguage)
        ? envLanguage
        : // TODO: fix the type in useStoreAndLanguageParams
          (languageFromParam as Language | undefined) || 'de';

    const currentLanguageRef = useRef<Language>(language);

    const [loading, setLoading] = useState(false);

    const languageDetails = store.languages.find(
        languageItem => languageItem.language === language
    );

    const locale = languageDetails?.locale || languageFromParam;

    Settings.defaultLocale = locale;

    useEffect(() => {
        (async () => {
            assert(isLanguage(language), `${language} is not a language`);

            setLoading(true);
            currentLanguageRef.current = language;
            const currentMessages = await fetchMessagesForLanguage(language);

            await initIntlNumber(locale);

            // language may change during load
            if (currentLanguageRef.current === language) {
                setMessages(currentMessages);
            }
        })()
            .catch(error => {
                // TODO: add to central error handling
                /* eslint-disable-next-line no-console */
                console.error(error);
                window.location.reload();
            })
            .finally(() => {
                // TODO: migrate to useAsyncOperation
                setLoading(false);
            });
    }, [language, locale]);

    const messagesAreNotReady = loading || !messages;
    return (
        <IntlProvider
            locale={locale}
            defaultLocale="de-CH"
            messages={messages ?? {}}
            onError={messagesAreNotReady ? () => {} : undefined}
        >
            <div style={{ display: messagesAreNotReady ? 'none' : 'block' }}>
                {children}
            </div>
        </IntlProvider>
    );
});

export default IntlStoreProvider;
