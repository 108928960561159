/* eslint no-restricted-syntax: 0 */
import { Observable } from '@apollo/client';

export interface ObservableVariable<T> {
    readonly currentValue: T;
    readonly onValueChange: Observable<T>;
    updateValue(newValue: T): void;
}

export interface ReadonlyObservableVariable<T> {
    readonly currentValue: T;
    readonly onValueChange: Observable<T>;
}

const makeObservableVariable = <T>(
    initialValue: T,
    notReplayOnSubscribe = false
): ObservableVariable<T> => {
    let currentValue = initialValue;

    const subscribers: ZenObservable.SubscriptionObserver<T>[] = [];

    const onValueChange = new Observable<T>(subscriber => {
        subscribers.push(subscriber);
        if (!notReplayOnSubscribe) {
            setTimeout(() => {
                subscriber.next(currentValue);
            }, 0);
        }
        return () => {
            subscribers.splice(subscribers.indexOf(subscriber), 1);
        };
    });

    return {
        get currentValue() {
            return currentValue;
        },
        get onValueChange() {
            return onValueChange;
        },
        updateValue: (newValue: T) => {
            currentValue = newValue;
            for (const subscriber of subscribers) {
                subscriber.next(currentValue);
            }
        },
    };
};

export default makeObservableVariable;
