import { useContext } from 'react';
import { CartContext } from '../../core/cartContext';
import useObservable from '../../core/hooks/useObservable';

const useCartItems = () => {
    const { cart } = useContext(CartContext);
    return useObservable(cart.items) ?? [];
};

export default useCartItems;
