import type { Browser } from 'detect-browser';
import { detect } from 'detect-browser';
import getCurrentLanguageFromPath from './util/getCurrentLanguageFromPath';

const browser = detect();

const getBaseUri = () => {
    let { baseURI } = document;
    // IE
    if (!baseURI) {
        const baseTags = document.getElementsByTagName('base');
        baseURI = baseTags.length ? baseTags[0].href : document.URL;
    }

    return baseURI;
};

const lang = getCurrentLanguageFromPath(document.location.href, getBaseUri());

const messages = {
    en: {
        title: 'Browser not supported',
        message:
            'Your browser is not supported, we recommend you switch to one of our supported browsers, such as Microsoft Edge, Google Chrome, Mozilla Firefox, or Apple Safari.',
    },
    de: {
        title: 'Browser nicht unterstützt',
        message:
            'Ihr Browser wird leider nicht unterstützt. Wir empfehlen Ihnen, einen von uns unterstützten Browser wie zum Beispiel Microsoft Edge, Google Chrome, Mozilla Firefox oder Apple Safari zu verwenden.',
    },
    fr: {
        title: 'TR FR: Browser not supported',
        message:
            'TR FR: Your browser is not supported, we recommend you switch to one of our supported browsers, such as Microsoft Edge, Google Chrome, Mozilla Firefox, or Apple Safari.',
    },
    it: {
        title: 'TR IT: Browser not supported',
        message:
            'TR FR: Your browser is not supported, we recommend you switch to one of our supported browsers, such as Microsoft Edge, Google Chrome, Mozilla Firefox, or Apple Safari.',
    },
} as Record<
    string,
    | {
          title: string;
          message: string;
      }
    | undefined
>;

// TODO Make this rules stricter each release
if (browser) {
    const version = parseInt(browser.version || '', 10);

    const rules: {
        browser: Browser;
        version?: number;
    }[] = [
        {
            browser: 'ie',
        },
        {
            browser: 'opera-mini',
        },
        {
            browser: 'safari',
            version: 14.1,
        },
        {
            browser: 'ios',
            version: 14.1,
        },
        {
            browser: 'chrome',
            version: 87,
        },
        {
            browser: 'firefox',
            version: 78,
        },
        {
            browser: 'edge',
            version: 88,
        },
        {
            browser: 'samsung',
            version: 11,
        },
    ];

    const isOld = rules.some(condition => {
        return (
            browser.name === condition.browser &&
            (!condition.version ||
                (condition.version && version < condition.version))
        );
    });

    if (isOld) {
        const appRoot = document.getElementById('root');
        if (appRoot) {
            const { parentNode, parentElement } = appRoot;
            parentNode?.removeChild(appRoot);

            const locale = messages[lang];

            parentElement?.insertAdjacentHTML(
                'beforeend',
                `<div style='margin-top: 200px; width: 100%;'>
                                                <div style='margin: auto; max-width: 500px'>
                                                   <div style='margin-bottom: 30px; font-size: 16px;text-align: center'>${locale?.title}</div>
                                                   <div style='text-align: center; line-height: 1.5'>${locale?.message}</div>
                                                </div>
                                   </div>`
            );
        }
    }
}

export {};
