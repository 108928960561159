import { gql } from '@apollo/client';
import { useContext } from 'react';
import useCancelableQuery from '../../core/hooks/apollo/useCancelableQuery';
import useStoreAndLanguageParams from '../../core/hooks/useStoreAndLanguageParams';
import PreorderModeContext from '../../preorder/PreorderModeContext';
import type { Product } from '../types';

const productListQuery = gql`
    query productListQuery(
        $store: ID!
        $language: Language!
        $pageSize: Int
        $page: Int
        $sortField: ProductSortField!
        $sortDirection: SortDirection!
        $search: String
        $preorderSeasonId: Int
        $includePreorderSeasonId: Int
    ) {
        products(
            store: $store
            language: $language
            pageSize: $pageSize
            page: $page
            sortField: $sortField
            sortDirection: $sortDirection
            search: $search
            preorderSeasonId: $preorderSeasonId
            includePreorderSeasonId: $includePreorderSeasonId
            includeExcludedCategories: false
        ) {
            items {
                id
                key
                name
                isNew: new
                onSale
                onSale
                manufacturer
                sku
                summary
                media {
                    type
                    url
                    renditionUrl
                }
                tileAttributes {
                    name
                    value
                }
            }
            total
        }
    }
`;

export type SearchResultProduct = Pick<
    Product,
    | 'key'
    | 'name'
    | 'id'
    | 'isNew'
    | 'onSale'
    | 'media'
    | 'tileAttributes'
    | 'manufacturer'
    | 'sku'
> & {
    manufacturer: string;
    tileAttributes: [];
};

const useProductSearch = ({
    search,
    skip,
    pageSize = 12,
}: {
    search: string;
    skip?: boolean;
    pageSize?: number;
}) => {
    const { store, language } = useStoreAndLanguageParams();
    const { productsFilters } = useContext(PreorderModeContext);

    const {
        loading,
        data: { products: { items, total } } = {
            products: { items: [], total: 0 },
        },
        error,
    } = useCancelableQuery<{
        products: {
            items: SearchResultProduct[];
            total: number;
        };
    }>(
        productListQuery,
        {
            variables: {
                pageSize,
                store,
                language,
                sortField: 'retailPrice',
                sortDirection: 'desc',
                search,
                ...productsFilters,
            },
            skip,
            fetchPolicy: 'cache-first',
        },
        true
    );

    return {
        loading,
        products: items,
        total,
        error,
    };
};

export default useProductSearch;
