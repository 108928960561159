import { gql, useQuery } from '@apollo/client';
import useStoreAndLanguageParams from '../hooks/useStoreAndLanguageParams';
import Storyblok from '../../content/storyblok/storyblok';
import useContentClient from '../../content/hooks/useContentClient';

export interface StockListSchema {
    _uid: string;
    title: string;
    category_node: string;
    filters: {
        attribute: string;
        component: string;
        value: string;
        _uid: string;
    }[];
}

const stockListQuery = gql`
    query stockListsItems($start_with: String) {
        StocklistsItems(starts_with: $start_with) {
            items {
                id
                name
                content {
                    stocklists
                    categoryId
                }
            }
        }
    }
`;

const useStockLists = (skip?: boolean) => {
    const { store, language } = useStoreAndLanguageParams();
    const client = useContentClient();
    return useQuery<{
        StocklistsItems: {
            items: {
                id: string;
                content: {
                    stocklists: StockListSchema[];
                    categoryId: string;
                };
            }[];
        };
    }>(stockListQuery, {
        variables: {
            start_with: Storyblok.formatSlug(store, language, 'stocklists/'),
        },
        client,
        skip,
    });
};

export default useStockLists;
