import type { AriaAttributes, ReactNode, MouseEvent } from 'react';
import { memo, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { clsx } from 'clsx';

import useCustomNavigate from '../hooks/router/useCustomNavigate';
import { ProgressSpinner, SmallProgressIndicator } from './ProgressIndicators';
import styles from './Button.module.scss';
import ButtonTypography from './button/ButtonTypography';

const isMultiState = ({
    primary,
    secondary,
    tertiary,
    textOnly,
    iconButton,
    textOnlySecondary,
    success,
    light,
}: {
    primary: boolean;
    secondary: boolean;
    tertiary: boolean;
    textOnly: boolean;
    iconButton: boolean;
    textOnlySecondary: boolean;
    success: boolean;
    light: boolean;
}) => {
    return (
        [
            primary,
            secondary,
            tertiary,
            textOnly,
            iconButton,
            textOnlySecondary,
            success,
            light,
        ].filter(state => state).length > 1
    );
};

const isNoState = ({
    primary,
    secondary,
    tertiary,
    textOnly,
    iconButton,
    textOnlySecondary,
    success,
    light,
}: {
    primary: boolean;
    secondary: boolean;
    tertiary: boolean;
    textOnly: boolean;
    iconButton: boolean;
    textOnlySecondary: boolean;
    success: boolean;
    light: boolean;
}) => {
    return (
        [
            primary,
            secondary,
            tertiary,
            textOnly,
            iconButton,
            textOnlySecondary,
            success,
            light,
        ].filter(state => state).length === 0
    );
};

export interface ButtonProps extends AriaAttributes {
    primary?: boolean;
    secondary?: boolean;
    tertiary?: boolean;
    success?: boolean;
    light?: boolean;
    wrap?: boolean;
    textOnly?: boolean;
    textOnlySecondary?: boolean;
    iconButton?: boolean;
    bordered?: boolean;
    rounded?: boolean;
    autoSize?: boolean;
    onPress?: (event: MouseEvent<HTMLButtonElement>) => void;
    linkTo?: string;
    disabled?: boolean;
    noPadding?: boolean;
    loading?: boolean;
    fullWidth?: boolean;
    children?: ReactNode;
    messageId?: string;
    submit?: boolean;
    size?: 'small' | 'medium';
    inactive?: boolean;
    lineHeightAsFontSize?: boolean;
    outlineLight?: boolean;
    danger?: boolean;
    uppercase?: boolean;
}

const ButtonContent = memo(
    ({
        loading,
        iconButton,
        children,
        messageId,
    }: {
        loading?: boolean;
        iconButton?: boolean;
        children: ReactNode;
        messageId?: string;
    }) => {
        if (loading) {
            return iconButton ? (
                <ProgressSpinner />
            ) : (
                <SmallProgressIndicator />
            );
        }

        if (children) {
            return <>{children}</>;
        }

        if (messageId) {
            return <FormattedMessage id={messageId} />;
        }

        return null;
    }
);

const Button = ({
    primary = false,
    secondary = false,
    tertiary = false,
    success = false,
    light = false,
    wrap = false,
    textOnly = false,
    textOnlySecondary = false,
    iconButton = false,
    bordered = false,
    outlineLight = false,
    autoSize = false,
    noPadding = false,
    rounded = false,
    size = 'medium',
    onPress,
    linkTo,
    children,
    messageId,
    disabled = false,
    loading = false,
    fullWidth = false,
    submit = false,
    inactive = false,
    danger = false,
    lineHeightAsFontSize,
    uppercase = false,
    ...ariaProps
}: ButtonProps) => {
    const navigate = useCustomNavigate();

    const states = {
        primary,
        secondary,
        tertiary,
        textOnly,
        iconButton,
        textOnlySecondary,
        success,
        light,
        outlineLight,
        danger,
    };

    const primaryByDefault = isNoState(states);

    if (isMultiState(states)) {
        throw new Error(
            `A button can be only one of ${Array.from(Object.keys(states)).join(
                ', '
            )}. Multiple states were provided.`
        );
    }

    if (!!children && !!messageId) {
        throw new Error(
            'Both children and messageId props were provided to the button which is not allowed!'
        );
    } else if (!children && !messageId) {
        throw new Error(
            'Either a child component or a messageId must be provided to the button!'
        );
    }

    const [isLoading, setIsLoading] = useState(loading);

    useEffect(() => {
        setIsLoading(loading);
    }, [loading]);

    const onPressHandler = async (e: MouseEvent<HTMLButtonElement>) => {
        setIsLoading(true);
        if (onPress) {
            onPress(e);
        } else if (linkTo) {
            navigate(linkTo);
        }
        setIsLoading(loading || false);
    };

    return (
        <button
            className={clsx(
                styles.button,
                {
                    [styles.primary]: primary || primaryByDefault,
                    [styles.secondary]: secondary,
                    [styles.success]: success,
                    [styles.light]: light,
                    [styles.wrap]: wrap,
                    [styles.tertiary]: tertiary,
                    [styles.textOnly]: textOnly,
                    [styles.textOnlySecondary]: textOnlySecondary,
                    [styles.iconButton]: iconButton,
                    [styles.noPadding]: noPadding,
                    [styles.fullWidth]: fullWidth,
                    [styles.bordered]: bordered,
                    [styles.autoSize]: autoSize,
                    [styles.disabledOrLoading]: disabled || isLoading,
                    [styles.rounded]: rounded,
                    [styles.inactive]: inactive,
                    [styles.outlineLight]: outlineLight,
                    [styles.danger]: danger,
                },
                styles[`${size}Size`]
            )}
            disabled={disabled}
            onClick={onPressHandler}
            type={submit ? 'submit' : 'button'}
            data-test-class="Button"
            {...ariaProps}
        >
            <ButtonTypography
                tertiary={tertiary}
                textOnly={textOnly || textOnlySecondary}
                iconButton={iconButton}
                size={size}
                lineHeightAsFontSize={lineHeightAsFontSize}
                uppercase={uppercase}
            >
                <ButtonContent
                    loading={loading}
                    iconButton={iconButton}
                    messageId={messageId}
                >
                    {children}
                </ButtonContent>
            </ButtonTypography>
        </button>
    );
};
/**
 * @deprecated
 * Please use Button from library
 */
export default memo(Button);
