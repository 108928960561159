import { memo } from 'react';
import { clsx } from 'clsx';
import type { IconProps } from './types';
import styles from './CloseIcon.module.css';

const CloseIcon = ({
    iconLabel,
    color,
    small = false,
    mini = false,
    bold = false,
}: IconProps & {
    /**
     * Small size
     */
    small?: boolean;
    /**
     * Mini size
     */
    mini?: boolean;
    /**
     * Bold icon
     */
    bold?: boolean;
}) => {
    const strokeWidth = mini || bold ? 2 : 1;
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 13.016 13.016"
            data-icon={iconLabel}
            color={color}
            className={clsx(styles.icon, {
                [styles.iconSmall]: small,
                [styles.iconMini]: mini,
            })}
        >
            <g transform="translate(-1221.99 -228.177)">
                <path
                    fill="none"
                    stroke="currentColor"
                    d="M-4588.657,240.839l12.308-12.308"
                    transform="translate(5811)"
                    strokeWidth={strokeWidth}
                />
                <path
                    fill="none"
                    stroke="currentColor"
                    d="M-4576.348,240.839l-12.309-12.308"
                    transform="translate(5811)"
                    strokeWidth={strokeWidth}
                />
            </g>
        </svg>
    );
};

export default memo(CloseIcon);
