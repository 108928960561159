import { memo } from 'react';
import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { TextBlock } from '@library';
import CurrentStoreLink from '../router/CurrentStoreLink';
import styles from './SidePaneMenuStyles.module.scss';

const SidePaneMenuLink = ({
    to,
    medium,
    onClick,
    children,
    messageId,
}: {
    to: string;
    medium?: boolean;
    onClick: () => void;
    children?: ReactNode;
    messageId?: string;
}) => {
    return (
        <CurrentStoreLink
            to={to}
            className={styles.categoryMenuItem}
            onClick={onClick}
            activeClassName={styles.categoryMenuLinkActive}
        >
            <TextBlock secondary medium={medium}>
                <span className={styles.categoryMenuItemText}>
                    {messageId ? <FormattedMessage id={messageId} /> : children}
                </span>
            </TextBlock>
        </CurrentStoreLink>
    );
};

export default memo(SidePaneMenuLink);
