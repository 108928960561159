export interface BaseClickEvent {
    /**
     *
     */
    preventDefault: () => void;
    /**
     *
     */
    stopPropagation: () => void;
}

export interface ClickableAriaOptions {
    /**
     *
     */
    onPress: (e: BaseClickEvent) => void;
    /**
     *
     */
    onKeyDown?: (e?: BaseClickEvent) => void;
    /**
     *
     */
    tabIndex?: number;
    /**
     *
     */
    role?: string;
}

const getClickableAriaOptions = ({
    onPress,
    onKeyDown,
    ...options
}: ClickableAriaOptions) => {
    return {
        role: 'button',
        tabIndex: 0,
        onClick: onPress,
        onKeyDown: (
            event: {
                /**
                 *
                 */
                key?: string;
            } & BaseClickEvent
        ) => {
            if (event.key === 'Enter' || event.key === ' ') {
                onPress(event);
            }
            if (onKeyDown) {
                onKeyDown(event);
            }
        },
        ...options,
    };
};

export default getClickableAriaOptions;
