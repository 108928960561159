import { memo } from 'react';
import type { IconProps } from './types';

const strokeProps = {
    fill: '#fff',
    stroke: 'currentColor',
    strokeMiterlimit: '10',
};

const BicycleSearchIcon = ({ iconLabel, color }: IconProps) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30.799"
            height="17.052"
            viewBox="0 0 30.799 17.052"
            data-icon={iconLabel}
            color={color}
        >
            <g transform="translate(-848.959 -936.77)">
                <g transform="translate(863.206 937.27)">
                    <circle
                        fill="none"
                        stroke="currentColor"
                        strokeMiterlimit="10"
                        cx="8.026"
                        cy="8.026"
                        r="8.026"
                        transform="translate(0 0)"
                    />
                    <line
                        {...strokeProps}
                        y2="16.052"
                        transform="translate(8.026 0)"
                    />
                    <line
                        {...strokeProps}
                        x1="16.052"
                        transform="translate(0 8.026)"
                    />
                    <line
                        {...strokeProps}
                        x1="11.35"
                        y2="11.35"
                        transform="translate(2.351 2.351)"
                    />
                    <line
                        {...strokeProps}
                        x1="11.35"
                        y1="11.35"
                        transform="translate(2.351 2.351)"
                    />
                </g>
                <g transform="translate(849.459 937.27)">
                    <path
                        fill="none"
                        stroke="currentColor"
                        strokeMiterlimit="10"
                        d="M863.4,950.725a8.026,8.026,0,1,1-.284-11.152q.142.14.277.286"
                        transform="translate(-849.459 -937.27)"
                    />
                    <line
                        {...strokeProps}
                        y2="16.052"
                        transform="translate(8.026 0)"
                    />
                    <line
                        {...strokeProps}
                        x1="12.206"
                        transform="translate(0 8.026)"
                    />
                    <line
                        {...strokeProps}
                        x1="11.35"
                        y2="11.35"
                        transform="translate(2.351 2.351)"
                    />
                    <line
                        {...strokeProps}
                        x1="11.35"
                        y1="11.35"
                        transform="translate(2.351 2.351)"
                    />
                </g>
            </g>
        </svg>
    );
};

export default memo(BicycleSearchIcon);
