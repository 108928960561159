import { memoize } from 'lodash-es';
import type * as SentryType from '@sentry/react';
import escapeStringRegexp from '../core/util/escapeStringRegexp';
import requestsLog from '../core/services/RequestsLog';

const ignoredErrorMessagePatterns = [
    'Non-Error promise rejection captured with value: Object Not Found Matching Id:',
    'Message did timeout',
    // cSpell:words instantSearchSDKJSBridgeClearHighlight
    "Can't find variable: instantSearchSDKJSBridgeClearHighlight",
    'Illegal invocation',
    'Response not successful: Received status code 400',
    'NetworkError when attempting to fetch resource',
    'Failed to fetch',
    'Load failed',
].map(item => new RegExp(escapeStringRegexp(item), 'ig'));

const addRequestsLogToEvent = (event: SentryType.Event) => {
    for (const {
        queryName,
        data,
        errors,
        timeTaken,
        timestamp,
    } of requestsLog.getRequestsWithError()) {
        event.breadcrumbs?.push({
            type: 'debug',
            category: 'http',
            level: 'error',
            timestamp,
            message: `${queryName} took ${timeTaken}`,
            data: {
                data,
                errors,
            },
        });
    }
    for (const {
        queryName,
        data,
        errors,
        timeTaken,
        timestamp,
    } of requestsLog.getSuccessfulRequests()) {
        event.breadcrumbs?.push({
            type: 'debug',
            category: 'http',
            level: 'info',
            timestamp,
            message: `${queryName} took ${timeTaken}`,
            data: {
                data,
                errors,
            },
        });
    }
    requestsLog.flushOldErrors();
    return event;
};

const initSentry = memoize(async () => {
    const Sentry = await import('@sentry/react');
    const Tracing = await import('@sentry/tracing');
    const Integrations = await import('@sentry/integrations');

    if (APP_ENV.SENTRY_DSN) {
        Sentry.init({
            dsn: APP_ENV.SENTRY_DSN,
            environment: APP_ENV.SENTRY_ENV,
            integrations: [
                // Dedupe is causing us more harm than good.
                // If an exception is captured both with and without scope,
                // Dedupe ignores the one with scope because it happens
                // later. This also ignores whether or not the event is
                // actually sent to Sentry, which is bad.
                ...Sentry.defaultIntegrations.filter(
                    item => item.name !== 'Dedupe'
                ),
                new Tracing.Integrations.BrowserTracing(),
                new Integrations.ExtraErrorData(),
            ],
            sampleRate: 1, // Sampling rate for errors
            tracesSampleRate: 0, // Sampling rate for traces (performance related tracking)
            release: `webshop@${import.meta.env.VITE__VERSION}`,
            ignoreErrors: ignoredErrorMessagePatterns,
            defaultIntegrations: false,
            maxBreadcrumbs: 100,
            beforeBreadcrumb(breadcrumb) {
                if (
                    breadcrumb.category === 'fetch' &&
                    breadcrumb.data?.url === APP_ENV.API_URL &&
                    breadcrumb.data.status_code === 200
                ) {
                    return null;
                }

                return breadcrumb;
            },
            initialScope: scope => {
                scope.addEventProcessor(addRequestsLogToEvent);
                scope.setTag('application', APP_ENV.APPLICATION);
                return scope;
            },
        });

        return Sentry as typeof SentryType;
    }

    return undefined;
});

export default initSentry;
