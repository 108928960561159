import { useEffect } from 'react';

/* Reference:
   https://github.com/mui-org/material-ui/blob/
   33850e52ca32787fbc02b64d3225bac2d451612c/packages/material-ui-utils/src/getScrollbarSize.ts
*/
const getScrollbarSize = (doc: Document): number => {
    const scrollDiv = doc.createElement('div');
    scrollDiv.style.width = '99px';
    scrollDiv.style.height = '99px';
    scrollDiv.style.position = 'absolute';
    scrollDiv.style.top = '-9999px';
    scrollDiv.style.overflow = 'scroll';

    doc.body.appendChild(scrollDiv);
    const scrollbarSize = scrollDiv.offsetWidth - scrollDiv.clientWidth;
    doc.body.removeChild(scrollDiv);

    return scrollbarSize;
};

const ownerDocument = (node?: Node): Document => {
    return (node && node.ownerDocument) || document;
};

const ownerWindow = (node?: Node): Window => {
    const doc = ownerDocument(node);
    return doc.defaultView || window;
};

const isOverflowing = (container: Element): boolean => {
    const doc = ownerDocument(container);

    if (doc.body === container) {
        return (
            ownerWindow(container).innerWidth > doc.documentElement.clientWidth
        );
    }

    return container.scrollHeight > container.clientHeight;
};

const useNoScrollingWindow = (apply: boolean) => {
    useEffect(() => {
        if (apply && isOverflowing(document.documentElement)) {
            const scrollbarSize = getScrollbarSize(document);

            document.documentElement.classList.add('noScrolling');
            document.documentElement.style.width = `calc(100% - ${scrollbarSize}px)`;
            document.documentElement.style.setProperty(
                '--scrollbar-offset',
                `${scrollbarSize}px`
            );
        }

        return () => {
            document.documentElement.classList.remove('noScrolling');
            document.documentElement.style.width = '100%';
            document.documentElement.style.setProperty(
                '--scrollbar-offset',
                '0'
            );
        };
    }, [apply]);
};
export default useNoScrollingWindow;
