import { gql, useQuery } from '@apollo/client';
import type { CurrentStoresQuery } from '../../user/types';

export const currentStoresQuery = gql`
    query currentStore {
        stores {
            id
            url
            currency
            languages {
                language
                locale
            }
            cartSharingEnabled
            orderCommentsEnabled
            dealerSearchEnabled
            useEmailAddressLabelForUsername
            companyCode
            companyName
            semiFinalPriceForCartBrandTotals
            sparePartSearchEnabled
            customerOnboardingUrl
            stockListEnabledCategories
            country
            companyInfo {
                email
                address1
                address2
                address3
                phone
                country
                copyright
            }
            features
            smallOrderSurchargeThreshold
        }
    }
`;

const useCurrentStoresQuery = () => {
    return useQuery<CurrentStoresQuery>(currentStoresQuery, {
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
    });
};

export default useCurrentStoresQuery;
