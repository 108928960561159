import { useContext } from 'react';
import { AppContext } from '../../context';

const useTabletView = () => {
    const { isInTabletView } = useContext(AppContext);

    return isInTabletView;
};

export default useTabletView;
