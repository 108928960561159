import { clsx } from 'clsx';
import type { ReactNode } from 'react';
import { memo } from 'react';
import styles from './FadeScrollEffect.module.scss';

export interface FadeScrollEffectProps {
    className?: string;
    mode?: 'vertical' | 'horizontal' | 'both';
    isTop?: boolean;
    isLeft?: boolean;
    isBottom?: boolean;
    isRight?: boolean;
    largeFade?: boolean;
    mediumFade?: boolean;
    children: ReactNode;
    tabIndex?: number;
}
const FadeScrollEffect = ({
    className,
    mode,
    isTop,
    isLeft,
    isBottom,
    isRight,
    largeFade,
    mediumFade,
    children,
    ...ariaProps
}: FadeScrollEffectProps) => {
    const isVertical = mode === 'vertical' || mode === 'both';
    const isHorizontal = mode === 'horizontal' || mode === 'both';
    return (
        <div
            className={clsx(styles.container, className, {
                [styles.largeFade]: largeFade,
                [styles.mediumFade]: mediumFade,
            })}
            {...ariaProps}
        >
            {isVertical && (
                <div
                    className={clsx(styles.topFadeEffect, styles.fadeEffect, {
                        [styles.show]: isTop,
                    })}
                />
            )}

            {isHorizontal && (
                <div
                    className={clsx(styles.leftFadeEffect, styles.fadeEffect, {
                        [styles.show]: isLeft,
                    })}
                />
            )}

            {children}

            {isVertical && (
                <div
                    className={clsx(
                        styles.bottomFadeEffect,
                        styles.fadeEffect,
                        {
                            [styles.show]: isBottom,
                        }
                    )}
                    data-test-class="bottomFadeEffect"
                />
            )}

            {isHorizontal && (
                <div
                    className={clsx(styles.rightFadeEffect, styles.fadeEffect, {
                        [styles.show]: isRight,
                    })}
                />
            )}
        </div>
    );
};

export default memo(FadeScrollEffect);
