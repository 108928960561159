import { gql } from '@apollo/client';
import { useContext } from 'react';
import useStoreAndLanguageParams from '../../core/hooks/useStoreAndLanguageParams';
import type { CartPriceTotals } from '../types';
import useCachedQuery from '../../core/hooks/useCachedQuery';
import useCartItems from '../hooks/useCartItems';
import { CartContext } from '../../core/cartContext';

export const localCartPriceSummaryQuery = gql`
    query customerPriceSummary(
        $store: ID!
        $cartType: CartType!
        $items: [CartItemInput!]!
    ) {
        customerPriceSummary(
            store: $store
            cartType: $cartType
            items: $items
        ) {
            store
            retailPrice
            generalDealerPrice
            finalPrice
            dealerDiscountPercentage
        }
    }
`;

const useLocalCartPriceSummary = (): CartPriceTotals => {
    const { store } = useStoreAndLanguageParams();
    const cartItems = useCartItems();

    const { cartType } = useContext(CartContext);

    const { data, error } = useCachedQuery<{
        customerPriceSummary: CartPriceTotals;
    }>(localCartPriceSummaryQuery, {
        variables: {
            store,
            items: cartItems.map(item => ({
                sku: item.sku,
                quantity: item.quantity,
            })),
            cartType: 'user',
        },
        skip: cartItems.length === 0 || cartType !== 'local',
    });

    if (error) {
        throw error;
    }

    return (
        data?.customerPriceSummary || {
            retailPrice: 0,
        }
    );
};

export default useLocalCartPriceSummary;
