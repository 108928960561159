import { memo } from 'react';
import type { IconProps } from './types';

const StockListIcon = ({ iconLabel, color }: IconProps) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="36.348"
            height="17.087"
            viewBox="0 0 36.348 17.087"
            data-icon={iconLabel}
            color={color}
        >
            <g transform="translate(-52.675 -398.954)">
                <g transform="translate(74.579 399.454)">
                    <g style={{ isolation: 'isolate' }}>
                        <g transform="translate(0 4.032)">
                            <path
                                stroke="currentColor"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M77.349,406.712l-.023,8.024L70.343,410.7l.023-8.024Z"
                                transform="translate(-70.343 -402.68)"
                            />
                        </g>
                        <g transform="translate(6.983 4.032)">
                            <path
                                stroke="currentColor"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M75.953,406.712l6.938-4.032-.023,8.024-6.938,4.032Z"
                                transform="translate(-75.931 -402.68)"
                            />
                        </g>
                        <g transform="translate(0.023)">
                            <path
                                stroke="currentColor"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M70.361,403.486l6.938-4.032,6.983,4.032-6.938,4.032Z"
                                transform="translate(-70.361 -399.454)"
                            />
                        </g>
                    </g>
                </g>
                <path
                    fill="#fff"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    stroke="currentColor"
                    opacity={0.92}
                    d="M53.382,402.685l1.741,1.741,2.913-2.913"
                    transform="translate(0 0.515)"
                />
                <path
                    fill="#fff"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    stroke="currentColor"
                    opacity={0.92}
                    d="M53.718,409.783l1.742,1.742,2.913-2.913"
                    transform="translate(0.084 2.287)"
                />
                <g transform="translate(61.785 401.315)">
                    <line
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        stroke="currentColor"
                        opacity={0.92}
                        x2="8.119"
                    />
                    <line
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        stroke="currentColor"
                        opacity={0.92}
                        x2="5.619"
                        transform="translate(0 3.749)"
                    />
                </g>
                <g transform="translate(61.785 410.063)">
                    <line
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        stroke="currentColor"
                        opacity={0.92}
                        x2="8.119"
                    />
                    <line
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        stroke="currentColor"
                        opacity={0.92}
                        x2="5.619"
                        transform="translate(0 3.749)"
                    />
                </g>
            </g>
        </svg>
    );
};

export default memo(StockListIcon);
