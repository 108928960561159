import onDocumentClick from './onDocumentClick';

const onOutsideClick = (
    node: Element,
    fn: () => void,
    rootNode?: HTMLElement
) => {
    let didClickInsideElement = false;

    const globalClickSubscription = onDocumentClick(() => {
        if (!didClickInsideElement) {
            didClickInsideElement = false;
            fn();
        } else {
            didClickInsideElement = false;
        }
    }, rootNode);

    const nodeClickHandler = () => {
        didClickInsideElement = true;
    };

    node.addEventListener('click', nodeClickHandler);

    return {
        unsubscribe: () => {
            globalClickSubscription.unsubscribe();
            node.removeEventListener('click', nodeClickHandler);
        },
    };
};

export default onOutsideClick;
