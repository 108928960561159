import type { Observable } from '@apollo/client';
import { useEffect, useState } from 'react';

const useObservable = <T>(observable: Observable<T>) => {
    const [value, setStateValue] = useState<T>();

    useEffect(() => {
        const subscription = observable.subscribe(setStateValue);

        return () => {
            if (!subscription.closed) {
                subscription.unsubscribe();
            }
        };
    }, [observable]);

    return value;
};

export default useObservable;
