import { memo } from 'react';
import { Navigate } from 'react-router-dom';
import {
    clearAccessToken,
    getAccessToken,
} from '../../util/auth/credentialsStorage';
import SpinnerPage from '../pages/SpinnerPage';
import IncorrectConfigurationMessage from '../IncorrectConfigurationMessage';

// we freeze token since we log out a user, but the user can make any other redirects
// while cross-store redirect is in progress.
// it prevents to redirect without token
let redirectToken = '';

const RedirectToStore = ({
    store,
    language,
    search,
    redirectPath,
    checkAuthState = false,
}: {
    store: { id: string; url: string };
    language: string;
    search?: string;
    redirectPath?: string;
    checkAuthState?: boolean;
}) => {
    if (!APP_ENV.DISABLE_STORE_URLS) {
        const storeUrl = APP_ENV.DEV_BRANCH
            ? store.url.replace('%BRANCH%', APP_ENV.DEV_BRANCH)
            : store.url;

        const url = new URL(storeUrl);
        const token = getAccessToken();
        const searchParams = new URLSearchParams(search);

        if (checkAuthState && url.host !== window.location.host && token) {
            clearAccessToken();
            redirectToken = token;
        }

        if (redirectToken) {
            searchParams.set('accessToken', redirectToken);
        }

        window.location.replace(
            `${storeUrl}/${language}?${searchParams.toString()}`
        );

        return <SpinnerPage />;
    }

    // APP_ENV.STORE='store' and APP_ENV.CANONICAL_STORE_URL=false is incorrect app configuration.
    // This message for developers.
    if (APP_ENV.STORE) {
        return <IncorrectConfigurationMessage />;
    }

    return (
        <Navigate
            to={{
                pathname: `/${store.id}/${language}${redirectPath}`,
                search,
            }}
        />
    );
};

export default memo(RedirectToStore);
