import type { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { createContext } from 'react';

export interface ContentClientContextValues {
    contentClient?: ApolloClient<NormalizedCacheObject>;
}

const ContentClientContext = createContext<ContentClientContextValues>({
    contentClient: undefined,
});

export default ContentClientContext;
