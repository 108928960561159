import { memo } from 'react';
import type { ReactNode } from 'react';
import { clsx } from 'clsx';
import styles from './Container.module.scss';

const Container = ({
    children,
    fullHeight = false,
    small = false,
    pageContent,
    noPaddingOnMobile,
    ...ariaProps
}: {
    fullHeight?: boolean;
    small?: boolean;
    children: ReactNode;
    pageContent?: boolean;
    noPaddingOnMobile?: boolean;
}) => {
    return (
        <div
            className={clsx(styles.container, {
                [styles.fullHeight]: fullHeight,
                [styles.small]: small,
                [styles.pageContent]: pageContent,
                [styles.noPaddingOnMobile]: noPaddingOnMobile,
            })}
            {...ariaProps}
        >
            {children}
        </div>
    );
};

export default memo(Container);
