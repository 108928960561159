import { memo, Suspense } from 'react';
import type { SuspenseProps } from 'react';
import SpinnerPage from '../pages/SpinnerPage';
import Container from '../Container';

const PageSuspense = (props: Omit<SuspenseProps, 'fallback'>) => {
    return (
        <Suspense
            {...props}
            fallback={
                <Container>
                    <SpinnerPage />
                </Container>
            }
        />
    );
};

export default memo(PageSuspense);
