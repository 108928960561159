import type { DocumentNode, OperationVariables } from '@apollo/client';
import { gql, useQuery } from '@apollo/client';
import assert from '../../core/util/assert';
import useStoreAndLanguageParams from '../../core/hooks/useStoreAndLanguageParams';
import useCurrentUser from '../../core/hooks/useCurrentUser';

const useCapabilityQuery = <
    T,
    TVariables extends OperationVariables = OperationVariables,
>(
    query: DocumentNode,
    key: keyof T,
    variables?: TVariables,
    skip?: boolean
    // eslint-disable-next-line max-params
): boolean => {
    const { data, loading, error } = useQuery<{
        capabilities: T;
    }>(query, {
        variables,
        skip,
    });

    if (error) {
        throw error;
    }

    if (loading) {
        return false;
    }

    if (skip) {
        return false;
    }

    assert(data, 'Data must be defined');

    return data.capabilities[key] as boolean;
};

export const useCanImpersonateCustomers = () => {
    const { store } = useStoreAndLanguageParams();
    const user = useCurrentUser();
    return useCapabilityQuery(
        gql`
            query canImpersonateCustomers($store: String!) {
                capabilities {
                    canImpersonateCustomers(store: $store)
                }
            }
        `,
        'canImpersonateCustomers',
        {
            store,
        },
        !user
    );
};

export const useCanEditCartDiscounts = () => {
    const user = useCurrentUser();
    const customerNumber = user?.customerNumber;
    return useCapabilityQuery(
        gql`
            query canImpersonateCustomers($customerNumber: Int!) {
                capabilities {
                    canEditCartPriceForCustomer(customerNumber: $customerNumber)
                }
            }
        `,
        'canEditCartPriceForCustomer',
        {
            customerNumber,
        },
        !user
    );
};

export const useCanEditPreorderDiscounts = () => {
    const user = useCurrentUser();
    const customerNumber = user?.customerNumber;
    return useCapabilityQuery(
        gql`
            query canImpersonateCustomers($customerNumber: Int!) {
                capabilities {
                    canEditPreorderPriceForCustomer(
                        customerNumber: $customerNumber
                    )
                }
            }
        `,
        'canEditPreorderPriceForCustomer',
        {
            customerNumber,
        },
        !user
    );
};
