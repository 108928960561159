import { memo, useRef } from 'react';
import { clsx } from 'clsx';
import useScrollState from '../../../../product/hooks/useScrollState';
import styles from './FadeScroll.module.scss';
import type { FadeScrollEffectProps } from './FadeScrollEffect';
import FadeScrollEffect from './FadeScrollEffect';

const FadeScroll = ({
    children,
    hideScrollbar,
    mode = 'both',
    ...props
}: FadeScrollEffectProps & {
    hideScrollbar?: boolean;
}) => {
    const scrollBodyRef = useRef<HTMLDivElement>(null);
    const { onScroll, ...states } = useScrollState(scrollBodyRef);

    return (
        <FadeScrollEffect mode={mode} {...props} {...states}>
            <div
                ref={scrollBodyRef}
                onScroll={onScroll}
                className={clsx(styles.body, styles[mode], {
                    [styles.hideScrollBar]: hideScrollbar,
                })}
            >
                {children}
            </div>
        </FadeScrollEffect>
    );
};

export default memo(FadeScroll);
