import type { StockListSchema } from '../queries/useStockLists';
import updateSearchParams from './updateSearchParams';

const getStockListUrl = (
    categoryId: string,
    filters: Pick<StockListSchema, 'filters'>['filters']
) => {
    const groupedByFilters = filters.reduce(
        (result, f) => {
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            if (result[f.attribute]) {
                result[f.attribute].push(f.value);
                return result;
            }
            return {
                ...result,
                [f.attribute]: [f.value],
            };
        },
        {} as Record<string, string[]>
    );

    const searchUrl = updateSearchParams(
        [],
        [
            ...Object.entries(groupedByFilters).map(([name, value]) => {
                return {
                    name,
                    value,
                };
            }),
            {
                name: 'viewMode',
                value: 'stockList',
            },
        ]
    );
    const targetPath = `/categories/${categoryId}/page/1?${searchUrl.toString()}`;
    return {
        targetPath,
        paramsStr: searchUrl.toString(),
    };
};

export default getStockListUrl;
