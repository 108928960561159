import type { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import type { StoreId } from '../../core/types/stores';
import type { Cart, CartType } from '../types';
import LocalCart from './LocalCart';
import RemoteCart from './RemoteCart';

const cartFactory = ({
    client,
    store,
    cartType,
    cartId,
}: {
    client: ApolloClient<NormalizedCacheObject>;
    store: StoreId;
    cartType: CartType;
    cartId?: number;
}): Cart => {
    switch (cartType) {
        case 'local': {
            return new LocalCart();
        }
        case 'customerPreorder':
        case 'user':
            return new RemoteCart(client, store, cartId);
        default: {
            throw new Error('wrong cartType');
        }
    }
};

export default cartFactory;
