import { gql } from '@apollo/client';

const updateCartItemQuantityMutation = gql`
    mutation updateCartItemQuantity(
        $store: ID!
        $cartId: Int!
        $sku: String!
        $quantity: Int!
    ) {
        updateCartItemQuantity(
            store: $store
            cartId: $cartId
            sku: $sku
            quantity: $quantity
        ) {
            success
        }
    }
`;

export default updateCartItemQuantityMutation;
