import type { RouteObject } from 'react-router-dom';
import AccessDeniedRedirect from '../core/components/router/AccessDeniedRedirect';
import LazyComponent from '../core/components/router/LazyComponent';
import RestrictedContent from '../core/components/router/RestrictedContent';
const PreorderSalesRepNotified = LazyComponent(
    async () => import('./components/PreorderSalesRepNotified')
);
const PreorderStatus = LazyComponent(
    async () => import('./components/PreorderStatus')
);

const PaymentStatus = LazyComponent(
    async () => import('../order/components/PaymentStatus')
);
const OrderSubmissionConfirmationPage = LazyComponent(
    async () => import('../order/components/OrderSubmissionConfirmationPage')
);

const OrderRoutes: RouteObject[] = [
    {
        path: 'orders/:orderId',
        children: [
            {
                path: '',
                element: (
                    <RestrictedContent
                        loggedInOnly
                        restrictedPage
                        fallback={AccessDeniedRedirect}
                    >
                        <OrderSubmissionConfirmationPage />
                    </RestrictedContent>
                ),
            },
            {
                path: 'payment',
                element: (
                    <RestrictedContent
                        loggedInOnly
                        restrictedPage
                        fallback={AccessDeniedRedirect}
                    >
                        <PaymentStatus />
                    </RestrictedContent>
                ),
            },
        ],
    },
    {
        path: 'order-success',
        element: <PreorderSalesRepNotified />,
    },
    {
        path: 'order-failed',
        element: (
            <PreorderStatus order={{ status: 'failure' }} loading={false} />
        ),
    },
    {
        path: 'order-submitted',
        element: (
            <PreorderStatus order={{ status: 'submitted' }} loading={false} />
        ),
    },
];

export default OrderRoutes;
