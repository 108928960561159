import HomeRedirect from '../core/components/router/HomeRedirect';
import LazyComponent from '../core/components/router/LazyComponent';
import RestrictedContent from '../core/components/router/RestrictedContent';

const DealerSearchPage = LazyComponent(
    async () => import('./components/DealerSearchPage')
);
const dealerRoutes = [
    {
        path: 'dealers',
        element: (
            <RestrictedContent
                storeFlags="dealerSearchEnabled"
                loggedOutOnly
                fallback={HomeRedirect}
            >
                <DealerSearchPage />
            </RestrictedContent>
        ),
    },
];

export default dealerRoutes;
