setTimeout(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if ((window as any).APP_INITIALIZED) {
        document.getElementById('webshop-error')!.remove();
    } else {
        document
            .querySelectorAll('.react-render-element')
            .forEach(function (element) {
                element.classList.add('hide');
            });

        document.getElementById('webshop-error')!.classList.add('show');
    }
}, 0);

export {};
