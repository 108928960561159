import { memo } from 'react';
import type { IconProps } from './types';

const ChevronRightIcon = ({
    iconLabel,
    color,
    smallIcon,
    emphasized = false,
}: IconProps & { emphasized?: boolean; smallIcon?: boolean }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={smallIcon ? '7.055' : '10.055'}
        height="17.736"
        viewBox={emphasized ? '0 0 6.055 9.933' : '0 0 10.055 17.736'}
        data-icon={iconLabel}
        color={color}
    >
        <path
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2px"
            d={
                emphasized
                    ? 'M195.741,246.351l3.463,3.463-3.641,3.641'
                    : 'M195.937,246.351l7.267,7.267-7.641,7.641'
            }
            transform="translate(-194.148 -244.937)"
        />
    </svg>
);

export default memo(ChevronRightIcon);
