import { gql } from '@apollo/client';
import { useContext, useMemo } from 'react';
import { CartContext } from '../../core/cartContext';
import useCurrentStore from '../../core/hooks/useCurrentStore';
import useLoginState from '../../core/hooks/useLoginState';
import useStoreAndLanguageParams from '../../core/hooks/useStoreAndLanguageParams';
import usePolledQuery from '../../core/hooks/apollo/usePolledQuery';

export const preorderCartBrandBreakdownQuery = gql`
    query preorderCartBrandBreakdown($store: ID!, $cartId: Int) {
        preorderCartBrandBreakdown(store: $store, cartId: $cartId) {
            brandName
            generalDealerPrice
        }
    }
`;

export const purchaseBreakdownQuery = gql`
    query purchaseBreakdown(
        $language: Language!
        $store: ID!
        $cartId: Int!
        $semiFinalPriceForCartBrandTotals: Boolean!
    ) {
        checkout(language: $language, store: $store, cartId: $cartId) {
            checkoutPriceSummary {
                purchaseBreakdown {
                    type
                    items {
                        id
                        totalCartGeneralDealerPrice
                            @skip(if: $semiFinalPriceForCartBrandTotals)
                        totalSeasonGeneralDealerPrice
                            @skip(if: $semiFinalPriceForCartBrandTotals)
                        totalCartSemiFinalPrice
                            @include(if: $semiFinalPriceForCartBrandTotals)
                        totalSeasonSemiFinalPrice
                            @include(if: $semiFinalPriceForCartBrandTotals)
                    }
                }
            }
        }
    }
`;

export interface PurchaseBreakdownItem {
    id: string;
    totalCartGeneralDealerPrice?: string;
    totalSeasonGeneralDealerPrice?: string;
    totalCartSemiFinalPrice?: string;
    totalSeasonSemiFinalPrice?: string;
}

interface PurchaseBreakdown {
    type: 'brand';
    items: PurchaseBreakdownItem[];
}

export type CartBrandBreakdown = Map<
    string,
    { cartTotal: number; seasonTotal: number }
>;

const useCartBrandTotal = (skip: boolean) => {
    const isLoggedIn = useLoginState();
    const { cart } = useContext(CartContext);
    const store = useCurrentStore();
    const { language } = useStoreAndLanguageParams();
    const { semiFinalPriceForCartBrandTotals } = store;

    const { data, loading, error } = usePolledQuery<{
        checkout: {
            checkoutPriceSummary: {
                purchaseBreakdown: PurchaseBreakdown;
            };
        };
    }>(purchaseBreakdownQuery, {
        variables: {
            store: store.id,
            language,
            cartId: cart.id,
            semiFinalPriceForCartBrandTotals,
        },
        skip: !isLoggedIn || !cart.id || skip,
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
        pollInterval: 180_000,
    });

    if (error) {
        throw error;
    }

    const breakdownByBrandMap = useMemo(() => {
        const map = new Map<
            string,
            { cartTotal: number; seasonTotal: number }
        >();

        if (!data) {
            return map;
        }

        const { purchaseBreakdown } = data.checkout.checkoutPriceSummary;

        for (const item of purchaseBreakdown.items) {
            if (semiFinalPriceForCartBrandTotals) {
                map.set(item.id, {
                    cartTotal: +(item.totalCartSemiFinalPrice || '0'),
                    seasonTotal: +(item.totalSeasonSemiFinalPrice || '0'),
                });
            } else {
                map.set(item.id, {
                    cartTotal: +(item.totalCartGeneralDealerPrice || '0'),
                    seasonTotal: +(item.totalSeasonGeneralDealerPrice || '0'),
                });
            }
        }

        return map;
    }, [data, semiFinalPriceForCartBrandTotals]);

    return {
        loading,
        cartBrandBreakdown: breakdownByBrandMap,
    };
};

export default useCartBrandTotal;
