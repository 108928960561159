import { memo } from 'react';

const PhoneIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
    >
        <path
            d="M0.875 4.625C0.875 11.5283 6.47167 17.125 13.375 17.125H15.25C15.7473 17.125 16.2242 16.9275 16.5758 16.5758C16.9275 16.2242 17.125 15.7473 17.125 15.25V14.1067C17.125 13.6767 16.8325 13.3017 16.415 13.1975L12.7292 12.2758C12.3625 12.1842 11.9775 12.3217 11.7517 12.6233L10.9433 13.7008C10.7083 14.0142 10.3025 14.1525 9.935 14.0175C8.57073 13.5159 7.33179 12.7238 6.30398 11.696C5.27618 10.6682 4.48406 9.42927 3.9825 8.065C3.8475 7.6975 3.98583 7.29167 4.29917 7.05667L5.37667 6.24833C5.67917 6.0225 5.81583 5.63667 5.72417 5.27083L4.8025 1.585C4.75178 1.38225 4.63477 1.20225 4.47004 1.07361C4.30532 0.944976 4.10234 0.875069 3.89333 0.875H2.75C2.25272 0.875 1.77581 1.07254 1.42417 1.42417C1.07254 1.77581 0.875 2.25272 0.875 2.75V4.625Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default memo(PhoneIcon);
