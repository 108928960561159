import { memo } from 'react';
import { clsx } from 'clsx';
import styles from './Icon.module.scss';
import type { IconProps } from './types';

const CheckmarkCircleIcon = memo(
    ({ iconLabel, color, small = false, theme }: IconProps) => {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 38 39"
                data-icon={iconLabel}
                color={color}
                className={clsx(theme, styles.icon, {
                    [styles.small]: small,
                })}
            >
                <path
                    fill="currentColor"
                    stroke="currentColor"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M19 2.96753C14.6153 2.96753 10.4102 4.70934 7.30978 7.80978C4.20934 10.9102 2.46753 15.1153 2.46753 19.5C2.46753 21.6711 2.89516 23.8209 3.72599 25.8267C4.55683 27.8325 5.7746 29.655 7.30978 31.1902C8.84496 32.7254 10.6675 33.9432 12.6733 34.774C14.6791 35.6048 16.8289 36.0325 19 36.0325C21.1711 36.0325 23.3209 35.6048 25.3267 34.774C27.3325 33.9432 29.155 32.7254 30.6902 31.1902C32.2254 29.655 33.4432 27.8325 34.274 25.8267C35.1048 23.8209 35.5325 21.6711 35.5325 19.5C35.5325 15.1153 33.7907 10.9102 30.6902 7.80978C27.5898 4.70934 23.3847 2.96753 19 2.96753ZM5.56497 6.06497C9.12816 2.50178 13.9609 0.5 19 0.5C24.0391 0.5 28.8718 2.50178 32.435 6.06497C35.9982 9.62816 38 14.4609 38 19.5C38 21.9951 37.5086 24.4658 36.5537 26.771C35.5989 29.0762 34.1993 31.1707 32.435 32.935C30.6707 34.6993 28.5762 36.0989 26.271 37.0537C23.9658 38.0086 21.4951 38.5 19 38.5C16.5049 38.5 14.0342 38.0086 11.729 37.0537C9.42383 36.0989 7.32928 34.6993 5.56497 32.935C3.80066 31.1707 2.40113 29.0762 1.44629 26.771C0.491449 24.4658 0 21.9951 0 19.5C1.17661e-07 14.4609 2.00178 9.62816 5.56497 6.06497ZM25.6392 14.0545C26.1937 14.4505 26.3221 15.2211 25.926 15.7756L18.5234 26.1392C18.3126 26.4343 17.9827 26.6217 17.6212 26.6516C17.2598 26.6816 16.9035 26.5509 16.6471 26.2945L12.2055 21.8529C11.7237 21.3711 11.7237 20.5899 12.2055 20.1081C12.6873 19.6263 13.4685 19.6263 13.9503 20.1081L17.362 23.5198L23.9181 14.3413C24.3142 13.7869 25.0847 13.6584 25.6392 14.0545Z"
                />
            </svg>
        );
    }
);

export default memo(CheckmarkCircleIcon);
