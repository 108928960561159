import { createContext } from 'react';

export interface StockLocationContextValues {
    checkoutStockLocation?: string;
    isUpdatePending: boolean;
    updateCheckoutStockLocation(stockLocation: string): void;
}

const StockLocationContext = createContext<StockLocationContextValues>({
    isUpdatePending: false,
    /* istanbul ignore next -- @preserve */
    updateCheckoutStockLocation: () => {
        // noop
    },
});

export default StockLocationContext;
