import { memo, useContext, useEffect, useMemo, useRef } from 'react';
import type { ReactNode } from 'react';
import UserContext from '../../core/components/UserContext';
import ContentClientContext from '../ContentClientContext';
import getContentClient from '../storyblok/getContentClient';
import { loadStoryblokPreviewMode } from '../storyblok/storyblok';

const ContentClientProvider = ({ children }: { children: ReactNode }) => {
    const { user } = useContext(UserContext);
    const loggedIn = !!user;

    const contextValue = useMemo(() => {
        return {
            contentClient: getContentClient(loggedIn),
        };
    }, [loggedIn]);

    // Storyblok live editing need reference to content client
    const clientRef = useRef(contextValue.contentClient);

    useEffect(() => {
        clientRef.current = contextValue.contentClient;
    }, [contextValue]);

    useEffect(() => {
        loadStoryblokPreviewMode(clientRef);
    }, []);

    return (
        <ContentClientContext.Provider value={contextValue}>
            {children}
        </ContentClientContext.Provider>
    );
};

export default memo(ContentClientProvider);
