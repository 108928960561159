import { memo } from 'react';
import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { clsx } from 'clsx';
import { TextBlock } from '@library';
import ChevronLeftIcon from '../icons/ChevronLeftIcon';
import ChevronRightIcon from '../icons/ChevronRightIcon';
import CheckmarkIcon from '../icons/CheckmarkIcon';
import styles from './SidePaneMenuStyles.module.scss';

const SidePaneMenuItem = ({
    onClick,
    icon,
    children,
    messageId,
    medium,
}: {
    children?: ReactNode;
    onClick?: () => void;
    icon?: 'rightIcon' | 'leftIcon' | 'checkmark' | null;
    messageId?: string;
    medium?: boolean;
}) => {
    return (
        <div
            role="button"
            tabIndex={0}
            className={clsx(styles.categoryMenuItem, {
                [styles.categoryMenuItemBack]: icon === 'leftIcon',
            })}
            onClick={onClick}
            onKeyPress={(event: React.KeyboardEvent<HTMLDivElement>) => {
                if ((event.key === ' ' || event.key === 'Enter') && onClick) {
                    onClick();
                }
            }}
        >
            {icon === 'leftIcon' && <ChevronLeftIcon smallIcon />}
            <div className={styles.categoryMenuItemTextContainer}>
                <TextBlock secondary medium={medium}>
                    <span className={styles.categoryMenuItemText}>
                        {messageId ? (
                            <FormattedMessage id={messageId} />
                        ) : (
                            children
                        )}
                    </span>
                </TextBlock>
            </div>

            {icon === 'rightIcon' && <ChevronRightIcon smallIcon />}
            {icon === 'checkmark' && <CheckmarkIcon small />}
        </div>
    );
};
export default memo(SidePaneMenuItem);
