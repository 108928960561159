import type { Observable } from '@apollo/client';
import type {
    FutureStockQuantities,
    ProductOrderRestriction,
    StockQuantity,
} from '../availability/types';

import type {
    AdditionalCharges,
    ProductCustomerPrice,
    RenditionUrl,
} from '../product/types';
import type { RemoteCartType } from './services/RemoteCart';
import RemoteCart from './services/RemoteCart';

export const CART_UPDATE_FLAG_KEY = 'cartChanged';

export interface Cart {
    /**
     * Tells if the cart is busy processing updates or is fetching.
     */
    readonly cartBusy: Observable<boolean>;
    readonly cartUpdated: Observable<unknown>;
    readonly items: Observable<CartItem[]>;
    readonly id?: number;
    fetchCart(): void;
    updateItemQuantity(sku: string, quantity: number): void;
    updateItemComment(sku: string, comment: string): Promise<void>;
    updateBulkItemQuantities(
        items: { sku: string; quantity: number }[],
        replace?: boolean
    ): void;
    clearCart(): void;
}

export function isRemoteCart(cart: Cart): cart is RemoteCart {
    return cart instanceof RemoteCart;
}

export interface CartPriceTotals {
    retailPrice: number;
    generalDealerPrice?: number;
    finalPrice?: number;
    dealerDiscountPercentage?: number;
}

export type DropshippingStatus =
    | 'eligible'
    | 'ineligible'
    | 'bulky'
    | 'dangerous'
    | 'unavailable';

export interface CartItemProduct {
    id: string;
    sku: string;
    rebateCode?: string;
    name: string;
    comment?: string;
    preorderDeliveryDate?: string;
    manufacturer?: string;
    barcode?: string;
    variantBarcode?: string;
    featuredAttribute?: string;
    /* Only applicable for simple products */
    groupId?: string;
    parent?: {
        id: string;
        key: string;
        featuredAttribute?: string;
        onSale?: boolean;
        manufacturer: string;
        groupId?: string;
        comment?: string;
        preorderDeliveryDate?: string;
        media: {
            renditionUrl: RenditionUrl;
        }[];
    };
    variationIndex?: number;
    onSale?: boolean;
    additionalCharges: AdditionalCharges[];
    media?: {
        renditionUrl: RenditionUrl;
    }[];
}

export interface CartItem {
    sku: string;
    quantity: number;
    comment?: string;
}

interface DeliveryAddress {
    country: string;
    city: string;
    address: string;
    address2?: string;
    postcode: string;
    name: string;
    id?: string;
    companyName?: string;
}

export type { DeliveryAddress };

export type CartType = 'local' | RemoteCartType;

export interface CartListItem {
    id: number;
    type: 'user' | 'customerPreorder';
    default: boolean;
    name?: string;
    hidden: boolean;
    canSubmit: boolean;
}

export type DetailedCartListItem = CartListItem & {
    items: {
        quantity: number;
    }[];
};

export enum CartErrors {
    REFETCH_ERROR = 'CART_REFETCH_ERROR',
    CLEAR_CART_ERROR = 'CLEAR_CART_ERROR',
    QUANTITY_UPDATE_ERROR = 'CART_QUANTITY_UPDATE_ERROR',
    BULK_QUANTITY_UPDATE_ERROR = 'CART_BULK_QUANTITY_UPDATE_ERROR',
}

export interface CartProduct extends CartItem {
    product: CartItemProduct;
    stock?: {
        singleStockQuantity: StockQuantity;
        productFutureStock: FutureStockQuantities;
        productOrderRestriction?: ProductOrderRestriction;
    };
    customerPrice?: ProductCustomerPrice;
}
