import AccessDeniedRedirect from '../core/components/router/AccessDeniedRedirect';
import LazyComponent from '../core/components/router/LazyComponent';
import RestrictedContent from '../core/components/router/RestrictedContent';

const CheckoutPage = LazyComponent(
    async () => import('./components/CheckoutPage')
);

const checkoutRoutes = [
    {
        path: 'checkout',
        element: (
            <RestrictedContent
                loggedInOnly
                restrictedPage
                fallback={AccessDeniedRedirect}
            >
                <CheckoutPage />
            </RestrictedContent>
        ),
    },
];
export default checkoutRoutes;
