import { memo } from 'react';
import type { NavigateProps } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import useStoreAndLanguageParams from '../../hooks/useStoreAndLanguageParams';
import injectStoreToLocationDescription from '../../util/injectStoreToLocationDescription';

const CurrentStoreRedirect = ({ to, ...rest }: NavigateProps) => {
    const { storeRootPath } = useStoreAndLanguageParams();
    return (
        <Navigate
            to={
                injectStoreToLocationDescription(
                    storeRootPath,
                    to
                ) as NavigateProps['to']
            }
            {...rest}
        />
    );
};

export default memo(CurrentStoreRedirect);
