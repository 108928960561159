import { gql } from '@apollo/client';
import { useContext } from 'react';
import type { CustomerCartPricing } from '@apiTypes';
import { AppContext } from '../../core/context';
import usePolledQuery from '../../core/hooks/apollo/usePolledQuery';
import useCurrentCartId from '../../cart/hooks/useCurrentCartId';
import { useCanEditCartDiscounts } from '../../user/queries/useCapabilities';
export const cartDiscountsQuery = gql`
    query cartDiscounts($id: Int!) {
        cartDiscounts(id: $id) {
            discounts {
                rebateCode
                productGroupId
                variantGroupId
                sku
                discount
                price
            }
        }
    }
`;

const useCartDiscounts = () => {
    const cartId = useCurrentCartId();
    const canEditDiscounts = useCanEditCartDiscounts();

    const { isImpersonating } = useContext(AppContext);
    const skip = !(canEditDiscounts && isImpersonating && cartId);

    const { loading, data, error } = usePolledQuery<{
        cartDiscounts: CustomerCartPricing;
    }>(cartDiscountsQuery, {
        skip,
        variables: {
            id: cartId,
        },
        pollInterval: 180_000,
    });

    if (error) {
        throw error;
    }

    return {
        loading: loading || skip,
        discounts: data?.cartDiscounts.discounts,
    };
};

export default useCartDiscounts;
