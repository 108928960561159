import { memo } from 'react';
import useAccessDeniedRedirect from '../../hooks/useAccessDeniedRedirect';
import CurrentStoreRedirect from './CurrentStoreRedirect';

const AccessDeniedRedirect = () => {
    const { targetUrl } = useAccessDeniedRedirect();

    return (
        <CurrentStoreRedirect
            to={`access-denied?redirectTo=${encodeURIComponent(targetUrl)}`}
        />
    );
};

export default memo(AccessDeniedRedirect);
