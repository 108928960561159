import { memo } from 'react';
import { clsx } from 'clsx';
import { FormattedMessage } from 'react-intl';
import { Text } from '@library';
import { buildTree } from '../util/categories';
import useCategories from '../queries/useCategories';
import type { StockListSchema } from '../queries/useStockLists';
import useLoginState from '../hooks/useLoginState';
import type Category from '../types/category';
import styles from './CategoryDropdown.module.scss';
import Container from './Container';
import LeafCategory from './LeafCategory';
import StockListIcon from './icons/StockListIcon';
import StockList from './StockList';
import BicycleSearchIcon from './icons/BicycleSearchIcon';
import CurrentStoreLink from './router/CurrentStoreLink';
import RestrictedContent from './router/RestrictedContent';

const SecondaryCategory = memo(
    ({ category, onClose }: { category: Category; onClose: () => void }) => {
        const targetPath = `/categories/${category.id}/page/1`;

        return (
            <div className={styles.secondaryCategory}>
                <CurrentStoreLink
                    onClick={onClose}
                    className={clsx('category-link', styles.categoryItemLink)}
                    to={targetPath}
                >
                    <Text primary bold>
                        {category.name}
                    </Text>
                </CurrentStoreLink>
                {category.children.map(child => (
                    <LeafCategory
                        key={child.id}
                        onClose={onClose}
                        category={child}
                    />
                ))}
            </div>
        );
    }
);

const CategoryDropdown = ({
    categoryId,
    nodeId,
    stockLists,
    onClose,
    setNodeId,
}: {
    categoryId: string;
    nodeId: string;
    stockLists: StockListSchema[] | undefined;
    onClose: () => void;
    setNodeId: (hoverId: string) => void;
}) => {
    const isStockList = nodeId === 'stock-list';
    const isLoggedIn = useLoginState();
    const bicycleSearchPath = '/spare-parts';

    const { categories } = useCategories();
    const tree = buildTree(categories);
    const selectedCategoryTree = tree.find(c => c.id === categoryId);
    if (!selectedCategoryTree || !selectedCategoryTree.children.length) {
        return null;
    }
    const isFirstMainCategory = tree.indexOf(selectedCategoryTree) === 0;

    return (
        <div
            className={styles.dropdown}
            data-test-class="categoryNavigationDropdown"
        >
            <Container>
                {!isStockList && (
                    <div className={styles.categories}>
                        {selectedCategoryTree.children.map(child => (
                            <SecondaryCategory
                                key={child.id}
                                onClose={onClose}
                                category={child}
                            />
                        ))}
                        {isLoggedIn && (
                            <div
                                className={clsx(styles.returnCategoryLink)}
                                data-test-class="optionLinks"
                            >
                                {isFirstMainCategory && (
                                    <RestrictedContent stores="ic">
                                        <CurrentStoreLink
                                            onClick={onClose}
                                            className={styles.bicycleSearch}
                                            to={bicycleSearchPath}
                                        >
                                            <div
                                                className={
                                                    styles.bicycleSearchIcon
                                                }
                                            >
                                                <BicycleSearchIcon />
                                            </div>
                                            <FormattedMessage id="spare parts search" />
                                        </CurrentStoreLink>
                                    </RestrictedContent>
                                )}

                                {stockLists && (
                                    <div
                                        role="link"
                                        tabIndex={0}
                                        onKeyPress={() => {
                                            setNodeId('stock-list');
                                        }}
                                        className={styles.returnLink}
                                        onClick={() => {
                                            setNodeId('stock-list');
                                        }}
                                        data-test-class="stockListDropdownLink"
                                    >
                                        <div className={styles.stockListIcon}>
                                            <StockListIcon color="currentColor" />
                                        </div>
                                        <div
                                            className={
                                                styles.returnLinkStockList
                                            }
                                        >
                                            <FormattedMessage id="stock list" />
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                )}
                {isStockList && (
                    <div className={styles.categories}>
                        {stockLists?.map(item => (
                            <StockList
                                /* eslint-disable-next-line no-underscore-dangle */
                                key={item._uid}
                                item={item}
                                categoryId={categoryId}
                                onClose={onClose}
                            />
                        ))}
                    </div>
                )}
            </Container>
        </div>
    );
};

export default memo(CategoryDropdown);
