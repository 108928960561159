import productDetailsRoutes from './productDetailsRoutes';
import productListRoutes from './productListRoutes';
import searchRoutes from './searchRoutes';

const productRoutes = [
    ...productDetailsRoutes,
    ...productListRoutes,
    ...searchRoutes,
];
export default productRoutes;
