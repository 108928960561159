import { useContext, useEffect, useRef } from 'react';
import { toast } from 'react-hot-toast';
import { FormattedMessage } from 'react-intl';
import { notify } from '@library';
import NetworkStatusContext, {
    NetworkStatus,
} from '../contexts/NetworkStatusContext';

const toastId = 'NETWORK_STATUS_NOTIFY_TOAST';

const useNotifyNetworkStatus = () => {
    const { networkStatus } = useContext(NetworkStatusContext);
    const lastNetworkStatus = useRef(networkStatus);

    useEffect(() => {
        if (
            networkStatus === NetworkStatus.ONLINE &&
            lastNetworkStatus.current === NetworkStatus.OFFLINE
        ) {
            document
                .getElementsByTagName('html')
                .item(0)
                ?.removeAttribute('disabled');

            const id = notify({
                type: 'success',
                title: <FormattedMessage id="You're back online." />,
                id: toastId,
            });

            setTimeout(() => {
                toast.remove(id);
            }, 5_000);
        }

        if (networkStatus === NetworkStatus.OFFLINE) {
            document
                .getElementsByTagName('html')
                .item(0)
                ?.setAttribute('disabled', 'true');
            notify({
                type: 'error',
                title: <FormattedMessage id="You're offline." />,
                message: (
                    <FormattedMessage id="Some features might be unavailable until your connection has been restored." />
                ),
                id: toastId,
                duration: Infinity,
            });
        }

        lastNetworkStatus.current = networkStatus;
    }, [networkStatus]);
};

export default useNotifyNetworkStatus;
