import type Category from '../types/category';

interface LookupTable {
    [key: string]: Category;
}

// transforms flatten categories list to tree-like structure
export const buildTree = (list: Omit<Category, 'children'>[]) => {
    const tree: Category[] = [];
    const lookup: LookupTable = {};

    list.forEach(obj => {
        lookup[obj.id] = {
            ...obj,
            children: [],
        };
    });
    list.forEach(obj => {
        if (obj.parentId) {
            lookup[obj.parentId].children.push(lookup[obj.id]);
        } else {
            tree.push(lookup[obj.id]);
        }
    });
    return tree;
};

export const buildBreadcrumbs = (category: Category) => {
    return category.path.map(({ name, id }) => ({
        name,
        id,
    }));
};
