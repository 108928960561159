import { useCallback, useRef, useEffect } from 'react';

function useImmutableCallback<T extends (...args: never[]) => unknown>(
    callback: T | undefined
): T {
    const callbackRef = useRef(callback);

    useEffect(() => {
        callbackRef.current = callback;
    }, [callback]);

    return useCallback(
        (...args: never[]) => callbackRef.current?.(...args),
        []
    ) as unknown as T;
}

export default useImmutableCallback;
