import { memo } from 'react';
import { capitalize } from 'lodash-es';
import type { SystemMaintenance } from '@apiTypes';
import { HStack, Heading, LinkButton, Text, VStack } from '@library';
import HeadlessPage from '../../../core/components/layout/HeadlessPage';
import useCurrentStore from '../../../core/hooks/useCurrentStore';
import useStoreAndLanguageParams from '../../../core/hooks/useStoreAndLanguageParams';
import EmailIcon from '../../../dealer/icons/EmailIcon';
import PhoneIcon from '../../../dealer/icons/PhoneIcon';
import HostettlerImage from './HostettlerImage.svg';
import HmaImage from './HmaImage.svg';
import IntercycleImage from './IntercycleImage.svg';
import styles from './MaintenanceModeFullPage.module.scss';

const getImageSrc = () => {
    switch (APP_ENV.APPLICATION) {
        case 'hostettler': {
            return HostettlerImage;
        }
        case 'intercycle': {
            return IntercycleImage;
        }
        case 'hostettlerMotorenAg': {
            return HmaImage;
        }
    }
};

const MaintenanceModeFullPage = ({
    systemMaintenance,
}: {
    systemMaintenance: SystemMaintenance;
}) => {
    const store = useCurrentStore();
    const { language } = useStoreAndLanguageParams();
    return (
        <HeadlessPage
            footer={
                <HStack gap="small" justify="end" align="center">
                    <HStack gap="small" align="center">
                        <LinkButton
                            inline
                            text
                            secondary
                            href={`mailto:${store.companyInfo.email}`}
                            target="_blank"
                            data-test-class="dealerEmailInfo"
                        >
                            <HStack gap="micro" align="center">
                                <EmailIcon />
                                <Text>{store.companyInfo.email}</Text>
                            </HStack>
                        </LinkButton>
                        <LinkButton
                            inline
                            text
                            secondary
                            href={`tel:${store.companyInfo.phone}`}
                            target="_blank"
                            data-test-class="dealerEmailInfo"
                        >
                            <HStack gap="micro" align="center">
                                <PhoneIcon />
                                <Text>{store.companyInfo.phone}</Text>
                            </HStack>
                        </LinkButton>
                    </HStack>
                </HStack>
            }
        >
            <div className={styles.message}>
                <VStack align="center" justify="center" expanded gap="large">
                    <img src={getImageSrc()} alt="Maintenance" />

                    <Heading h3>
                        {
                            systemMaintenance[
                                `message${capitalize(
                                    language
                                )}` as keyof SystemMaintenance
                            ]
                        }
                    </Heading>
                </VStack>
            </div>
        </HeadlessPage>
    );
};

export default memo(MaintenanceModeFullPage);
