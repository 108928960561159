import type { ReactNode } from 'react';
import { useLayoutEffect, useRef, useState } from 'react';

import { clsx } from 'clsx';
import { createPortal } from 'react-dom';
import { CloseIcon } from '@library';
import assert from '../util/assert';
import onDocumentKeydown from '../events/onDocumentKeydown';
import onOutsideClick from '../events/onOutsideClick';
import styles from './Modal.module.scss';
import Button from './Button';
import Backdrop from './Backdrop';

interface ModalProps {
    onClose: () => void;
    children: ReactNode;
    fixedHeader?: ReactNode;
    fixedFooter?: ReactNode;
    wide?: boolean;
    large?: boolean;
    regular?: boolean;
    medium?: boolean;
    small?: boolean;
    disableShortcuts?: boolean;
    scrollableOverflowContent?: boolean;
}

const FADE_DURATION = 250;
const Modal = ({
    onClose,
    disableShortcuts = false,
    scrollableOverflowContent = false,
    fixedHeader,
    fixedFooter,
    children,
    wide,
    large,
    medium,
    small,
    regular,
    ...ariaProps
}: ModalProps) => {
    const container = document.getElementById('modal-container');
    const modalContentContainerRef = useRef<HTMLDivElement>(null);

    const closeInProgress = useRef(false);
    const [closeAnimation, setCloseAnimation] = useState(false);
    const handleClose = () => {
        if (closeInProgress.current) {
            return;
        }
        closeInProgress.current = true;
        setCloseAnimation(true);
        setTimeout(onClose, FADE_DURATION);
    };

    const onEscapeKeyDown = (event: KeyboardEvent): void => {
        if (event.key === 'Escape') {
            handleClose();
        }
    };

    assert(container, 'container is required.');

    useLayoutEffect(() => {
        const subscriptions: { unsubscribe: () => void }[] = [];

        if (!disableShortcuts) {
            if (modalContentContainerRef.current) {
                subscriptions.push(
                    onOutsideClick(
                        modalContentContainerRef.current as Element,
                        handleClose,
                        container
                    )
                );
            }

            subscriptions.push(onDocumentKeydown(onEscapeKeyDown));
        }

        return () => {
            subscriptions.forEach(sub => sub.unsubscribe());
        };
        // eslint-disable-next-line
    }, []);

    return createPortal(
        <Backdrop {...ariaProps} closing={closeAnimation}>
            <div
                className={clsx(styles.modal, {
                    [styles.wide]: wide,
                    [styles.large]: large,
                    [styles.medium]: medium,
                    [styles.small]: small,
                    [styles.regular]: regular,
                    [styles.closing]: closeAnimation,
                })}
                ref={modalContentContainerRef}
            >
                <div
                    className={styles.closeButton}
                    data-test-class="modal-close-button"
                >
                    <Button iconButton onPress={handleClose}>
                        <CloseIcon color="var(--base-color-2)" />
                    </Button>
                </div>
                {fixedHeader || fixedFooter ? (
                    <div className={styles.contentWrapper}>
                        <div className={styles.fixedHeader}>{fixedHeader}</div>
                        <div
                            className={clsx(styles.content, {
                                [styles.scrollableOverflowContent]:
                                    scrollableOverflowContent,
                            })}
                        >
                            {children}
                        </div>
                        <div className={styles.fixedFooter}>{fixedFooter}</div>
                    </div>
                ) : (
                    children
                )}
            </div>
        </Backdrop>,
        container
    );
};

export default Modal;
