import { memo } from 'react';
import type { IconProps } from './types';

const strokeProps: Record<string, string> = {
    fill: 'none',
    stroke: 'currentColor',
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    strokeWidth: '2.5px',
};

const CartSmallIcon = ({
    iconLabel,
    color,
    smaller = false,
}: IconProps & {
    smaller?: boolean;
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={smaller ? '15.123' : '24.988'}
            height={smaller ? '13.01' : '21.5'}
            viewBox="0 0 24.988 21.5"
            data-icon={iconLabel}
            color={color}
        >
            <g transform="translate(1.249 1.25)">
                <path
                    {...strokeProps}
                    d="M229.827,251.907a1.2,1.2,0,1,1-1.2,1.206,1.207,1.207,0,0,1,1.2-1.206Z"
                    transform="translate(-220.042 -235.316)"
                />
                <path
                    {...strokeProps}
                    d="M232.4,251.907a1.2,1.2,0,1,1-1.2,1.206,1.207,1.207,0,0,1,1.2-1.206Z"
                    transform="translate(-214.937 -235.316)"
                />
                <path
                    {...strokeProps}
                    d="M225.75,246.351h4.055l3.639,11.28h12.581"
                    transform="translate(-225.749 -246.351)"
                />
                <path
                    {...strokeProps}
                    d="M233.023,249.211"
                    transform="translate(-211.306 -240.67)"
                />
                <path
                    {...strokeProps}
                    d="M228.129,249.211"
                    transform="translate(-221.026 -240.67)"
                />
                <line
                    {...strokeProps}
                    x2="16.413"
                    transform="translate(6.077 4.797)"
                />
            </g>
        </svg>
    );
};

export default memo(CartSmallIcon);
