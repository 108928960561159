import { Navigate } from 'react-router-dom';
import LazyComponent from '../core/components/router/LazyComponent';
import userRoutes from '../user/userRoutes';
import deeplinkRoutes from '../user/deeplinkRoutes';
import productRoutes from '../product/productRoutes';
import contentRoutes from '../content/contentRoutes';
import cartRoutes from '../cart/cartRoutes';
import dealersRoutes from '../dealer/dealerRoutes';
import brandRoutes from '../brand/brandRoutes';
import OrderRoutes from '../order/orderRoutes';
import checkoutRoutes from '../checkout/checkoutRoutes';
import RestrictedContent from '../core/components/router/RestrictedContent';
import AccessDeniedRedirect from '../core/components/router/AccessDeniedRedirect';
import externalFormsRoutes from '../externalForms/externalFormsRoutes';
import { STORYBLOK_ENVIRONMENTS } from '../content/types/types';
import CartPricingProvider from '../pricing/providers/CartPricingProvider';
import CartProvider from '../core/components/providers/CartProvider';
import serviceRoutes from '../service/serviceRoutes';
import ExportRoutes from './exportRoutes';
import AppLayout from './components/AppLayout';
import AppProviders from './components/AppProviders';

const ShortPathRedirect = LazyComponent(
    async () => import('../core/components/router/ShortPathRedirect')
);

const ContentLivePreviewRedirects = LazyComponent(
    async () => import('../content/components/ContentLivePreviewRedirects')
);

const YhEPResendFormPage = LazyComponent(
    async () => import('../cart/components/YhEPIntegration/YhEPResendFormPage')
);

const SetLanguage = LazyComponent(
    async () => import('./components/SetLanguage')
);

const QrUpload = LazyComponent(
    async () => import('../service/components/QrUpload/QrUpload')
);

const PreorderModeProvider = LazyComponent(
    async () =>
        import('../preorder/components/PreorderMode/PreorderModeProvider')
);

const StaticTestPage = LazyComponent(
    async () => import('../core/components/StaticTestPage')
);

const appRoutes = [
    ...productRoutes,
    ...userRoutes,
    ...contentRoutes,
    ...cartRoutes,
    ...OrderRoutes,
    ...checkoutRoutes,
    ...dealersRoutes,
    ...brandRoutes,
    ...externalFormsRoutes,
    ...deeplinkRoutes,
    ...serviceRoutes,
    { path: 'static-test-page', element: <StaticTestPage /> },
];

const { MODE } = import.meta.env;
const storeRootPath = APP_ENV.STORE ? ':language?/*' : ':store/:language?/*';

const routes = [
    {
        path: '/yhep-resend',
        element: <YhEPResendFormPage />,
    },
    {
        path: '/set-language/:language',
        element: <SetLanguage />,
    },
    {
        path: storeRootPath,
        element: <AppProviders />,
        children: [
            {
                path: '*',
                element: (
                    <CartProvider>
                        <CartPricingProvider>
                            <AppLayout />
                        </CartPricingProvider>
                    </CartProvider>
                ),
                children: appRoutes,
            },
            {
                path: 'preorder/*',
                children: [
                    {
                        path: '',
                        element: <Navigate to="../account" />,
                    },
                    {
                        path: ':preorderSeasonId/*',
                        element: (
                            <RestrictedContent
                                loggedInOnly
                                restrictedPage
                                fallback={AccessDeniedRedirect}
                                shouldShowSpinnerPage
                            >
                                <PreorderModeProvider>
                                    <AppLayout />
                                </PreorderModeProvider>
                            </RestrictedContent>
                        ),
                        children: appRoutes,
                    },
                ],
            },
            {
                path: 'headless/*',
                children: [
                    {
                        path: 'ticket-upload',
                        element: <QrUpload />,
                    },
                ],
            },
            ...ExportRoutes,
        ],
    },
    /* CMS Live Preview urls */
    ...STORYBLOK_ENVIRONMENTS.reduce((result, app) => {
        return [...result, `:language/${app}/:store/*`, `${app}/:store/*`];
    }, [] as string[]).map(path => {
        return {
            path,
            element: <ContentLivePreviewRedirects />,
        };
    }),
    ...(!APP_ENV.STORE && MODE === 'production'
        ? [
              {
                  path: '*',
                  element: <Navigate to="/de" />,
              },
          ]
        : []),
    /* Redirect from unlocked store link */
    ...(APP_ENV.STORE
        ? [
              ...['de', 'ch', 'fr', 'en'].map(
                  language => `${APP_ENV.STORE}/${language}/*`
              ),
          ].map(path => {
              return {
                  path,
                  element: <ShortPathRedirect />,
              };
          })
        : []),
    // Redirect to default store for dev purposes
    ...(MODE === 'development'
        ? [
              {
                  path: '*',
                  element: (
                      <Navigate
                          to={
                              APP_ENV.APPLICATION === 'hostettler'
                                  ? '/ch/de'
                                  : '/ic/de'
                          }
                      />
                  ),
              },
          ]
        : []),
];

export default routes;
