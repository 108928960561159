import type { RefObject } from 'react';
import { useLayoutEffect, useRef } from 'react';
import useImmutableCallback from '../useImmutableCallback';

const useResizeObserver = (
    elementRef: RefObject<HTMLElement>,
    callback: ResizeObserverCallback,
    condition: boolean
) => {
    const immutableCallback = useImmutableCallback(callback);
    const observer = useRef(new ResizeObserver(immutableCallback));
    useLayoutEffect(() => {
        const container = elementRef.current;
        if (!container || !condition) {
            return undefined;
        }
        const observerCurrent = observer.current;
        observerCurrent.observe(container);

        return () => {
            observerCurrent.unobserve(container);
        };
    }, [elementRef, condition]);
};

export default useResizeObserver;
