import { memo } from 'react';
const ScrollToTopIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="33"
            height="33"
            viewBox="0 0 33 33"
        >
            <rect
                className="a"
                fill="rgba(100,127,177,0.2)"
                width="33"
                height="33"
                rx="16.5"
            />
            <g transform="translate(-962.5 -1129.5)">
                <path
                    className="b"
                    fill="none"
                    stroke="#647fb1"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M-12625.488,477.343l5,5,5-5"
                    transform="translate(-11640.988 1622.843) rotate(180)"
                />
                <path
                    className="b"
                    fill="none"
                    stroke="#647fb1"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M-12625.488,477.343l5,5,5-5"
                    transform="translate(-11640.988 1628.843) rotate(180)"
                />
            </g>
        </svg>
    );
};

export default memo(ScrollToTopIcon);
