import type { DocumentNode } from 'graphql';
import type { OperationVariables, QueryHookOptions } from '@apollo/client';
import { useQuery } from '@apollo/client';

function useCachedQuery<
    TData,
    TVariables extends OperationVariables = OperationVariables,
>(
    query: DocumentNode,
    options?: QueryHookOptions<TData, TVariables>,
    ignoreSkip?: boolean
) {
    const { data, previousData, loading, error, ...result } = useQuery<
        TData,
        TVariables
    >(query, {
        ...options,
        skip: options?.skip,
    });

    const emptyData = !data;
    const isShowPrevious =
        (emptyData && loading) || (ignoreSkip && options?.skip);

    return {
        ...result,
        loading,
        error,
        data: isShowPrevious ? previousData : data,
    };
}

export default useCachedQuery;
