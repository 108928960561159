import { importComponents } from '@library';
import AccessDeniedRedirect from '../core/components/router/AccessDeniedRedirect';
import RestrictedContent from '../core/components/router/RestrictedContent';
import HomeRedirect from '../core/components/router/HomeRedirect';

const { BicycleSearch, MotorcycleSearchPage } = importComponents(async () => {
    return import('./product.module');
}, ['BicycleSearch', 'MotorcycleSearchPage']);

const searchRoutes = [
    {
        path: 'spare-parts',
        element: (
            <RestrictedContent
                storeFlags="sparePartSearchEnabled"
                fallback={HomeRedirect}
            >
                <RestrictedContent
                    loggedInOnly
                    restrictedPage
                    fallback={AccessDeniedRedirect}
                >
                    <BicycleSearch />
                </RestrictedContent>
            </RestrictedContent>
        ),
    },
    {
        path: 'motorcycle-search',
        element: (
            <RestrictedContent stores={['ch']} fallback={HomeRedirect}>
                <MotorcycleSearchPage />
            </RestrictedContent>
        ),
    },
];
export default searchRoutes;
