import { useEffect, useState } from 'react';
import type { ReadonlyObservableVariable } from '../util/makeObservableVariable';

const useObservableVariableState = <T>(
    observableVariable: ReadonlyObservableVariable<T>
) => {
    const state = useState<T>(observableVariable.currentValue);
    const [, setStateValue] = state;
    useEffect(() => {
        const subscription =
            observableVariable.onValueChange.subscribe(setStateValue);
        return () => {
            subscription.unsubscribe();
        };
    }, [observableVariable, setStateValue]);

    return [state[0]];
};

export default useObservableVariableState;
