import { memo } from 'react';
import SidePaneMenuLink from '../SidePaneMenuLink';

const MenuLink = ({
    item,
    parent,
    onClick,
}: {
    item: {
        name: string;
        to: string;
    };
    parent?: boolean;
    onClick: () => void;
}) => {
    return (
        <SidePaneMenuLink to={item.to} medium={parent} onClick={onClick}>
            {item.name}
        </SidePaneMenuLink>
    );
};

export default memo(MenuLink);
