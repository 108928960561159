import useCartItems from './useCartItems';

const useCartTotalQuantity = () => {
    return useCartItems().reduce(
        (totalQuantity, item) => totalQuantity + item.quantity,
        0
    );
};

export default useCartTotalQuantity;
