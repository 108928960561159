import { importComponents } from '@library';

const { ProductDetails } = importComponents(async () => {
    return import('./product.module');
}, ['ProductDetails']);

const productDetailsRoutes = [
    {
        path: 'product/:pid/:variantId',
        element: <ProductDetails />,
    },
    {
        path: 'product/:pid',
        element: <ProductDetails />,
    },
];

export default productDetailsRoutes;
