import { memo } from 'react';
import type { IconProps } from './types';

const ChevronRightIcon = ({ iconLabel, color }: IconProps) => (
    <svg
        width="7"
        height="12"
        viewBox="0 0 9 16"
        fill="none"
        data-icon={iconLabel}
        color={color}
    >
        <path
            d="M1.07422 1.92588L7.22467 8.07623L1.07422 14.2267"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default memo(ChevronRightIcon);
