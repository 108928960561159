import { clsx } from 'clsx';
import type { CSSProperties, ReactNode, Ref } from 'react';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { CloseIcon } from '@library';
import useGlobalKeydown from '../hooks/dom/useGlobalKeydown';
import assert from '../util/assert';
import useOutsideClick from '../hooks/dom/useOutsideClick';
import Backdrop from './Backdrop';
import Button from './Button';
import HorizontalLayout from './layout/HorizontalLayout';
import styles from './SidePane.module.css';

const SLIDE_DURATION = 250;
const SidePane = (
    {
        side = 'left',
        onClose,
        children,
        header,
        noCloseButton = false,
        // We have situations where we want to prompt user for
        // close confirmation. We need to not perform animation in
        // this case.
        // @TODO: Find a better implementation in new designs
        preventAnimation = false,
        ...ariaProps
    }: {
        side?: 'left' | 'right';
        children?: ReactNode;
        header?: ReactNode;
        onClose: () => void;
        noCloseButton?: boolean;
        preventAnimation?: boolean;
    },
    ref: Ref<{ close: () => void }>
) => {
    const container = document.getElementById('side-pane-container');
    const contentRef = useRef<HTMLDivElement>(null);
    assert(container, 'container is required.');

    const closeInProgress = useRef(false);
    const [closeAnimation, setCloseAnimation] = useState(false);

    const handleClose = () => {
        if (preventAnimation) {
            onClose();
        } else {
            if (closeInProgress.current) {
                return;
            }
            closeInProgress.current = true;
            setCloseAnimation(true);
            setTimeout(onClose, SLIDE_DURATION);
        }
    };

    const onEscapeKeyDown = (event: KeyboardEvent): void => {
        if (event.key === 'Escape') {
            handleClose();
        }
    };

    useGlobalKeydown(onEscapeKeyDown);
    useOutsideClick({
        elementRef: contentRef,
        callback: handleClose,
        rootNode: container,
    });

    useImperativeHandle(ref, () => ({
        close: handleClose,
    }));

    return createPortal(
        <Backdrop
            justify={side === 'left' ? 'start' : 'end'}
            closing={closeAnimation}
        >
            <div
                className={clsx(styles.contentContainer, styles[side], {
                    [styles.closing]: closeAnimation,
                })}
                style={
                    {
                        '--slide-duration': `${SLIDE_DURATION}ms`,
                    } as CSSProperties
                }
                ref={contentRef}
                {...ariaProps}
            >
                <div className={styles.content}>
                    <HorizontalLayout
                        justify={header ? 'spaceBetween' : 'end'}
                        align="center"
                    >
                        {header}
                        {!noCloseButton && (
                            <Button textOnly noPadding onPress={handleClose}>
                                <CloseIcon color="var(--base-color-2)" />
                            </Button>
                        )}
                    </HorizontalLayout>
                    {children}
                </div>
            </div>
        </Backdrop>,
        container
    );
};

export default forwardRef(SidePane);
