import { clsx } from 'clsx';
import { memo } from 'react';
import styles from './HyphenatedText.module.css';
const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
//  Component for supporting hyphens in Firefox
//  Firefox can not work with uppercase text and hyphens: auto.
const HyphenatedText = ({ children }: { children?: string }) => {
    if (!children) {
        return <></>;
    }
    if (!isFirefox) {
        return <>{children}</>;
    }
    const upperCaseRegex = new RegExp(/([\p{Lu}][^\p{Ll}]{2,})/gu);
    const result = children.split(upperCaseRegex);
    return (
        <span>
            {result.map((text, idx) => {
                if (!text.length) {
                    return null;
                }
                const upperCase = idx % 2 === 1;
                const transformedText = upperCase ? text.toLowerCase() : text;
                return (
                    <span
                        key={idx.toString()}
                        className={clsx({
                            [styles.uppercase]: upperCase,
                        })}
                    >
                        {transformedText}
                    </span>
                );
            })}
        </span>
    );
};

export default memo(HyphenatedText);
