import { memo, useEffect } from 'react';
import type { RefObject } from 'react';
import { clsx } from 'clsx';
import { useInView } from 'react-intersection-observer';
import ScrollToTopIcon from './icons/ScrollToTopIcon';
import styles from './ScrollToTop.module.scss';

const ScrollToTop = ({
    showAboveHelpDesk = false,
    headerRef,
}: {
    showAboveHelpDesk?: boolean;
    headerRef: RefObject<HTMLDivElement>;
}) => {
    const { ref, inView } = useInView({
        threshold: 0,
        initialInView: true,
    });

    useEffect(() => {
        ref(headerRef.current);
    }, [ref, headerRef]);

    const getToTop = () => {
        window.scrollTo({
            top: 0,
        });
    };

    return (
        <div
            role="button"
            tabIndex={0}
            className={clsx(styles.container, {
                [styles.showAboveHelpDesk]: showAboveHelpDesk,
                [styles.show]: !inView,
            })}
            onClick={getToTop}
            onKeyPress={(event: React.KeyboardEvent<HTMLDivElement>) => {
                if (event.key === ' ' || event.key === 'Enter') {
                    getToTop();
                }
            }}
        >
            {!inView && <ScrollToTopIcon />}
        </div>
    );
};

export default memo(ScrollToTop);
