import { memo, useContext, useEffect } from 'react';
import type { ReactNode, ReactElement, MemoExoticComponent } from 'react';
import { AppContext } from '../../context';
import type { ContentAvailabilityOptions } from '../../hooks/useContentAvailableCheck';
import useContentAvailableCheck from '../../hooks/useContentAvailableCheck';
import SpinnerPage from '../pages/SpinnerPage';

interface RestrictedContentProps extends ContentAvailabilityOptions {
    children: ReactNode;
    fallback?: MemoExoticComponent<() => ReactElement>;
    shouldShowSpinnerPage?: boolean;
}

const AuthenticatedPage = memo(({ children }: { children: ReactNode }) => {
    const { setRestrictedPage } = useContext(AppContext);

    useEffect(() => {
        setRestrictedPage(true);
        return () => {
            setRestrictedPage(false);
        };
    }, [setRestrictedPage]);

    return <>{children}</>;
});

const RestrictedContent = ({
    children,
    fallback,
    shouldShowSpinnerPage = false,
    ...availabilityCheckOptions
}: RestrictedContentProps) => {
    const { available, loading } = useContentAvailableCheck(
        availabilityCheckOptions
    );

    if (loading) {
        return shouldShowSpinnerPage ? <SpinnerPage /> : null;
    }

    if (!available) {
        if (fallback) {
            const FallbackComponent = fallback;
            return <FallbackComponent />;
        }

        return null;
    }

    if (
        availabilityCheckOptions.loggedInOnly &&
        availabilityCheckOptions.restrictedPage
    ) {
        return <AuthenticatedPage>{children}</AuthenticatedPage>;
    }

    return <>{children}</>;
};

export default memo(RestrictedContent);
