import type { RefObject } from 'react';
import { useMemo, useState, useLayoutEffect } from 'react';
import { throttle } from 'lodash-es';
import useImmutableCallback from '../../core/hooks/useImmutableCallback';
import useResizeObserver from '../../core/hooks/dom/useResizeObserver';

// A tolerance of 2px when calculating scroll position is at end
const TOLERANCE = 2;

const useScrollState = (scrollBodyRef: RefObject<HTMLElement>) => {
    const [state, setState] = useState<{
        isTop: boolean;
        isBottom: boolean;
        isLeft: boolean;
        isRight: boolean;
    }>({
        isTop: false,
        isBottom: false,
        isLeft: false,
        isRight: false,
    });

    const handleScroll = useImmutableCallback(() => {
        if (scrollBodyRef.current) {
            const {
                scrollTop,
                clientHeight,
                scrollHeight,
                clientWidth,
                scrollWidth,
                scrollLeft,
            } = scrollBodyRef.current;

            const onBottom =
                scrollHeight > clientHeight &&
                scrollTop + clientHeight + TOLERANCE < scrollHeight;

            const onTop = scrollHeight > clientHeight && scrollTop >= TOLERANCE;

            const onRight =
                scrollWidth > clientWidth &&
                scrollLeft + clientWidth + TOLERANCE < scrollWidth;

            const onLeft = scrollWidth > clientWidth && scrollLeft >= TOLERANCE;

            setState({
                isTop: onTop,
                isBottom: onBottom,
                isLeft: onLeft,
                isRight: onRight,
            });
        }
    });

    useLayoutEffect(() => {
        handleScroll();
    }, [handleScroll, scrollBodyRef]);

    useResizeObserver(
        scrollBodyRef,
        () => {
            /* istanbul ignore next -- @preserve */
            handleScrollThrottle();
            /* istanbul ignore next -- @preserve */
        },
        true
    );

    const handleScrollThrottle = useMemo(() => {
        return throttle(() => {
            handleScroll();
        }, 100);
    }, [handleScroll]);

    return { ...state, onScroll: handleScrollThrottle };
};

export default useScrollState;
