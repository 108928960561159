import type { ReactNode } from 'react';
import { memo, useMemo } from 'react';
import withErrorBoundary from '../../core/components/errors/withErrorBoundary';
import useCartDiscounts from '../queries/useCartDiscounts';
import useSaveCartDiscounts from '../queries/useSaveCartDiscounts';
import type { CustomDiscountContextValue } from './CustomDiscountContext';
import CustomDiscountContext from './CustomDiscountContext';

const CartPricingProvider = ({ children }: { children: ReactNode }) => {
    const { loading, discounts } = useCartDiscounts();
    const { loading: isUpdating, saveCustomDiscount } = useSaveCartDiscounts();

    const contextValue: CustomDiscountContextValue = useMemo(() => {
        return {
            loading,
            isUpdating,
            editMode: !!discounts,
            discounts: discounts || [],
            saveCustomDiscount,
        };
    }, [loading, isUpdating, saveCustomDiscount, discounts]);

    return (
        <CustomDiscountContext.Provider value={contextValue}>
            {children}
        </CustomDiscountContext.Provider>
    );
};

export default memo(withErrorBoundary(CartPricingProvider));
