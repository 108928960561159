import { memo, useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { clsx } from 'clsx';
import { Text, HStack, CloseIcon } from '@library';
import useStoreAndLanguageParams from '../../../core/hooks/useStoreAndLanguageParams';
import TextInput from '../../../core/components/input/TextInput';
import Button from '../../../core/components/Button';
import useEffectWithoutMount from '../../../core/util/useEffectWithoutMount';
import SearchIcon from '../../../core/components/icons/SearchIcon';
import Container from '../../../core/components/Container';
import onDocumentKeydown from '../../../core/events/onDocumentKeydown';
import useBrandsSearch from '../../queries/useBrandsSearch';
import useCategoriesSearch from '../../queries/useCategoriesSearch';
import useSubmitSearchData from '../../hooks/useSubmitSearchData';
import LazyComponent from '../../../core/components/router/LazyComponent';
import ComponentSuspense from '../../../core/components/router/ComponentSuspense';
import { useNoScrollingWindow } from '../../../core/components/Backdrop';
import useProductSearch from '../../queries/useProductSearch';
import useCustomNavigate from '../../../core/hooks/router/useCustomNavigate';
import styles from './SearchField.module.scss';

const SearchPreview = LazyComponent(async () => import('./SearchPreview'));

/* eslint max-lines-per-function: ['error', 240], max-statements: ['error', 26] */
const SearchField = () => {
    const intl = useIntl();
    const navigate = useCustomNavigate();
    const { store, language } = useStoreAndLanguageParams();
    const [value, setValue] = useState('');
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');
    const [isSearchFieldInFocus, setIsSearchFieldActive] = useState(false);
    const searchInputRef = useRef<HTMLInputElement>(null);
    useNoScrollingWindow(isSearchFieldInFocus);
    const { key } = useLocation();

    const cancelAndResetSearch = () => {
        resetSearch();
        setIsSearchFieldActive(false);
        if (searchInputRef.current) {
            searchInputRef.current.blur();
        }
    };

    const onEscapeKeyDown = (event: KeyboardEvent): void => {
        if (event.key === 'Escape') {
            cancelAndResetSearch();
        }
    };

    useEffectWithoutMount(() => {
        resetSearch();
    }, [key]);

    useEffect(() => {
        const subscriptions: { unsubscribe: () => void }[] = [];
        subscriptions.push(onDocumentKeydown(onEscapeKeyDown));
        return () => {
            subscriptions.forEach(sub => sub.unsubscribe());
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedSearchTerm(value);
        }, 250);

        return () => {
            clearTimeout(handler);
        };
    }, [value]);

    const initiateSearch = () => {
        if (value !== '') {
            submitSearch();
        }
    };

    const {
        data: brandsData = { brands: [] },
        loading: brandsLoading,
        error: brandsError,
    } = useBrandsSearch(store, debouncedSearchTerm);

    const {
        data: categoriesData = {
            categories: {
                items: [],
            },
        },
        loading: categoriesLoading,
        error: categoriesError,
    } = useCategoriesSearch(store, language, debouncedSearchTerm);

    const {
        products,
        loading,
        error: productsError,
    } = useProductSearch({
        search: debouncedSearchTerm,
        skip: debouncedSearchTerm.length < 2,
    });

    const genericLoading = value !== debouncedSearchTerm;

    const [targetUrl, submitButtonMessage] = useSubmitSearchData({
        brands: brandsData.brands,
        categories: categoriesData.categories.items,
        products,
        query: value,
        loading:
            loading || brandsLoading || categoriesLoading || genericLoading,
    });

    const submitSearch = () => {
        navigate(targetUrl);
        resetSearch();
        setIsSearchFieldActive(false);
        if (searchInputRef.current) {
            searchInputRef.current.blur();
        }
    };

    const resetSearch = () => {
        setDebouncedSearchTerm('');
        setValue('');
    };

    const clearSearch = () => {
        resetSearch();
        setIsSearchFieldActive(true);
    };

    const onSearchIconButtonClick = () => {
        setIsSearchFieldActive(true);
        setTimeout(() => {
            searchInputRef.current?.focus();
        }, 200);
    };

    const isSearchActive = isSearchFieldInFocus || value !== '';

    return (
        <div
            className={clsx(styles.searchField, {
                [styles.active]: isSearchActive,
            })}
        >
            {isSearchActive && (
                <>
                    <div className={styles.searchPreviewHeaderBackdrop} />
                    <div
                        className={clsx(styles.searchPreviewBackdrop, {
                            [styles.searchPreviewBackdropResults]:
                                value.length >= 2,
                        })}
                    >
                        <Container>
                            <ComponentSuspense height="300px">
                                {value.length >= 2 && (
                                    <SearchPreview
                                        queryResult={debouncedSearchTerm}
                                        brands={brandsData.brands}
                                        categories={
                                            categoriesData.categories.items
                                        }
                                        products={products}
                                        productLoading={
                                            loading || genericLoading
                                        }
                                        brandsLoading={
                                            brandsLoading || genericLoading
                                        }
                                        categoriesLoading={
                                            categoriesLoading || genericLoading
                                        }
                                        submitSearch={submitSearch}
                                        submitButtonMessage={
                                            submitButtonMessage
                                        }
                                        brandsError={brandsError}
                                        categoriesError={categoriesError}
                                        productsError={productsError}
                                        search={value}
                                    />
                                )}
                            </ComponentSuspense>
                        </Container>
                    </div>
                    <div className={styles.closeSearchButton}>
                        <Button
                            textOnly
                            noPadding
                            onPress={cancelAndResetSearch}
                        >
                            <HStack gap="micro">
                                <span>
                                    <FormattedMessage id="dismiss" />
                                </span>
                                <CloseIcon small />
                            </HStack>
                        </Button>
                    </div>
                </>
            )}
            {!isSearchActive && (
                <div className={styles.iconOnlySearchTrigger}>
                    <Button iconButton onPress={onSearchIconButtonClick}>
                        <SearchIcon />
                        <div className={styles.searchLabel}>
                            <Text caption uppercase center>
                                <FormattedMessage id="search" />
                            </Text>
                        </div>
                    </Button>
                </div>
            )}
            <div
                className={clsx(styles.search, {
                    [styles.iconOnlyInactiveField]: !isSearchActive,
                    [styles.searchFieldActive]: isSearchActive,
                })}
            >
                <input style={{ display: 'none' }} autoComplete="off" />
                <TextInput
                    hasStartAdornment
                    hasEndAdornment
                    greyBackground
                    noGreyBorder
                    material={false}
                    ref={searchInputRef}
                    aria-label="search"
                    data-test-class="searchPreviewField"
                    id="search"
                    value={value}
                    onFocus={() => setIsSearchFieldActive(true)}
                    onBlur={() => setIsSearchFieldActive(false)}
                    onChange={e => setValue(e.target.value)}
                    placeholder={intl.formatMessage({ id: 'find products' })}
                    autoComplete="globalSearch"
                    startAdornment={
                        <Button iconButton onPress={initiateSearch}>
                            <SearchIcon />
                        </Button>
                    }
                    endAdornment={
                        value.length > 1 && (
                            <Button iconButton onPress={clearSearch}>
                                <CloseIcon />
                            </Button>
                        )
                    }
                    onKeyPress={(
                        event: React.KeyboardEvent<HTMLInputElement>
                    ) => {
                        if (event.key === 'Enter') {
                            initiateSearch();
                        }
                    }}
                />
            </div>
        </div>
    );
};

export default memo(SearchField);
