import type { IntlShape } from 'react-intl';
import { useIntl } from 'react-intl';
import { useContext } from 'react';
import { AppContext } from '../context';
import formatSwissPrice from '../util/formatSwissPrice';

export interface PriceFormat {
    price: string;
    currency: string;
    priceLabel: string;
}

const getPrice = (currency: string, value: number, intl: IntlShape) => {
    if (intl.locale === 'fr-CH') {
        return formatSwissPrice(value);
    }
    return intl
        .formatNumber(value, {
            style: 'currency',
            currency,
            currencyDisplay: 'code',
        })
        .replace(/[A-Z]/g, '')
        .trim();
};

const usePriceFormatter = () => {
    const intl = useIntl();
    const { currency } = useContext(AppContext);

    return (value?: number): PriceFormat => {
        if (!value) {
            return {
                price: '0',
                currency,
                priceLabel: `${currency} ${getPrice(currency, 0, intl)}`,
            };
        }

        const price = getPrice(currency, value, intl);

        return {
            price,
            currency,
            priceLabel: `${currency} ${price}`,
        };
    };
};

export default usePriceFormatter;
