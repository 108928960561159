import { memo } from 'react';
import type { IconProps } from './types';

const ChevronRightIcon = ({ iconLabel, color }: IconProps) => (
    <svg
        width="12"
        height="7"
        viewBox="0 0 15 9"
        fill="none"
        data-icon={iconLabel}
        color={color}
    >
        <path
            d="M13.3007 1L7.15033 7.15045L0.999831 1"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default memo(ChevronRightIcon);
