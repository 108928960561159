// official code from https://formatjs.io/docs/polyfills/intl-numberformat/
// cspell: words numberformat, getcanonicallocales, pluralrules
import { shouldPolyfill as shouldPolyfillNumberFormat } from '@formatjs/intl-numberformat/should-polyfill';
import { shouldPolyfill as shouldPolyfillGetCanonical } from '@formatjs/intl-getcanonicallocales/should-polyfill';
import { shouldPolyfill as shouldPolyfillLocale } from '@formatjs/intl-locale/should-polyfill';
import { shouldPolyfill as shouldPolyfillPluralRules } from '@formatjs/intl-pluralrules/should-polyfill';

// eslint-disable-next-line max-statements, complexity
const initIntlNumber = async (locale: string) => {
    if (shouldPolyfillLocale()) {
        await import('@formatjs/intl-locale/polyfill');
    }

    if (shouldPolyfillGetCanonical()) {
        await import(
            '../../../node_modules/@formatjs/intl-getcanonicallocales/polyfill'
        );
    }

    const localeForLoadPlural = shouldPolyfillPluralRules(locale);
    // This locale is not supported by browser, we need to load polyfill
    if (localeForLoadPlural) {
        await import('@formatjs/intl-pluralrules/polyfill-force');
        switch (localeForLoadPlural) {
            case 'en': {
                await import('@formatjs/intl-pluralrules/locale-data/en');
                break;
            }
            case 'de': {
                await import('@formatjs/intl-pluralrules/locale-data/de');
                break;
            }
            case 'fr': {
                await import('@formatjs/intl-pluralrules/locale-data/fr');
                break;
            }
            case 'it': {
                await import('@formatjs/intl-pluralrules/locale-data/it');
                break;
            }
        }
    }

    const localeForLoad = shouldPolyfillNumberFormat(locale);
    // This locale is not supported by browser, we need to load polyfill
    if (localeForLoad) {
        await import('@formatjs/intl-numberformat/polyfill-force');
        switch (localeForLoad.toLowerCase()) {
            case 'en': {
                await import('@formatjs/intl-numberformat/locale-data/en');
                break;
            }
            case 'en-gb': {
                await import('@formatjs/intl-numberformat/locale-data/en-GB');
                break;
            }
            case 'de': {
                await import('@formatjs/intl-numberformat/locale-data/de');
                break;
            }
            case 'de-ch': {
                await import('@formatjs/intl-numberformat/locale-data/de-CH');
                break;
            }
            case 'fr': {
                await import('@formatjs/intl-numberformat/locale-data/fr');
                break;
            }
            case 'fr-ch': {
                await import('@formatjs/intl-numberformat/locale-data/fr-CH');
                break;
            }
            case 'it': {
                await import('@formatjs/intl-numberformat/locale-data/it');
                break;
            }
            case 'it-ch': {
                await import('@formatjs/intl-numberformat/locale-data/it-CH');
                break;
            }
        }
    }
};

export default initIntlNumber;
