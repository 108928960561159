import { memo } from 'react';
import type { CSSProperties, ReactNode } from 'react';
import { clsx } from 'clsx';
import styles from './VerticalLayout.module.scss';
import { alignmentValueMap, justifyValueMap } from './HorizontalLayout';

const VerticalLayout = ({
    fullWidth,
    children,
    reversed = false,
    notFullHeight = false,
    stretchChildren = false,
    gap = 'none',
    align = 'start',
    justify = 'start',
    ...ariaOrDataProps
}: {
    fullWidth?: boolean;
    notFullHeight?: boolean;
    children: ReactNode;
    reversed?: boolean;
    stretchChildren?: boolean;
    gap?: 'none' | 'micro' | 'small' | 'medium' | 'large';
    align?: keyof typeof alignmentValueMap;
    justify?: keyof typeof justifyValueMap;
}) => {
    return (
        <div
            style={
                {
                    justifyContent: justifyValueMap[justify],
                    alignItems: alignmentValueMap[align],
                    '--vertical-gap': `var(--gap-${gap})`,
                } as CSSProperties
            }
            className={clsx(styles.verticalContainer, {
                [styles.reversed]: reversed,
                [styles.stretchChildren]: stretchChildren,
                [styles[`${gap}Gap`]]: gap !== 'none',
                [styles.fullWidth]: fullWidth,
                [styles.fullHeight]: !notFullHeight,
            })}
            {...ariaOrDataProps}
        >
            {children}
        </div>
    );
};
/**
 * @deprecated
 * Please use VStack
 */
export default memo(VerticalLayout);
