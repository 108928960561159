import { memo } from 'react';
import type { IconProps } from './types';

const MenuIcon = ({ iconLabel, color }: IconProps) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="13.786"
            height="12.054"
            viewBox="0 0 13.786 12.054"
            data-icon={iconLabel}
            color={color}
        >
            <g transform="translate(-35 -91.688)">
                <line
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2px"
                    x2="11.786"
                    transform="translate(36 92.688)"
                />
                <line
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2px"
                    x2="11.786"
                    transform="translate(36 97.714)"
                />
                <line
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2px"
                    x2="11.786"
                    transform="translate(36 102.741)"
                />
            </g>
        </svg>
    );
};

export default memo(MenuIcon);
