import type { RefObject } from 'react';
import { useLayoutEffect } from 'react';
import onOutsideClick from '../../events/onOutsideClick';
import useImmutableCallback from '../useImmutableCallback';

interface UseOutsideClickParam {
    elementRef: RefObject<HTMLElement>;
    callback: () => void;
    skip?: boolean;
    rootNode?: HTMLElement;
}

const isUseOutsideClickParam = (
    param: unknown | undefined
): param is UseOutsideClickParam => {
    return !!(param as { callback?: () => void } | undefined)?.callback;
};

const getParams = (
    params: RefObject<HTMLElement> | UseOutsideClickParam,
    callback?: () => void,
    skip?: boolean
) => {
    if (isUseOutsideClickParam(params)) {
        return params;
    }
    return {
        elementRef: params,
        callback,
        skip,
        rootNode: undefined,
    };
};

function useOutsideClick(
    elementRef: RefObject<HTMLElement>,
    callback: () => void,
    skip?: boolean
): void;

function useOutsideClick(params: UseOutsideClickParam): void;

function useOutsideClick(
    params: RefObject<HTMLElement> | UseOutsideClickParam,
    callback?: () => void,
    skip?: boolean
): void {
    const {
        elementRef,
        skip: skipParam,
        callback: callbackParam,
        rootNode,
    } = getParams(params, callback, skip);
    const immutableCallback = useImmutableCallback(callbackParam);

    useLayoutEffect(() => {
        let subscription: { unsubscribe: () => void } | undefined;

        if (elementRef.current && !skipParam) {
            subscription = onOutsideClick(
                elementRef.current,
                immutableCallback,
                rootNode
            );
        }

        return () => {
            if (subscription) {
                subscription.unsubscribe();
            }
        };
    }, [elementRef, immutableCallback, skipParam, rootNode]);
}

export default useOutsideClick;
