import { useEffect, useRef } from 'react';

const useEffectWithoutMount = (
    effect: React.EffectCallback,
    deps: React.DependencyList
) => {
    const isFirst = useRef<boolean>(true);
    useEffect(() => {
        if (isFirst.current) {
            isFirst.current = false;
            return undefined;
        }

        return effect();
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, deps);
};

export default useEffectWithoutMount;
