import { memo, Component, isValidElement } from 'react';
import type { ErrorInfo, ReactElement, ReactNode } from 'react';
import type * as SentryType from '@sentry/react';
import type { ApolloError } from '@apollo/client';
import { Heading, VStack, Button, ErrorAlertBanner } from '@library';
import formatErrorMessage from '../util/formatErrorMessage';
import initSentry from '../../main/initSentry';
import assert from '../util/assert';
import styles from './ErrorBoundary.module.css';
import UnhandledError from './UnhandledError';

interface ErrorBoundaryState {
    hasError: boolean;
    error?: Error;
}

interface ErrorBoundaryProps<P, T extends P & { error: Error | ApolloError }> {
    children: ReactElement<P> | ReactElement[] | undefined;
    global?: boolean;
    customMessage?: ReactNode;
    errorComponent?(args: T): ReactNode;
}

export interface ErrorComponentProps {
    error: Error | ApolloError;
    defaultErrorMessage: ReactNode;
}

class ErrorBoundary<P, T extends P & ErrorComponentProps> extends Component<
    ErrorBoundaryProps<P, T>,
    ErrorBoundaryState
> {
    sentry?: typeof SentryType;

    // eslint-disable-next-line react/state-in-constructor
    state: ErrorBoundaryState = {
        hasError: false,
    };

    static getDerivedStateFromError(error: Error): ErrorBoundaryState {
        // eslint-disable-next-line no-console
        console.error(formatErrorMessage(error));
        // Update state so the next render will show the fallback UI.
        return { hasError: true, error };
    }

    async componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        if (APP_ENV.SENTRY_DSN) {
            if (!this.sentry) {
                this.sentry = await initSentry();
            }

            const { sentry } = this;
            assert(sentry, 'sentry is required.');

            sentry.withScope(scope => {
                scope.setExtra('componentStack', errorInfo.componentStack);
                sentry.captureException(error, scope);
            });
        }
    }

    render() {
        const { hasError, error } = this.state;
        const {
            global = false,
            customMessage,
            errorComponent,
            children,
        } = this.props;

        if (hasError) {
            if (global) {
                return (
                    <div className={styles.errorBoundary}>
                        <VStack gap="large" align="center">
                            <Heading h1 center>
                                Something Went Wrong: {error?.message}
                            </Heading>
                            <Links />
                        </VStack>
                    </div>
                );
            }

            const defaultErrorMessage = customMessage ? (
                <ErrorAlertBanner>{customMessage}</ErrorAlertBanner>
            ) : (
                <UnhandledError>{error}</UnhandledError>
            );

            if (errorComponent) {
                return errorComponent({
                    error,
                    defaultErrorMessage,
                    ...(isValidElement(children) ? children.props : undefined),
                } as T);
            }

            return defaultErrorMessage;
        }

        return children;
    }
}

const Links = memo(() => {
    return (
        <VStack gap="small" align="center">
            <Button
                onClick={() => {
                    window.location.pathname = '/';
                }}
            >
                Go To Homepage
            </Button>
        </VStack>
    );
});

export default ErrorBoundary;
