import { memo, type ReactNode } from 'react';
import ErrorAlertIcon from '../icons/ErrorAlertIcon';
import AlertBanner from './AlertBanner';

const ErrorAlertBanner = ({ children, ...props }: { children: ReactNode }) => {
    return (
        <AlertBanner
            icon={<ErrorAlertIcon small />}
            danger
            {...props}
            align="baseline"
        >
            <div>{children}</div>
        </AlertBanner>
    );
};

export default memo(ErrorAlertBanner);
