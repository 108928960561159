/* eslint-disable @typescript-eslint/no-explicit-any */
import type { RouteObject } from 'react-router-dom';
import { Navigate, Outlet } from 'react-router-dom';
import { importComponents } from '@library';
import AccessDeniedRedirect from '../core/components/router/AccessDeniedRedirect';
import PageNotFound from '../core/components/router/PageNotFound';
import RestrictedContent from '../core/components/router/RestrictedContent';

const {
    MyRequests,
    CreateTicket,
    ReturnDetails,
    CreationForm,
    SelectProductClassStep,
    SelectProductsForReturn,
    ReturnConfirmation,
    PreviewStep,
    SelectWarrantyType,
    SelectProductsForWarranty,
    WarrantyProductDetails,
    WarrantyFilesStep,
    WarrantyConfirmation,
    InquiryStep,
    InquiryConfirmation,
} = importComponents(async () => {
    return import('./service.module');
}, [
    'MyRequests',
    'CreateTicket',
    'ReturnDetails',
    'CreationForm',
    'SelectProductClassStep',
    'SelectProductsForReturn',
    'ReturnConfirmation',
    'PreviewStep',
    'SelectWarrantyType',
    'SelectProductsForWarranty',
    'WarrantyProductDetails',
    'WarrantyFilesStep',
    'WarrantyConfirmation',
    'InquiryStep',
    'InquiryConfirmation',
]);

const flowWithReasons = [
    {
        path: 'select-product-type',
        element: <SelectProductClassStep />,
        handle: {
            key: 'select-product-type',
        },
    },
    {
        path: 'select-products',
        element: <SelectProductsForReturn />,
        handle: {
            key: 'select-products',
        },
    },
    {
        path: 'preview',
        element: <PreviewStep />,
        handle: {
            key: 'preview',
        },
    },
    {
        path: 'confirmation',
        element: <ReturnConfirmation />,
        handle: {
            key: 'confirmation',
        },
    },
];

const warrantySteps = [
    {
        path: 'select-type',
        element: <SelectWarrantyType />,
        handle: {
            key: 'select-type',
        },
    },
    {
        path: 'select-products',
        element: <SelectProductsForWarranty />,
        handle: {
            key: 'select-products',
        },
    },
    {
        path: 'products-details',
        element: <WarrantyProductDetails />,
        handle: {
            key: 'products-details',
        },
    },
    {
        path: 'files',
        element: <WarrantyFilesStep />,
        handle: {
            key: 'files',
        },
    },
    {
        path: 'preview',
        element: <PreviewStep />,
        handle: {
            key: 'preview',
        },
    },
    {
        path: 'confirmation',
        element: <WarrantyConfirmation />,
        handle: {
            key: 'confirmation',
        },
    },
];
const inquiryFlow = [
    {
        path: 'create',
        element: <InquiryStep />,
        handle: {
            key: 'create',
        },
    },
    {
        path: 'confirmation',
        element: <InquiryConfirmation />,
        handle: {
            key: 'confirmation',
        },
    },
];

const serviceRoutes: RouteObject[] = [
    {
        path: 'service',
        element: (
            <RestrictedContent featureFlag="rma" fallback={PageNotFound}>
                <RestrictedContent
                    loggedInOnly
                    restrictedPage
                    fallback={AccessDeniedRedirect}
                >
                    <Outlet />
                </RestrictedContent>
            </RestrictedContent>
        ),
        children: [
            {
                path: '',
                element: <MyRequests />,
            },
            {
                path: 'create',
                element: <CreateTicket />,
            },
            {
                path: 'create/return',
                element: <CreationForm type="return" steps={flowWithReasons} />,
                children: [
                    ...flowWithReasons,
                    {
                        element: (
                            <Navigate
                                replace
                                to={`./${flowWithReasons[0].path}`}
                            />
                        ),
                        index: true,
                    },
                ],
            },
            {
                path: 'create/complaint',
                element: (
                    <CreationForm type="complaint" steps={flowWithReasons} />
                ),
                children: [
                    ...flowWithReasons,
                    {
                        element: (
                            <Navigate
                                replace
                                to={`./${flowWithReasons[0].path}`}
                            />
                        ),
                        index: true,
                    },
                ],
            },
            {
                path: 'create/warranty',
                element: <CreationForm type="warranty" steps={warrantySteps} />,
                children: [
                    ...warrantySteps,
                    {
                        element: (
                            <Navigate replace to={'./select-warranty-type'} />
                        ),
                        index: true,
                    },
                ],
            },
            {
                path: 'create/repair',
                element: <CreationForm type="repair" steps={warrantySteps} />,
                children: [
                    ...warrantySteps,
                    {
                        element: (
                            <Navigate replace to={'./select-warranty-type'} />
                        ),
                        index: true,
                    },
                ],
            },
            {
                path: 'create/inquiry',
                element: <CreationForm type="inquiry" steps={inquiryFlow} />,
                children: [
                    ...inquiryFlow,
                    {
                        element: (
                            <Navigate
                                replace
                                to={`./${flowWithReasons[0].path}`}
                            />
                        ),
                        index: true,
                    },
                ],
            },

            {
                path: 'ticket/:id',
                element: <ReturnDetails />,
            },
        ],
    },
];

export default serviceRoutes;
