import { useMemo } from 'react';
import type { Filter } from '../queries/useCategoriesTreeForFilters';
import useCategoriesTreeForFilters from '../queries/useCategoriesTreeForFilters';
import { buildTree } from '../../core/util/categories';
import addAllChildToTree from '../util/addAllChildToTree';
import flattenTree from '../util/flattenTree';
import type Category from '../../core/types/category';

const includeCategory = <T extends { allChildren?: T[]; id: string }>(
    category: T,
    id: string
) => {
    return (
        category.allChildren &&
        category.allChildren.find(child => child.id === id)
    );
};

const getCurrentAvailableCategories = (
    maxLevelOfFinalTree: number | undefined,
    categories?: { category: { id: string } }[],
    categoriesTree?: Category[]
) => {
    if (categories && categoriesTree) {
        const treeWithAllChild = addAllChildToTree(categoriesTree);
        const allCategories = flattenTree(
            treeWithAllChild,
            maxLevelOfFinalTree
        );
        return allCategories
            .filter(rootCategory =>
                categories.some(
                    category =>
                        includeCategory(rootCategory, category.category.id) ||
                        category.category.id === rootCategory.id
                )
            )
            .map(category => {
                const { allChildren, ...item } = category;

                return {
                    ...item,
                };
            });
    }
    return [];
};

const useProductsCategoriesTree = ({
    filters,
    skip,
    categoryId,
    categoriesTree,
    maxLevelOfFinalTree = 2,
}: {
    filters: Filter[];
    skip: boolean;
    categoryId?: string;
    categoriesTree?: Category[];
    maxLevelOfFinalTree?: number;
}) => {
    const { items: categories, loading } = useCategoriesTreeForFilters({
        filters,
        skip,
        categoryId,
    });

    const currentAvailableCategories = useMemo(
        () =>
            getCurrentAvailableCategories(
                maxLevelOfFinalTree,
                categories,
                categoriesTree
            ),
        [categories, categoriesTree, maxLevelOfFinalTree]
    );

    const items = useMemo(() => {
        return buildTree(currentAvailableCategories);
    }, [currentAvailableCategories]);

    return {
        items,
        total: currentAvailableCategories.length,
        loading,
    };
};

export default useProductsCategoriesTree;
