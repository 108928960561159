import { memo, useMemo } from 'react';
import type { ReactNode } from 'react';
import useCheckoutStockLocation from '../../../cart/queries/useCheckoutStockLocation';
import StockLocationContext from '../../stockLocationContext';

const StockLocationProvider = ({ children }: { children: ReactNode }) => {
    const {
        checkoutStockLocation,
        updateCheckoutStockLocation,
        isUpdatePending,
        error,
    } = useCheckoutStockLocation();

    const providerValue = useMemo(
        () => ({
            checkoutStockLocation,
            updateCheckoutStockLocation,
            isUpdatePending,
        }),
        [checkoutStockLocation, isUpdatePending, updateCheckoutStockLocation]
    );

    if (error) {
        throw error;
    }

    return (
        <StockLocationContext.Provider value={providerValue}>
            {children}
        </StockLocationContext.Provider>
    );
};

export default memo(StockLocationProvider);
