import { createContext } from 'react';
import type { ActivePreorderSeason } from './queries/useActivePreorderSeasons';

export interface PreorderModeContextValues {
    isInPreorderMode: boolean;
    onlyPreorderProducts: boolean;
    preorderSeasonId?: number;
    selectedPreorderSeason?: ActivePreorderSeason;
    activePreorderSeasons: ActivePreorderSeason[];
    productsFilters: {
        preorderSeasonId?: number;
        includePreorderSeasonId?: number;
        isSalesRepresentative?: boolean;
    };
    updateSelectedPreorderSeason(id: number): void;
}

const PreorderModeContext = createContext<PreorderModeContextValues>({
    isInPreorderMode: false,
    onlyPreorderProducts: false,
    activePreorderSeasons: [],
    productsFilters: {
        isSalesRepresentative: false,
    },
    updateSelectedPreorderSeason: () => {
        // noop
    },
});

export default PreorderModeContext;
