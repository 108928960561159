import { createContext } from 'react';
import type { UserAddress } from '../../user/types';
import type { CheckoutPriceSummary } from '../queries/useCheckoutQuery';
import type {
    Branch,
    CheckoutErrorType,
    CheckoutWarningType,
    ShippingMethod,
} from '../types';

export interface CheckoutFields {
    billingAddress?: UserAddress;
    shippingAddress: UserAddress[];
    branches: Branch[];
    shippingMethods: ShippingMethod[];
    errorCodes: CheckoutErrorType[];
    warnings: CheckoutWarningType[];
    isDropShipmentEnabled: boolean;
    requiresPrepay: boolean;
    isPaymentTermsMutable: boolean;
    checkoutPriceSummary?: CheckoutPriceSummary;
}

export interface CheckoutContextValue {
    loading: boolean;
    initialized: boolean;
    checkoutFields: CheckoutFields;
}

const CheckoutContext = createContext<CheckoutContextValue>({
    loading: false,
    initialized: false,
    checkoutFields: {
        billingAddress: undefined,
        shippingAddress: [],
        branches: [],
        shippingMethods: [],
        errorCodes: [],
        isDropShipmentEnabled: false,
        requiresPrepay: false,
        isPaymentTermsMutable: false,
        warnings: [],
    },
});

export default CheckoutContext;
