import type { ReactNode } from 'react';
import { memo, useContext, useRef } from 'react';
import { Helmet } from 'react-helmet';
import useStoreAndLanguageParams from '../hooks/useStoreAndLanguageParams';
import { AppContext } from '../context';
import useCurrentStore from '../hooks/useCurrentStore';
import MaintenanceBanner from '../../main/components/MaintenanceMode/MaintenanceBanner';
import Header from './Header';
import styles from './Layout.module.scss';
import Footer from './footer/StoreFooter';
import ScrollToTop from './ScrollToTop';
import withErrorBoundary from './errors/withErrorBoundary';
import ComponentSuspense from './router/ComponentSuspense';
import LazyComponent from './router/LazyComponent';

interface LayoutProps {
    children: ReactNode;
}

const ImpersonationActiveBanner = LazyComponent(
    async () => import('./layout/ImpersonationActiveBanner')
);

export const Layout = withErrorBoundary(
    memo(({ children }: LayoutProps) => {
        const store = useStoreAndLanguageParams();
        const { companyName: title } = useCurrentStore();
        const { isImpersonating } = useContext(AppContext);

        const arePreOrderRoutes = window.location.href.includes('preorder');

        const headerRef = useRef<HTMLDivElement>(null);

        return (
            <>
                <Helmet defer={false} htmlAttributes={{ lang: store.language }}>
                    {<title>{title}</title>}
                    {title && <meta name="description" content={title} />}
                </Helmet>
                <div className={styles.layout}>
                    {isImpersonating && (
                        <ComponentSuspense height="40px">
                            <ImpersonationActiveBanner />
                        </ComponentSuspense>
                    )}
                    <Header ref={headerRef} />
                    <MaintenanceBanner />
                    <main className={styles.mainContent}>{children}</main>
                    <Footer />
                </div>
                {arePreOrderRoutes && <ScrollToTop headerRef={headerRef} />}
            </>
        );
    })
);
