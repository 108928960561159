import { useContext } from 'react';
import UserContext from '../components/UserContext';
import assert from '../../core/util/assert';

const useCurrentCustomer = () => {
    return useContext(UserContext).customer;
};

export const useCurrentCustomerLoggedInOnly = () => {
    const { customer } = useContext(UserContext);
    assert(customer, 'User is not logged in');
    return customer;
};

export default useCurrentCustomer;
