import { memo } from 'react';
import { capitalize } from 'lodash-es';
import { SystemMaintenanceTypeEnum, type SystemMaintenance } from '@apiTypes';
import {
    AlertBanner,
    Paragraph,
    RepairBoxInformationIcon,
    VStack,
} from '@library';
import useStoreAndLanguageParams from '../../../core/hooks/useStoreAndLanguageParams';
import useSystemMaintenance from '../../queries/useSystemMaintenances';
import Container from '../../../core/components/Container';

const MaintenanceBanner = memo(() => {
    const { language } = useStoreAndLanguageParams();
    const { systemMaintenance } = useSystemMaintenance();
    if (
        !systemMaintenance ||
        systemMaintenance.type !== SystemMaintenanceTypeEnum.Partial
    ) {
        return null;
    }
    return (
        <VStack>
            <Container>
                <div>
                    <VStack gap="small">
                        <div />
                        <AlertBanner
                            information
                            icon={<RepairBoxInformationIcon />}
                            align="center"
                        >
                            <VStack gap="mini">
                                <Paragraph medium>
                                    {
                                        systemMaintenance[
                                            `message${capitalize(
                                                language
                                            )}` as keyof SystemMaintenance
                                        ]
                                    }
                                </Paragraph>
                            </VStack>
                        </AlertBanner>
                    </VStack>
                </div>
            </Container>
        </VStack>
    );
});

export default MaintenanceBanner;
