import { gql, useQuery } from '@apollo/client';
import { useContext } from 'react';

import useStoreAndLanguageParams from '../../core/hooks/useStoreAndLanguageParams';
import type { CategoryBreakdown } from '../../product/queries/types';
import PreorderModeContext from '../../preorder/PreorderModeContext';
import type { ProductItem } from '../../product/types';

const categoriesTreeForFiltersQuery = gql`
    query categoriesTreeForFiltersQuery(
        $store: ID!
        $categoryId: ID
        $language: Language!
        $filters: [ProductListFilter!]
        $preorderSeasonId: Int
        $includePreorderSeasonId: Int
    ) {
        rootCategories: products(
            categoryId: $categoryId
            store: $store
            language: $language
            filters: $filters
            preorderSeasonId: $preorderSeasonId
            includePreorderSeasonId: $includePreorderSeasonId
            includeExcludedCategories: false
        ) {
            categories {
                category {
                    id
                    key
                    parentId
                    name
                }
            }
        }
    }
`;

export interface Filter {
    name: string;
    values: string[];
}

const useCategoriesTreeForFilters = ({
    filters,
    skip,
    categoryId,
}: {
    filters: Filter[];
    skip: boolean;
    categoryId?: string;
}) => {
    const { store, language } = useStoreAndLanguageParams();

    const { productsFilters } = useContext(PreorderModeContext);

    const {
        loading,
        error,
        data: { rootCategories: { categories } } = {
            rootCategories: {
                categories: undefined,
            },
        },
    } = useQuery<{
        products: {
            items: ProductItem[];
        };
        categories: {
            total: number;
        };
        rootCategories: {
            categories: CategoryBreakdown[];
        };
    }>(categoriesTreeForFiltersQuery, {
        variables: {
            store,
            language,
            filters,
            categoryId,
            ...productsFilters,
        },
        fetchPolicy: 'cache-first',
        skip,
    });

    return {
        items: categories,
        loading,
        error,
    };
};

export default useCategoriesTreeForFilters;
