import type { ReactNode } from 'react';
import { memo, useEffect, useMemo } from 'react';
import NetworkStatusContext, {
    NetworkStatus,
} from '../../core/contexts/NetworkStatusContext';
import useObservableVariableState from '../../core/hooks/useObservableVariableState';
import makeObservableVariable from '../../core/util/makeObservableVariable';

const NetworkStatusProvider = ({ children }: { children: ReactNode }) => {
    const status = makeObservableVariable(
        navigator.onLine ? NetworkStatus.ONLINE : NetworkStatus.OFFLINE
    );

    const [networkStatus] = useObservableVariableState(status);

    useEffect(() => {
        const onOnline = () => {
            status.updateValue(NetworkStatus.ONLINE);
        };

        const onOffline = () => {
            status.updateValue(NetworkStatus.OFFLINE);
        };

        window.addEventListener('online', onOnline);
        window.addEventListener('offline', onOffline);

        return () => {
            window.removeEventListener('online', onOnline);
            window.removeEventListener('offline', onOffline);
        };
    }, [status]);

    const contextValue = useMemo(() => ({ networkStatus }), [networkStatus]);

    return (
        <NetworkStatusContext.Provider value={contextValue}>
            {children}
        </NetworkStatusContext.Provider>
    );
};

export default memo(NetworkStatusProvider);
