import { memo } from 'react';
import type { IconProps } from './types';
import BaseIcon from './BaseIcon';

const ErrorAlertIcon = memo((props: IconProps) => {
    return (
        <BaseIcon viewBox="0 0 31 30" {...props} fill="none">
            <g clipPath="url(#clip0_13725_64256)">
                <path
                    d="M30.046 23.412L18.4001 3.24078C17.7948 2.19231 16.7106 1.56641 15.5 1.56641C14.2894 1.56641 13.2052 2.19236 12.5998 3.24078L0.954 23.412C0.348667 24.4604 0.348667 25.7123 0.954 26.7608C1.55933 27.8092 2.6435 28.4351 3.85416 28.4351H27.1459C28.3565 28.4351 29.4407 27.8092 30.046 26.7608C30.6513 25.7123 30.6513 24.4604 30.046 23.412ZM28.5226 25.8812C28.2352 26.3789 27.7206 26.676 27.1459 26.676H3.85416C3.27947 26.676 2.76478 26.3789 2.47744 25.8812C2.1901 25.3835 2.1901 24.7891 2.47744 24.2914L14.1233 4.12028C14.4107 3.62258 14.9253 3.32545 15.5 3.32545C16.0747 3.32545 16.5894 3.62258 16.8767 4.12028L28.5226 24.2914C28.8099 24.7891 28.8099 25.3835 28.5226 25.8812Z"
                    fill="currentColor"
                />
                <path
                    d="M15.4999 20.8125C14.8533 20.8125 14.3271 21.3386 14.3271 21.9853C14.3271 22.6319 14.8533 23.158 15.4999 23.158C16.1465 23.158 16.6727 22.6319 16.6727 21.9853C16.6727 21.3386 16.1465 20.8125 15.4999 20.8125Z"
                    fill="currentColor"
                />
                <path
                    d="M16.3795 9.96484H14.6204V19.0536H16.3795V9.96484Z"
                    fill="currentColor"
                />
            </g>
            <defs>
                <clipPath id="clip0_13725_64256">
                    <rect
                        width="30"
                        height="30"
                        fill="white"
                        transform="translate(0.5)"
                    />
                </clipPath>
            </defs>
        </BaseIcon>
    );
});
export default memo(ErrorAlertIcon);
