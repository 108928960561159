import { memo } from 'react';
import { Outlet } from 'react-router-dom';
import { Layout } from '../../core/components/Layout';
import PageSuspense from '../../core/components/router/PageSuspense';

const AppLayout = memo(() => {
    return (
        <Layout>
            <PageSuspense>
                <Outlet />
            </PageSuspense>
        </Layout>
    );
});

export default AppLayout;
