import Cookies from 'js-cookie';

const accessTokenKey = 'accessToken';
const accessTokenTimeKey = 'accessTokenTime';
const TOKEN_LIFETIME = 7 * 24 * 60 * 60 * 1000;

export const getAccessToken = () => {
    return localStorage.getItem(accessTokenKey) || Cookies.get(accessTokenKey);
};

export const storeAccessToken = (value: string, persist?: boolean) => {
    if (persist) {
        localStorage.setItem(accessTokenKey, value);
        localStorage.setItem(accessTokenTimeKey, Date.now().toString());
        Cookies.remove(accessTokenKey, {
            path: '/',
            sameSite: 'strict',
        });
        Cookies.remove(accessTokenTimeKey, {
            path: '/',
            sameSite: 'strict',
        });
    } else {
        Cookies.set(accessTokenKey, value, {
            path: '/',
            sameSite: 'strict',
        });
        Cookies.set(accessTokenTimeKey, Date.now().toString(), {
            path: '/',
            sameSite: 'strict',
        });
        localStorage.removeItem(accessTokenKey);
        localStorage.removeItem(accessTokenTimeKey);
    }
};

export const clearAccessToken = () => {
    localStorage.removeItem(accessTokenKey);
    localStorage.removeItem(accessTokenTimeKey);
    Cookies.remove(accessTokenKey, {
        path: '/',
        sameSite: 'strict',
    });
    Cookies.remove(accessTokenTimeKey, {
        path: '/',
        sameSite: 'strict',
    });
};

export const getLastUpdate = () => {
    return +(
        localStorage.getItem(accessTokenTimeKey) ||
        Cookies.get(accessTokenTimeKey) ||
        0
    );
};

export const getAccessTokenPersistence = () => {
    return !!localStorage.getItem('accessToken');
};

export const getNotExpiredToken = () => {
    const token = getAccessToken();
    const lastUpdate = getLastUpdate();
    if (Date.now() - lastUpdate >= TOKEN_LIFETIME && token) {
        clearAccessToken();
        return undefined;
    }
    return token;
};

export const getPermissionToken = () => {
    return (
        new URLSearchParams(window.location.search).get('permissionToken') ??
        undefined
    );
};
