import { gql } from '@apollo/client';

const userCartQuery = gql`
    query userCart($store: ID!, $id: Int!) {
        cart(store: $store, id: $id) {
            items {
                sku
                quantity
                comment
            }
        }
    }
`;

export default userCartQuery;
