import { memo } from 'react';
import { clsx } from 'clsx';
import { TextBlock } from '@library';
import Cart from '../../core/components/icons/CartIcon';
import usePriceFormatter from '../../core/hooks/usePriceFormatter';
import Badge from '../../core/components/Badge';
import useCustomerPriceVisibility from '../../core/hooks/useCustomerPriceVisibility';
import useTabletView from '../../core/hooks/dom/useTabletView';
import CartSmallIcon from '../../core/components/icons/CartSmallIcon';
import useCartTotalQuantity from '../../cart/hooks/useCartTotalQuantity';
import useCartTotalPrice from '../../cart/queries/useCartTotalPrice';
import useCustomNavigate from '../../core/hooks/router/useCustomNavigate';
import withErrorBoundary from '../../core/components/errors/withErrorBoundary';
import ErrorIcon from '../../core/components/icons/ErrorIcon';
import styles from './CartIcon.module.scss';

const CartTotalQuantity = memo(
    ({
        totalQuantity,
        tabletView,
    }: {
        totalQuantity?: number;
        tabletView?: boolean;
    }) => {
        if (totalQuantity === undefined) {
            return null;
        }

        return (
            <div
                className={clsx(styles.badge, {
                    [styles.tabletView]: tabletView,
                })}
                data-test-id="header-cart-item-count"
            >
                <Badge secondary={!tabletView} primary={tabletView} small>
                    {totalQuantity <= 99 ? totalQuantity : '99+'}
                </Badge>
            </div>
        );
    }
);

const CartAmount = memo(
    ({
        customerPrice,
    }: {
        customerPrice?: { retailPrice: number; finalPrice?: number };
    }) => {
        const priceFormatter = usePriceFormatter();
        const [showCustomerPrice] = useCustomerPriceVisibility();
        const { price, currency } = priceFormatter(
            showCustomerPrice
                ? customerPrice?.finalPrice
                : customerPrice?.retailPrice
        );

        const formattedPrice =
            currency === 'EUR' ? `€ ${price}` : `${price} ${currency}`;

        return (
            <div className={styles.amount} data-test-id="headerCartTotalPrice">
                <TextBlock caption uppercase center>
                    {formattedPrice}
                </TextBlock>
            </div>
        );
    }
);

const CartIcon = memo(() => {
    const navigate = useCustomNavigate();

    const totalQuantity = useCartTotalQuantity();
    const totalPrice = useCartTotalPrice();

    const isTabletView = useTabletView();

    const goToCart = () => {
        navigate('cart');
    };

    return (
        <div
            role="button"
            tabIndex={0}
            data-test-id="cart-of-current-user"
            aria-label="cart of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={goToCart}
            className={styles.cartIconContainer}
            onKeyPress={(e: React.KeyboardEvent<HTMLDivElement>) => {
                if (e.key === 'Enter' || e.key === ' ') {
                    goToCart();
                }
            }}
        >
            <TextBlock caption>
                <div className={styles.buttonChildren}>
                    <div className={styles.iconWrapper}>
                        <div className={styles.cartIcon}>
                            {isTabletView ? <CartSmallIcon /> : <Cart />}
                        </div>
                        <CartTotalQuantity
                            totalQuantity={totalQuantity}
                            tabletView={isTabletView}
                        />
                    </div>
                    {!isTabletView && <CartAmount customerPrice={totalPrice} />}
                </div>
            </TextBlock>
        </div>
    );
});

export default memo(
    withErrorBoundary(CartIcon, {
        errorComponent: () => (
            <div className={styles.amount} data-test-id="headerCartTotalPrice">
                <ErrorIcon />
            </div>
        ),
    })
);
