import { memo } from 'react';
import type { IconProps } from './types';

const AlertIcon = ({
    iconLabel,
    color,
    large = false,
    extraLarge = false,
}: IconProps & { large?: boolean; extraLarge?: boolean }) => {
    let size = 30.442;

    if (large) {
        size = 45;
    }

    if (extraLarge) {
        size = 67;
    }

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={`${size}`}
            height={`${size}`}
            viewBox="0 0 30.442 30.442"
            data-icon={iconLabel}
            color={color}
        >
            <g className="a" fill="#fff" stroke="currentColor">
                <circle
                    className="d"
                    fill="none"
                    cx="15.221"
                    cy="15.221"
                    r="14.721"
                />
            </g>
            <text
                className="b"
                fill="currentColor"
                fontSize="21px"
                fontFamily="Roboto"
                fontWeight="300"
                letterSpacing="0.15em"
                transform="translate(15.125 22.231)"
            >
                <tspan x="-2.369" y="0">
                    !
                </tspan>
            </text>
        </svg>
    );
};

export default memo(AlertIcon);
