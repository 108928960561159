import { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Heading } from '@library';
import Container from '../Container';
import styles from './PageNotFound.module.css';

const PageNotFound = () => {
    return (
        <Container>
            <div className={styles.container}>
                <Heading h3>
                    <FormattedMessage id="page not found" />
                </Heading>
            </div>
        </Container>
    );
};

export default memo(PageNotFound);
