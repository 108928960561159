import { gql } from '@apollo/client';
import useCancelableQuery from '../../core/hooks/apollo/useCancelableQuery';

const useBrandsSearch = (store: string, search: string) =>
    useCancelableQuery<{
        brands: {
            name: string;
            logo?: string;
            slug: string;
        }[];
    }>(
        gql`
            query brandsSearch($store: ID!, $search: String) {
                brands(store: $store, search: $search, page: 1, pageSize: 4) {
                    name
                    logo
                    slug
                }
            }
        `,
        {
            variables: {
                store,
                search,
            },
            skip: search.length < 2,
        },
        true
    );

export default useBrandsSearch;
