import { useState, useEffect } from 'react';

const useMatchMedia = (query: string) => {
    const [matches, setMatches] = useState(
        () => window.matchMedia(query).matches
    );
    useEffect(() => {
        const mediaQueryList = window.matchMedia(query);
        const onChange = (event: MediaQueryListEvent) => {
            setMatches(event.matches);
        };

        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (mediaQueryList.addEventListener) {
            mediaQueryList.addEventListener('change', onChange);
        } else {
            // cspell: words NOSONAR
            mediaQueryList.addListener(onChange); // NOSONAR
        }

        return () => {
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            if (mediaQueryList.removeEventListener) {
                mediaQueryList.removeEventListener('change', onChange);
            } else {
                mediaQueryList.removeListener(onChange); // NOSONAR
            }
        };
    }, [query]);

    return matches;
};

export default useMatchMedia;
