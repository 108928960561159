import type { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { allCategoriesQuery } from '../core/queries/useCategories';
import { currentStoresQuery } from '../core/queries/useCurrentStoresQuery';

const initStoresAndCategoriesData = (
    client: ApolloClient<NormalizedCacheObject>
) => {
    const storesData = APP_ENV.STORES;
    if (storesData && Object.keys(storesData).length > 0) {
        client.writeQuery({
            query: currentStoresQuery,
            variables: {},
            data: storesData.data,
        });
    }

    const categoriesData = APP_ENV.MAIN_CATEGORIES;

    if (categoriesData && Object.keys(categoriesData).length > 0) {
        for (const [store, data] of Object.entries(categoriesData)) {
            for (const [language, categoryData] of Object.entries(data)) {
                client.writeQuery({
                    query: allCategoriesQuery,
                    variables: {
                        store,
                        language,
                    },
                    data: categoryData.data,
                });
            }
        }
    }
};

export default initStoresAndCategoriesData;
