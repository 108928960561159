import {
    isComponent,
    isComponentsArray,
    isContentType,
    isDateVisibility,
    isHideForProps,
} from '../types/types';

type Node = Record<string, unknown>;

const checkHideFor = (item: unknown, isLogged?: boolean) => {
    if (isHideForProps(item) && item.hide_for.length) {
        const typeUser = isLogged ? 'logged-in' : 'logged-out';
        if (item.hide_for.includes(typeUser)) {
            return false;
        }
    }
    return true;
};

const checkDate = (item: unknown, now: Date) => {
    if (isDateVisibility(item)) {
        const startDate =
            item.start_date &&
            new Date(`${item.start_date.replace(' ', 'T')}Z`);
        const endDate =
            item.end_date && new Date(`${item.end_date.replace(' ', 'T')}Z`);
        const isLater = !startDate || now >= startDate;
        const isBefore = !endDate || now <= endDate;
        return isLater && isBefore;
    }
    return true;
};

const filterByVisibility = <T>(
    item: T,
    params: { now: Date; isLogged: boolean }
) => {
    if (
        !item ||
        !checkHideFor(item, params.isLogged) ||
        !checkDate(item, params.now)
    ) {
        return null;
    }

    Object.entries(item as Node).forEach(([key, value]) => {
        const node = item as Node;
        if (!value) {
            return;
        }
        if (
            Array.isArray(value) &&
            (isComponentsArray(value) || value.some(isContentType))
        ) {
            node[key] = value
                .filter(child => filterByVisibility(child, params))
                .filter(child => child);
        }
        if (isComponent(value) || isContentType(value)) {
            node[key] = filterByVisibility(value, params);
        }
    });

    return item as Partial<T>;
};

export default filterByVisibility;
