import { memo, useContext, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import {
    HStack,
    Modal,
    Text,
    Button,
    VStack,
    Collapsible,
    Switch,
    ClickableArea,
    SidePane,
    TextBlock,
} from '@library';
import {
    useCookieConsent,
    useCookieConsentActions,
} from '../context/CookieConsentContext';
import type { ConsentCategory, Consent } from '../context/CookieConsentService';
import useEffectWithoutMount from '../../core/util/useEffectWithoutMount';

import { AppContext } from '../../core/context';
import CustomLinkButton from '../../core/components/CustomLinkButton';
import styles from './ConsentSettings.module.scss';

const items: {
    key: ConsentCategory;
}[] = [
    {
        key: 'necessary',
    },
    {
        key: 'performance',
    },
    {
        key: 'functional',
    },
    {
        key: 'advertising',
    },
];

const ConsentCategoryItem = memo(
    ({
        category,
        alwaysOn,
        value,
        last,
        onChange,
    }: {
        category: ConsentCategory;
        alwaysOn: boolean;
        last: boolean;
        value: boolean;
        onChange: (value: boolean) => void;
    }) => {
        return (
            <Collapsible
                theme={styles.collapsibleTheme}
                trigger={
                    <HStack justify="spaceBetween" align="center">
                        <Text medium>
                            <FormattedMessage
                                id={`Cookie Settings Category_${category} Title`}
                            />
                        </Text>
                        <ClickableArea
                            onPress={event => {
                                event.stopPropagation();
                            }}
                        >
                            <Switch
                                name={category}
                                value={value}
                                onChange={newValue => {
                                    if (alwaysOn) {
                                        return;
                                    }
                                    onChange(newValue);
                                }}
                                disabled={alwaysOn}
                            />
                        </ClickableArea>
                    </HStack>
                }
                wide
                borderType={!last ? 'bottom' : 'none'}
                triggerSeparator
                key={category}
            >
                <TextBlock>
                    <FormattedMessage
                        id={`Cookie Settings Category_${category} Text`}
                        values={{
                            br: <div className={styles.lineBreak} />,
                        }}
                    />
                </TextBlock>
            </Collapsible>
        );
    }
);

const ConsentSettings = ({
    open,
    onOpenChange,
}: {
    open: boolean;
    onOpenChange: (value: boolean) => void;
}) => {
    const { consent } = useCookieConsent();
    const {
        acceptAll,
        acceptAllProgress,
        declineAll,
        declineAllProgress,
        acceptProgress,
        accept,
    } = useCookieConsentActions();

    const { isInMobileView } = useContext(AppContext);

    const [state, setState] = useState<Consent>(
        consent || {
            necessary: true,
            performance: false,
            functional: false,
            advertising: false,
        }
    );

    useEffectWithoutMount(() => {
        if (consent) {
            setState(consent);
        }
    }, [consent]);

    const buttonBlock = (
        <HStack gap="small" wrap>
            <div className={styles.confirmButton}>
                <Button
                    secondary
                    justify="center"
                    onClick={() => {
                        accept(state)
                            .then(() => {
                                onOpenChange(false);
                            })
                            .catch(ex => {
                                throw ex;
                            });
                    }}
                    loading={acceptProgress}
                >
                    <FormattedMessage id="Cookie Settings SaveButton" />
                </Button>
            </div>
            <div className={styles.buttons}>
                <div className={styles.button}>
                    <Button
                        justify="center"
                        onClick={() => {
                            acceptAll()
                                .then(() => {
                                    onOpenChange(false);
                                })
                                .catch(ex => {
                                    throw ex;
                                });
                        }}
                        loading={acceptAllProgress}
                    >
                        <FormattedMessage id="Cookie AcceptButton" />
                    </Button>
                </div>
                <div className={styles.button}>
                    <Button
                        justify="center"
                        onClick={() => {
                            declineAll()
                                .then(() => {
                                    onOpenChange(false);
                                })
                                .catch(ex => {
                                    throw ex;
                                });
                        }}
                        loading={declineAllProgress}
                    >
                        <FormattedMessage id="Cookie DeclineButton" />
                    </Button>
                </div>
            </div>
        </HStack>
    );

    const content = (
        <div className={styles.container}>
            <VStack gap="smallMedium">
                <Text>
                    <FormattedMessage
                        id="Cookie Settings Text"
                        values={{
                            link: chunks => (
                                <CustomLinkButton
                                    text
                                    inline
                                    target="_blank"
                                    to="privacy"
                                >
                                    {chunks}
                                </CustomLinkButton>
                            ),
                            br: <div className={styles.lineBreak} />,
                        }}
                    />
                </Text>
                <VStack gap="small">
                    <Text medium>
                        <FormattedMessage id="Cookie Settings Consent preferences" />
                    </Text>

                    <div>
                        {items.map((item, idx) => {
                            return (
                                <ConsentCategoryItem
                                    key={item.key}
                                    category={item.key}
                                    value={state[item.key]}
                                    onChange={value => {
                                        setState({
                                            ...state,
                                            [item.key]: value,
                                        });
                                    }}
                                    alwaysOn={item.key === 'necessary'}
                                    last={idx === items.length - 1}
                                />
                            );
                        })}
                    </div>
                </VStack>
                {!isInMobileView && buttonBlock}
            </VStack>
        </div>
    );

    const header = <FormattedMessage id="Cookie Settings Title" />;
    if (isInMobileView) {
        return (
            <SidePane
                side="bottom"
                open={open}
                header={header}
                onOpenChange={onOpenChange}
                footer={buttonBlock}
                autoHeight
            >
                {content}
            </SidePane>
        );
    }

    return (
        <Modal open={open} onOpenChange={onOpenChange} header={header}>
            {content}
        </Modal>
    );
};

export default memo(ConsentSettings);
