import { useContext } from 'react';
import ContentClientContext from '../ContentClientContext';

const useContentClient = () => {
    const { contentClient } = useContext(ContentClientContext);
    if (!contentClient) {
        throw new Error('Content client is not found');
    }
    return contentClient;
};

export default useContentClient;
