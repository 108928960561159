import { gql, useQuery } from '@apollo/client';

import type { QuerySystemMaintenancesArgs, SystemMaintenance } from '@apiTypes';
import usePolledQuery from '../../core/hooks/apollo/usePolledQuery';

export const systemMaintenancesQuery = gql`
    query systemMaintenances {
        activeSystemMaintenance {
            id
            messageDe
            messageEn
            messageFr
            messageIt
            type
        }
    }
`;

export const useSystemMaintenancePolled = () => {
    return usePolledQuery(systemMaintenancesQuery, {
        pollInterval: 120_000,
    });
};

const useSystemMaintenance = () => {
    const { data, error } = useQuery<
        {
            activeSystemMaintenance: SystemMaintenance | undefined;
        },
        QuerySystemMaintenancesArgs
    >(systemMaintenancesQuery, {
        fetchPolicy: 'cache-and-network',
    });

    return {
        systemMaintenance: data?.activeSystemMaintenance,
        error,
    };
};

export default useSystemMaintenance;
