import { memo } from 'react';
import AccessDeniedRedirect from '../core/components/router/AccessDeniedRedirect';
import LazyComponent from '../core/components/router/LazyComponent';
import RestrictedContent from '../core/components/router/RestrictedContent';

const ShareCart = LazyComponent(
    async () => import('./components/ShareCart/ShareCart')
);

const RestrictedCartPage = LazyComponent(
    async () => import('./components/CartPage/RestrictedCartPage')
);
const CartsOverview = LazyComponent(
    async () => import('./components/CartOverview/CartsOverview')
);

const RestrictedCartsOverview = memo(() => {
    return (
        <RestrictedContent loggedInOnly fallback={AccessDeniedRedirect}>
            <CartsOverview />
        </RestrictedContent>
    );
});

const CartRoutes = [
    {
        path: 'cart',
        element: <RestrictedCartPage />,
    },
    {
        path: 'carts',
        element: <RestrictedCartsOverview />,
    },
    { path: 'share-cart/:code', element: <ShareCart /> },
];

export default CartRoutes;
