import { gql, useQuery } from '@apollo/client';
import { useContext } from 'react';
import PreorderModeContext from '../../preorder/PreorderModeContext';
import useStoreAndLanguageParams from '../hooks/useStoreAndLanguageParams';
import type { CategoryWithFilters } from '../types/category';

export const allCategoriesQuery = gql`
    query allCategories(
        $store: ID!
        $language: Language!
        $preorderSeasonId: Int
        $includePreorderSeasonId: Int
    ) {
        categories(
            store: $store
            language: $language
            preorderSeasonId: $preorderSeasonId
            includePreorderSeasonId: $includePreorderSeasonId
        ) {
            items {
                parentId
                key
                id
                name
                hiddenInTree
                filters {
                    id
                    field
                }
                path {
                    key
                    id
                    name
                    hiddenInTree
                }
            }
        }
    }
`;

const useCategories = (
    initial?: boolean,
    options: {
        preorderSeasonId?: number;
    } = {}
) => {
    const { store, language } = useStoreAndLanguageParams();
    const { productsFilters } = useContext(PreorderModeContext);
    const {
        error,
        loading,
        data: { categories: { items } } = { categories: { items: [] } },
    } = useQuery<{
        categories: {
            items: CategoryWithFilters[];
        };
    }>(allCategoriesQuery, {
        variables: {
            store,
            language,
            ...productsFilters,
            ...options,
        },
        fetchPolicy: initial ? 'cache-and-network' : 'cache-first',
        nextFetchPolicy: 'cache-first',
    });

    if (error) {
        throw error;
    }

    return {
        categories: items,
        loading,
    };
};

export default useCategories;
