import type { ReactNode } from 'react';
import { memo } from 'react';
import { clsx } from 'clsx';
import styles from './Icon.module.scss';
import type { IconProps } from './types';

const BaseIcon = memo(
    ({
        iconLabel,
        color,
        small = false,
        theme,
        mini,
        viewBox,
        children,
        size,
        ...props
    }: IconProps & {
        /**
         * The View Box of icon
         */
        viewBox: string;
        children: ReactNode;
    } & Pick<React.SVGProps<SVGSVGElement>, 'fill'>) => {
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox={viewBox}
                data-icon={iconLabel}
                color={color}
                className={clsx(theme, styles.icon, {
                    [styles.small]: small,
                    [styles.mini]: mini,
                })}
                style={
                    size
                        ? ({
                              '--icon-size': `${size}px`,
                          } as React.CSSProperties)
                        : undefined
                }
                {...props}
            >
                {children}
            </svg>
        );
    }
);

export default memo(BaseIcon);
