import { useLocation } from 'react-router-dom';
import useStoreAndLanguageParams from './useStoreAndLanguageParams';

const useAccessDeniedRedirect = () => {
    const { storeRootPath } = useStoreAndLanguageParams();
    const { pathname, search } = useLocation();

    const searchParams = new URLSearchParams(search);

    const targetUrl = `${pathname.replace(
        storeRootPath,
        ''
    )}?${searchParams.toString()}`;

    return { targetUrl };
};

export default useAccessDeniedRedirect;
