import type { OperationVariables } from '@apollo/client';
import { useApolloClient } from '@apollo/client';
import type { WatchQueryOptions } from '@apollo/client/core/watchQueryOptions';
import equal from 'fast-deep-equal';
import type { DocumentNode } from 'graphql';
import { useCallback, useRef, useSyncExternalStore } from 'react';
import useEffectWithoutMount from '../../util/useEffectWithoutMount';

/*
 * Apollo query hook which aborts request, if any of options are changed during different renders.
 * Please use it only if you need cancelable requests, it's not default hook.
 * Use useCachedQuery or useQuery by default. useQuery and useCachedQuery abort requests on unMount.
 * */

const useCancelableQuery = <
    TData,
    TVariables extends OperationVariables = OperationVariables,
>(
    query: DocumentNode,
    options: Omit<WatchQueryOptions<TVariables, TData>, 'query'> & {
        skip?: boolean;
    },
    isCached?: boolean
) => {
    const apolloClient = useApolloClient();

    // `onError` and `onCompleted` callback functions will not always have a
    // stable identity, so we'll exclude them from the memoization key to
    // prevent `afterExecute` from being triggered un-necessarily.
    const newOptionsForCompare = {
        ...options,
        onError: undefined,
        onCompleted: undefined,
    };

    const previousOptionsForCompareRef = useRef<typeof options | undefined>(
        undefined
    );

    if (!equal(previousOptionsForCompareRef.current, newOptionsForCompare)) {
        previousOptionsForCompareRef.current = newOptionsForCompare;
    }

    const stateRef = useRef<{
        data: undefined | TData;
        error: undefined | Error;
        loading: boolean;
    }>({
        data: undefined,
        error: undefined,
        loading: true,
    });

    useEffectWithoutMount(() => {
        stateRef.current = {
            data: undefined,
            error: undefined,
            loading: true,
        };
    }, [apolloClient]);

    const subscribe = useCallback(
        (callback: () => void) => {
            if (options.skip) {
                stateRef.current = {
                    ...stateRef.current,
                    loading: false,
                };
                return () => {
                    // skip loading
                };
            }
            stateRef.current = {
                ...stateRef.current,
                loading: true,
            };
            const watchedQuery = apolloClient.watchQuery<TData, TVariables>({
                ...options,
                query,
            });
            if (!isCached || watchedQuery.getCurrentResult().data) {
                stateRef.current = {
                    ...stateRef.current,
                    data: watchedQuery.getCurrentResult().data,
                };
            }

            const sub = watchedQuery.subscribe({
                next: value => {
                    if (!value.partial) {
                        stateRef.current = {
                            data: value.data,
                            error: undefined,
                            loading: value.loading,
                        };
                    }
                    callback();
                },
                error: error => {
                    stateRef.current = {
                        ...stateRef.current,
                        error,
                        loading: false,
                    };
                    callback();
                },
                complete: () => {
                    stateRef.current = {
                        ...stateRef.current,
                        loading: false,
                    };
                    callback();
                },
            });
            return () => {
                sub.unsubscribe();
            };
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [previousOptionsForCompareRef.current, apolloClient]
    );

    const state = useSyncExternalStore(subscribe, () => stateRef.current);
    return {
        data: state.data,
        error: state.error,
        loading: state.loading,
    };
};

export default useCancelableQuery;
