import { clsx } from 'clsx';
import type { ReactElement, ReactNode } from 'react';
import { cloneElement, memo } from 'react';
import {
    FloatingFocusManager,
    FloatingOverlay,
    FloatingPortal,
    useClick,
    useDismiss,
    useFloating,
    useInteractions,
    useRole,
    useTransitionStatus,
} from '@floating-ui/react';
import { CloseIcon } from '@library';
import { Button } from '../buttons';
import Container from '../../../core/components/Container';
import { HStack } from '../layout';
import { Heading } from '../typography';
import FadeScroll from '../../../core/components/layout/FadeScroll/FadeScroll';
import useEffectWithoutMount from '../../../core/util/useEffectWithoutMount';
import styles from './SidePane.module.scss';
import useNoScrollingWindow from './hooks/useNoScrollingWindow';

export interface SidePaneProps {
    /**
     *
     */
    side?: 'left' | 'right' | 'bottom';
    children?: ReactNode;
    /**
     *
     */
    header?: ReactNode;
    /**
     * Control show state outside the component
     */
    open?: boolean;
    /**
     * Open state changed
     */
    onOpenChange?: (open: boolean) => void;
    /**
     * Trigger
     */
    trigger?: ReactElement;
    /**
     * The footer of side pane. Use for actions
     */
    footer?: ReactNode;
    /**
     * Hide the close button for implementing always visible bottom side pane panel.
     */
    hiddenCloseButton?: boolean;
    /**
     * The auto height. For bottom side only
     */
    autoHeight?: boolean;
    /**
     * Make pane cover full screen with scrollable content.
     */
    fullScreen?: boolean;
    /**
     * Hide overlay. For bottom side only
     */
    hiddenOverlay?: boolean;
    /**
     * Apply overflow: hidden on container
     */
    hideOverflow?: boolean;
    /**
     * Triggers when animation is finished and side pane is unmounted.
     */
    onUnmounted?: () => void;
}

const SidePaneContainer = memo(
    ({
        side,
        children,
    }: Pick<SidePaneProps, 'side'> & { children: ReactNode }) => {
        if (side === 'bottom') {
            return <Container fullHeight>{children}</Container>;
        }
        return <div className={styles.container}>{children}</div>;
    }
);

const SidePaneCloseButton = memo(
    ({ onOpenChange }: Pick<SidePaneProps, 'onOpenChange'>) => {
        return (
            <div className={styles.close}>
                <Button
                    secondary
                    text
                    onClick={() => {
                        onOpenChange?.(false);
                    }}
                    theme={styles.buttonTheme}
                    data-test-class="close"
                >
                    <CloseIcon bold />
                </Button>
            </div>
        );
    }
);

const SidePaneHeader = memo(
    ({
        header,
        side,
        onOpenChange,
        hiddenCloseButton,
    }: Pick<
        SidePaneProps,
        'header' | 'side' | 'onOpenChange' | 'hiddenCloseButton'
    >) => {
        if (hiddenCloseButton && !header) {
            return null;
        }

        if (!header && !hiddenCloseButton) {
            return <SidePaneCloseButton onOpenChange={onOpenChange} />;
        }

        return (
            <div
                className={clsx(styles.header, {
                    [styles.headerWithContent]: !!header,
                })}
            >
                <SidePaneContainer side={side}>
                    <HStack
                        justify={header ? 'spaceBetween' : 'end'}
                        gap="small"
                        align="center"
                    >
                        {header && (
                            <div className={styles.headerContent}>
                                <Heading h3>{header}</Heading>
                            </div>
                        )}

                        {!hiddenCloseButton && (
                            <SidePaneCloseButton onOpenChange={onOpenChange} />
                        )}
                    </HStack>
                </SidePaneContainer>
            </div>
        );
    }
);

const SidePane = ({
    side = 'left',
    open,
    children,
    onOpenChange,
    onUnmounted,
    header,
    trigger,
    footer,
    hiddenCloseButton,
    autoHeight,
    hiddenOverlay,
    hideOverflow,
    fullScreen,
}: SidePaneProps) => {
    const { context, refs } = useFloating({
        open,
        onOpenChange,
    });

    const click = useClick(context);
    const dismiss = useDismiss(context, { enabled: !hiddenOverlay });

    const role = useRole(context, { role: 'dialog' });

    const { getFloatingProps, getReferenceProps } = useInteractions([
        click,
        dismiss,
        role,
    ]);

    const { isMounted, status } = useTransitionStatus(context, {
        duration: 300,
    });

    useEffectWithoutMount(() => {
        if (status === 'unmounted') {
            onUnmounted?.();
        }
    }, [status]);

    useNoScrollingWindow(isMounted);
    return (
        <>
            {trigger &&
                cloneElement(trigger, {
                    ...getReferenceProps(),
                    ref: refs.setReference,
                })}
            {isMounted && (
                <FloatingPortal>
                    <FloatingOverlay
                        className={clsx(styles.root, {
                            [styles.hiddenOverlay]: hiddenOverlay,
                        })}
                        style={{
                            position: hiddenOverlay ? undefined : 'fixed',
                            overflow: hideOverflow ? 'hidden' : undefined,
                        }}
                        data-status={status}
                    >
                        <FloatingFocusManager
                            context={context}
                            initialFocus={-1}
                        >
                            <div
                                {...getFloatingProps()}
                                className={clsx(styles.sidePane, styles[side], {
                                    [styles.autoHeight]: autoHeight,
                                    [styles.fullScreen]: fullScreen,
                                })}
                                ref={refs.setFloating}
                            >
                                <div className={styles.wrapperBody}>
                                    <SidePaneHeader
                                        header={header}
                                        side={side}
                                        onOpenChange={onOpenChange}
                                        hiddenCloseButton={hiddenCloseButton}
                                    />
                                    <FadeScroll className={styles.body}>
                                        <SidePaneContainer side={side}>
                                            {children}
                                        </SidePaneContainer>
                                    </FadeScroll>

                                    {footer && (
                                        <div className={styles.footer}>
                                            <SidePaneContainer side={side}>
                                                {footer}
                                            </SidePaneContainer>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </FloatingFocusManager>
                    </FloatingOverlay>
                </FloatingPortal>
            )}
        </>
    );
};

export default memo(SidePane);
