import { memo } from 'react';
import { clsx } from 'clsx';
import type Category from '../types/category';
import styles from './CategoryDropdown.module.scss';
import CurrentStoreLink from './router/CurrentStoreLink';

const LeafCategory = ({
    category,
    params = '',
    onClose,
}: {
    category: Category;
    params?: string;
    onClose: () => void;
}) => {
    let targetPath = `/categories/${category.id}/page/1`;
    if (params) {
        targetPath += `?${params}`;
    }

    return (
        <CurrentStoreLink
            onClick={onClose}
            className={clsx('category-link', styles.categoryItemLink)}
            to={targetPath}
        >
            <div className={styles.leafCategory}>{category.name}</div>
        </CurrentStoreLink>
    );
};

export default memo(LeafCategory);
