import type { HTMLAttributes } from 'react';
import { forwardRef, memo } from 'react';

const ClickableArea = forwardRef(
    (
        {
            onClick,
            tabIndex,
            children,
            ...props
        }: HTMLAttributes<HTMLDivElement> & {
            onClick: (e: {
                preventDefault: () => void;
                stopPropagation: () => void;
            }) => void;
        },
        ref: React.Ref<HTMLDivElement>
    ) => (
        <div
            role="button"
            tabIndex={tabIndex || 0}
            {...props}
            onClick={onClick}
            ref={ref}
            onKeyDown={event => {
                if (event.key === 'Enter' || event.key === ' ') {
                    onClick(event);
                }
                if (props.onKeyDown) {
                    props.onKeyDown(event);
                }
            }}
        >
            {children}
        </div>
    )
);

export default memo(ClickableArea);
