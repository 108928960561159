// Copied from https://github.com/sindresorhus/escape-string-regexp/blob/main/index.js
const escapeStringRegexp = (input: string): string => {
    if (typeof input !== 'string') {
        throw new TypeError('Expected a string');
    }

    // Escape characters with special meaning either inside or outside character sets.
    // Use a simple backslash escape when it’s always valid,
    // and a `\xnn` escape when the simpler form would be disallowed by Unicode patterns’ stricter grammar.
    return input.replace(/[|\\{}()[\]^$+*?.]/g, '\\$&').replace(/-/g, '\\x2d');
};

export default escapeStringRegexp;
