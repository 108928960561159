type KeyboardCallback = (event: KeyboardEvent) => void;

const onDocumentKeydownSubscribeHandlers: KeyboardCallback[] = [];

const onDocumentKeydownHandler = (event: KeyboardEvent) => {
    onDocumentKeydownSubscribeHandlers.forEach(fn => {
        fn(event);
    });
};

const onDocumentKeydown = (fn: (event: KeyboardEvent) => void) => {
    if (!onDocumentKeydownSubscribeHandlers.length) {
        document.body.addEventListener('keydown', onDocumentKeydownHandler, {
            capture: true,
        });
    }
    onDocumentKeydownSubscribeHandlers.push(fn);
    return {
        unsubscribe: () => {
            onDocumentKeydownSubscribeHandlers.splice(
                onDocumentKeydownSubscribeHandlers.indexOf(fn),
                1
            );
            if (!onDocumentKeydownSubscribeHandlers.length) {
                document.body.removeEventListener(
                    'keydown',
                    onDocumentKeydownHandler
                );
            }
        },
    };
};

export default onDocumentKeydown;
