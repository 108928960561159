import type { Language } from '../types/language';

const replaceLanguageInPath = ({
    pathname,
    language,
    isLockedStore,
    noLanguageInPath,
}: {
    pathname: string;
    language: Language;
    isLockedStore: boolean;
    noLanguageInPath?: boolean;
}) => {
    const components = pathname.split('/');
    if (components.length === 2 && components[1] === '') {
        return `/${language}`;
    }

    const shift = noLanguageInPath ? 1 : 0;

    if (isLockedStore) {
        return ['', language, ...components.slice(2 - shift)].join('/');
    }

    return ['', components[1], language, ...components.slice(3 - shift)].join(
        '/'
    );
};

export default replaceLanguageInPath;
