export interface SearchParams {
    name: string;
    value?: string | string[];
}

export type Search =
    | string[][]
    | Record<string, string>
    | string
    | URLSearchParams;

const updateSearchParams = (
    search: Search,
    params: SearchParams[]
): URLSearchParams => {
    const existingParams = new URLSearchParams(search);
    params.forEach(({ name, value }) => {
        if (existingParams.has(name)) {
            existingParams.delete(name);
        }

        if (!value) return;

        if (Array.isArray(value)) {
            value.forEach(singleValue =>
                existingParams.append(name, singleValue)
            );
            return;
        }

        existingParams.append(name, value);
    });

    return existingParams;
};

export const updateSearchParam = (
    search: Search,
    name: string,
    value?: string | string[]
): URLSearchParams =>
    updateSearchParams(search, [
        {
            name,
            value,
        },
    ]);

export default updateSearchParams;
