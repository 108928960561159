import { memo, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Heading, Text } from '@library';
import Button from './Button';
import Modal from './Modal';
import styles from './UpdateNotifier.module.css';

const mainScriptUrl = Array.from(document.getElementsByTagName('script')).find(
    item => item.src.includes('/index')
)?.src;

const TIMEOUT = 60_000;

const UpdateNotifier = () => {
    const [isUpToDate, setIsUpToDate] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [stop, setStop] = useState(false);
    useEffect(() => {
        if (stop || import.meta.env.MODE === 'development') {
            return;
        }
        if (!mainScriptUrl) {
            throw Error("UpdateNotifier: Main script url wasn't found.");
        }
        const updateCheckInterval = setInterval(() => {
            (async () => {
                const response = await fetch(mainScriptUrl, {
                    method: 'HEAD',
                    cache: 'reload',
                });
                if (response.status === 404) {
                    clearTimeout(updateCheckInterval);
                    setIsUpToDate(false);
                }
            })().catch(error => {
                throw error;
            });
        }, TIMEOUT);

        return () => {
            clearTimeout(updateCheckInterval);
        };
    }, [isUpToDate, stop]);

    const close = () => {
        setStop(true);
        setIsUpToDate(true);
    };

    if (isUpToDate || stop) {
        return null;
    }

    return (
        <Modal onClose={close} disableShortcuts>
            <div>
                <Heading h3>
                    <FormattedMessage id="New version available" />
                </Heading>
            </div>
            <div className={styles.message}>
                <Text primary>
                    <FormattedMessage id="Please refresh the page to get the latest version." />
                </Text>
            </div>
            <div className={styles.buttonBlock}>
                <Button secondary onPress={close}>
                    <FormattedMessage id="Later" />
                </Button>
                <Button
                    primary
                    loading={isLoading}
                    onPress={() => {
                        window.location.reload();
                        setIsLoading(true);
                    }}
                >
                    <FormattedMessage id="Refresh" />
                </Button>
            </div>
        </Modal>
    );
};

export default memo(UpdateNotifier);
