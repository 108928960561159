import { memo } from 'react';
import type { IconProps } from './types';

const SearchIcon = ({ iconLabel, color }: IconProps) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16.854"
            height="16.854"
            viewBox="0 0 16.854 16.854"
            data-icon={iconLabel}
            color={color}
        >
            <g transform="translate(-1093.246 -69.246)">
                <g
                    stroke="#707070"
                    fill="#fff"
                    transform="translate(1093.246 69.246)"
                >
                    <circle fill="none" cx="6.5" cy="6.5" r="6" />
                </g>
                <line
                    fill="none"
                    stroke="#707070"
                    x2="5.825"
                    y2="5.825"
                    transform="translate(1103.921 79.921)"
                />
            </g>
        </svg>
    );
};

export default memo(SearchIcon);
