/* eslint-disable @typescript-eslint/no-explicit-any */
import LazyComponent from '../../../core/components/router/LazyComponent';

const importComponents = <C extends Record<string, React.ComponentType<any>>>(
    factory: () => Promise<C>,
    components: (keyof C)[]
) => {
    return components.reduce(
        (prevValue, value) => {
            prevValue[value] = LazyComponent(async () => ({
                default: (await factory())[value],
            }));
            return prevValue;
        },
        {} as Record<
            keyof C,
            React.LazyExoticComponent<React.ComponentType<any>>
        >
    );
};

export default importComponents;
