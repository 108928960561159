import { gql, useMutation } from '@apollo/client';
import { getOperationName } from '@apollo/client/utilities';
import { useCallback, useContext } from 'react';
import { CartContext } from '../../core/cartContext';
import useStoreAndLanguageParams from '../../core/hooks/useStoreAndLanguageParams';
import notEmpty from '../../core/util/notEmpty';
import type { CartFields } from '../types';
import { cartFieldsQuery } from './useCartFields';
import { checkoutQuery } from './useCheckoutQuery';

const saveCartFieldsMutation = gql`
    mutation saveCartFields(
        $cartId: Int!
        $store: ID!
        $fields: CartFieldsInput!
    ) {
        saveCartFields(cartId: $cartId, store: $store, fields: $fields)
    }
`;

const useSaveCartFieldsMutation = (
    setUpdatesPending: (value: boolean) => void
) => {
    const { store } = useStoreAndLanguageParams();
    const {
        cart: { id },
    } = useContext(CartContext);

    const [mutation] = useMutation(saveCartFieldsMutation, {
        refetchQueries: [
            getOperationName(cartFieldsQuery),
            getOperationName(checkoutQuery),
        ].filter(notEmpty),
    });

    return useCallback(
        async (fields: Partial<CartFields>) => {
            if (!id) {
                throw new Error('Invalid cart id');
            }

            setUpdatesPending(true);
            try {
                return await mutation({
                    variables: {
                        store,
                        cartId: id,
                        fields,
                    },
                });
            } finally {
                setUpdatesPending(false);
            }
        },
        [mutation, store, id, setUpdatesPending]
    );
};

export default useSaveCartFieldsMutation;
