import { createContext } from 'react';

export interface AppContextValues {
    isLoggedIn: boolean;
    isImpersonating: boolean;
    currency: string;
    showCustomerPrice: boolean;
    customerPriceCacheKey?: string;
    isInTabletView: boolean;
    isInMobileView: boolean;
    setShowCustomerPrice(showCustomerPrice: boolean): void;
    logout: () => void;
    login: (accessToken: string, stayLoggedIn: boolean) => void;
    setRestrictedPage: (value: boolean) => void;
}

export const AppContext = createContext<AppContextValues>({
    isLoggedIn: false,
    isImpersonating: false,
    showCustomerPrice: true,
    currency: 'EUR',
    setShowCustomerPrice: (() => {}) as (showCustomerPrice: boolean) => void,
    isInTabletView: false,
    isInMobileView: false,
    logout: () => {},
    login: () => {},
    setRestrictedPage: () => {},
    /* istanbul ignore next -- @preserve */
});
