import { useContext } from 'react';
import PreorderModeContext from '../../preorder/PreorderModeContext';
import useCartRequiresPrepay from '../../cart/hooks/useCartRequiresPrepay';

const useCurrentStockOnlyFlag = () => {
    const requiresPrepay = useCartRequiresPrepay();
    const { isInPreorderMode } = useContext(PreorderModeContext);

    return requiresPrepay && !isInPreorderMode;
};

export default useCurrentStockOnlyFlag;
