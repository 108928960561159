import type { ForwardedRef } from 'react';
import { forwardRef, memo } from 'react';
import { useLocation } from 'react-router-dom';
import { clsx } from 'clsx';
import { useIntl } from 'react-intl';
import { HStack, Text, VStack } from '@library';
import CartIcon from '../../user/components/CartIcon';
import useLoginState from '../hooks/useLoginState';
import SearchField from '../../product/components/ProductSearch/SearchField';
import useSalePage from '../queries/useSalePage';
import useTabletView from '../hooks/dom/useTabletView';
import Container from './Container';
import HeaderCategories from './HeaderCategories';
import RestrictedContent from './router/RestrictedContent';
import CurrentStoreLink from './router/CurrentStoreLink';
import styles from './Header.module.scss';
import SidePaneCategoryMenu from './header/SidePaneCategoryMenu/SidePaneCategoryMenu';
import ComponentSuspense from './router/ComponentSuspense';
import LazyComponent from './router/LazyComponent';
import CompanyLogo from './CompanyLogo';
import withErrorBoundary from './errors/withErrorBoundary';

const UserMenuLoggedIn = LazyComponent(
    async () => import('../../user/components/UserMenu/UserMenuLoggedIn')
);

const CartIndicator = LazyComponent(
    async () => import('../../cart/components/CartIndicator/CartIndicator')
);

const UserMenuLoggedOut = LazyComponent(
    async () => import('../../user/components/UserMenu/UserMenuLoggedOut')
);

const HeaderItem = memo(
    ({
        linkName,
        highlighted = false,
        message,
        isSearchActive,
        ...ariaProps
    }: {
        highlighted?: boolean;
        linkName: string;
        message: string;
        isSearchActive: (searchTerm: string) => boolean;
    }) => {
        const intl = useIntl();
        return (
            <CurrentStoreLink
                data-test-class="header-link"
                {...ariaProps}
                className={clsx(styles.navCategoryLink, {
                    [styles.navCategoryLinkActive]: isSearchActive(linkName),
                    [styles.highlightedNavCategoryLinkActive]:
                        isSearchActive(linkName) && highlighted,
                })}
                to={`/${linkName}`}
                key="linkName"
            >
                <Text
                    primary
                    light={!isSearchActive(linkName)}
                    medium={isSearchActive(linkName)}
                >
                    <span
                        data-text={intl.formatMessage({
                            id: message,
                        })}
                        className={styles.navCategoryLinkText}
                    >
                        {intl.formatMessage({
                            id: message,
                        })}
                    </span>
                </Text>
            </CurrentStoreLink>
        );
    }
);

const TopNav = memo(() => {
    return (
        <nav
            style={{ order: 0 }}
            className={clsx(styles.navbar, styles.mainNav)}
        >
            <div className={clsx(styles.logo)}>
                <CompanyLogo />
            </div>
            <div className={clsx(styles.userPart)}>
                <ComponentSuspense height="32px" medium>
                    <RestrictedContent loggedInOnly>
                        <UserMenuLoggedIn />
                    </RestrictedContent>
                    <RestrictedContent loggedOutOnly>
                        <UserMenuLoggedOut />
                    </RestrictedContent>
                </ComponentSuspense>
            </div>
        </nav>
    );
});

const HeaderSalesPageItem = withErrorBoundary(
    memo(
        ({
            isSearchActive,
        }: {
            isSearchActive(searchTerm: string): boolean;
        }) => {
            const { data } = useSalePage();

            if (!data) {
                return null;
            }

            return (
                <HeaderItem
                    linkName="sale"
                    highlighted
                    message="sales page header"
                    isSearchActive={isSearchActive}
                />
            );
        }
    )
);

const Header = (_: unknown, ref: ForwardedRef<HTMLDivElement>) => {
    const isLoggedIn = useLoginState();
    const { pathname, search } = useLocation();
    const intl = useIntl();

    const searchParams = new URLSearchParams(search);
    searchParams.delete('q');

    const isRouteActive = (path: string) => pathname.includes(path);
    const brandPath = '/brands';

    const isSearchActive = (searchTerm: string) =>
        pathname.indexOf(`${searchTerm}`) !== -1;

    const isTabletView = useTabletView();

    const searchFieldAndCartIconGap = isTabletView ? 'small' : 'medium';

    return (
        <header className={styles.header} data-test-id="header" ref={ref}>
            <Container>
                <TopNav />

                <VStack>
                    <nav
                        className={clsx(styles.navbar, styles.searchNav, {
                            [styles.searchNavTablet]: isTabletView,
                        })}
                    >
                        <HStack align="center" justify="spaceBetween">
                            <div>
                                {isTabletView && <SidePaneCategoryMenu />}
                            </div>
                            <HStack
                                align="center"
                                gap={searchFieldAndCartIconGap}
                            >
                                <SearchField />
                                <RestrictedContent
                                    storeFlags={
                                        isLoggedIn
                                            ? undefined
                                            : 'cartSharingEnabled'
                                    }
                                >
                                    {isLoggedIn ? (
                                        <ComponentSuspense height="32px">
                                            <CartIndicator />
                                        </ComponentSuspense>
                                    ) : (
                                        <CartIcon />
                                    )}
                                </RestrictedContent>
                            </HStack>
                        </HStack>
                    </nav>
                    {!isTabletView && (
                        <nav className={clsx(styles.navbar, styles.pagesNav)}>
                            <HStack gap="medium">
                                <HeaderCategories />
                                <CurrentStoreLink
                                    data-test-class="header-link"
                                    className={clsx(styles.navCategoryLink, {
                                        [styles.navCategoryLinkActive]:
                                            isRouteActive(brandPath),
                                    })}
                                    to={brandPath}
                                >
                                    <Text
                                        primary
                                        light={!isRouteActive(brandPath)}
                                        medium={isRouteActive(brandPath)}
                                    >
                                        <span
                                            data-text={intl.formatMessage({
                                                id: 'brands',
                                            })}
                                            className={
                                                styles.navCategoryLinkText
                                            }
                                        >
                                            {intl.formatMessage({
                                                id: 'brands',
                                            })}
                                        </span>
                                    </Text>
                                </CurrentStoreLink>
                                <RestrictedContent
                                    storeFlags="sparePartSearchEnabled"
                                    loggedInOnly
                                >
                                    <HeaderItem
                                        linkName="spare-parts"
                                        message="spare parts search"
                                        isSearchActive={isSearchActive}
                                    />
                                </RestrictedContent>
                                <RestrictedContent stores={['ch']}>
                                    <HeaderItem
                                        linkName="motorcycle-search"
                                        message="motorcycle search"
                                        isSearchActive={isSearchActive}
                                    />
                                </RestrictedContent>
                                {isLoggedIn && (
                                    <HeaderSalesPageItem
                                        isSearchActive={isSearchActive}
                                    />
                                )}
                                <RestrictedContent
                                    storeFlags="dealerSearchEnabled"
                                    loggedOutOnly
                                >
                                    <HeaderItem
                                        data-test-class="dealerSearchLink"
                                        linkName="dealers"
                                        highlighted
                                        message="dealer locator"
                                        isSearchActive={isSearchActive}
                                    />
                                </RestrictedContent>
                            </HStack>
                        </nav>
                    )}
                </VStack>
            </Container>
        </header>
    );
};

export default memo(forwardRef(Header));
