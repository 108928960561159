import {
    memo,
    createContext,
    useContext,
    useMemo,
    useRef,
    useState,
} from 'react';
import type { FetchResult } from '@apollo/client';
import type { ReactNode } from 'react';
import assert from '../../../core/util/assert';
import useCartFields from '../../../checkout/queries/useCartFields';
import useSaveCartFieldsMutation from '../../../checkout/queries/useSaveCartFieldsMutation';
import type { CartFields } from '../../../checkout/types';
import type { ObservableVariable } from '../../../core/util/makeObservableVariable';
import makeObservableVariable from '../../../core/util/makeObservableVariable';

interface CartFieldsContextValue {
    cartFieldHasError: boolean;
    updatesPending: boolean;
    cartFieldsObservable?: ObservableVariable<CartFields | undefined>;
    setCartFieldsHasError(cartFieldsHasError: boolean): void;
    setUpdatesPending(areUpdatesPending: boolean): void;
    saveCartFields(fields: Partial<CartFields>): Promise<FetchResult>;
}

const CartFieldsContext = createContext<CartFieldsContextValue>({
    cartFieldHasError: false,
    updatesPending: false,
    cartFieldsObservable: undefined,
    setCartFieldsHasError: () => {
        // noop
    },
    setUpdatesPending: () => {
        // noop
    },
    saveCartFields: async () => {
        // noop
        return undefined as unknown as FetchResult;
    },
});

export const CartFieldsProvider = memo(
    ({ children }: { children: ReactNode }) => {
        const [updatesPending, setUpdatesPending] = useState(false);
        const [cartFieldHasError, setCartFieldsHasError] = useState(false);
        const cartFieldsQueryResult = useRef(
            makeObservableVariable<CartFields | undefined>(undefined)
        );

        useCartFields(cartFieldsData => {
            cartFieldsQueryResult.current.updateValue(
                cartFieldsData?.cartFields
            );
        });

        const saveCartFields = useSaveCartFieldsMutation(setUpdatesPending);
        const value = useMemo(
            () => ({
                updatesPending,
                setUpdatesPending,
                cartFieldHasError,
                setCartFieldsHasError,
                cartFieldsObservable: cartFieldsQueryResult.current,
                saveCartFields,
            }),
            [cartFieldHasError, saveCartFields, updatesPending]
        );

        return (
            <CartFieldsContext.Provider value={value}>
                {children}
            </CartFieldsContext.Provider>
        );
    }
);

export const useCartFieldsContext = (): CartFieldsContextValue & {
    cartFieldsObservable: ObservableVariable<CartFields | undefined>;
} => {
    const { cartFieldsObservable, ...contextValue } =
        useContext(CartFieldsContext);

    assert(
        cartFieldsObservable,
        'Please check that CartFieldsContext is present in the tree'
    );

    return { ...contextValue, cartFieldsObservable };
};
