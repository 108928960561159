import { memo } from 'react';
import type { IconProps } from './types';

const ChevronLeftIcon = ({
    iconLabel,
    color,
    emphasized = false,
    smallIcon,
}: IconProps & { emphasized?: boolean; smallIcon?: boolean }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={smallIcon ? '7.055' : '10.055'}
        height="17.735"
        viewBox={emphasized ? '0 0 6.055 9.933' : '0 0 10.055 17.736'}
        data-icon={iconLabel}
        color={color}
    >
        <path
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2px"
            d={
                emphasized
                    ? 'M199.026,246.351l-3.463,3.463,3.641,3.641'
                    : 'M202.829,246.351l-7.266,7.266,7.64,7.64'
            }
            transform="translate(-194.563 -244.937)"
        />
    </svg>
);

export default memo(ChevronLeftIcon);
