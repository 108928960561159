import { gql } from '@apollo/client';

const updateBulkCartItemQuantityMutation = gql`
    mutation updateCartItemQuantities(
        $cartId: Int!
        $items: [CartItemInput!]!
    ) {
        updateCartItemQuantities(cartId: $cartId, items: $items) {
            success
        }
    }
`;

export default updateBulkCartItemQuantityMutation;
