const onDocumentClickSubscribeHandlers: Map<
    string,
    {
        unsubscribe: () => void;
        listeners: Array<(event: MouseEvent) => void>;
    }
> = new Map();

const onDocumentClickHandler = (rootNodeId: string) => (event: MouseEvent) => {
    onDocumentClickSubscribeHandlers.get(rootNodeId)?.listeners.forEach(fn => {
        fn(event);
    });
};

// rootNode should only be passed in for components that use react portal
// or are children of the same. For everything else, default root node
// should be used. For more info on this, see
// https://reactjs.org/blog/2020/10/20/react-v17.html#changes-to-event-delegation
const onDocumentClick = (
    fn: (event: MouseEvent) => void,
    rootNode = document.getElementById('root')
) => {
    if (!rootNode) {
        return {
            unsubscribe: () => {},
        };
    }

    const rootNodeHandler = onDocumentClickSubscribeHandlers.get(rootNode.id);
    if (!rootNodeHandler) {
        const clickHandler = onDocumentClickHandler(rootNode.id);

        rootNode.addEventListener('click', clickHandler);

        onDocumentClickSubscribeHandlers.set(rootNode.id, {
            unsubscribe: () => {
                rootNode.removeEventListener('click', clickHandler);
            },
            listeners: [fn],
        });
    } else {
        onDocumentClickSubscribeHandlers.set(rootNode.id, {
            unsubscribe: rootNodeHandler.unsubscribe,
            listeners: [...rootNodeHandler.listeners, fn],
        });
    }

    return {
        unsubscribe: () => {
            const rootNodeHandlerUnsubscribe =
                onDocumentClickSubscribeHandlers.get(rootNode.id);
            if (rootNodeHandlerUnsubscribe) {
                const fns = rootNodeHandlerUnsubscribe.listeners.slice();
                fns.splice(fns.indexOf(fn), 1);

                onDocumentClickSubscribeHandlers.set(rootNode.id, {
                    ...rootNodeHandlerUnsubscribe,
                    listeners: fns,
                });

                if (!fns.length) {
                    rootNodeHandlerUnsubscribe.unsubscribe();
                    onDocumentClickSubscribeHandlers.delete(rootNode.id);
                }
            }
        },
    };
};

export default onDocumentClick;
