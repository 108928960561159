import type { LinkProps } from 'react-router-dom';
import isString from './isString';

const transformToCurrentStoreLink = (storeRootPath: string, link: string) => {
    const linkWithSlash = link[0] === '/' ? link : `/${link}`;
    return `${storeRootPath}${linkWithSlash}`;
};

const injectStoreToLocationDescription = (
    storeRootPath: string,
    to: LinkProps['to']
) => {
    if (isString(to)) {
        return transformToCurrentStoreLink(storeRootPath, to);
    }
    return {
        ...to,
        pathname:
            to.pathname &&
            transformToCurrentStoreLink(storeRootPath, to.pathname),
    };
};

export default injectStoreToLocationDescription;
