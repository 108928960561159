import type { ObservableVariable } from '../../core/util/makeObservableVariable';
import makeObservableVariable from '../../core/util/makeObservableVariable';

export interface Consent {
    necessary: true;
    performance: boolean;
    functional: boolean;
    advertising: boolean;
}

export type ConsentCategory =
    | 'necessary'
    | 'performance'
    | 'functional'
    | 'advertising';

declare global {
    interface Window {
        CookieFirst?: CookieFirstContext;
    }
}

export interface CookieFirstContext {
    consent: null | Consent;
    openPanel: () => void;
    closePanel: () => void;
    updateConsent: (newConsent: Consent) => Promise<void>;
    acceptCategory: (category: ConsentCategory) => Promise<void>;
    acceptAllCategories: () => Promise<void>;
    acceptPreselectedCategories: () => Promise<void>;
    declineAllCategories: () => Promise<void>;
    declineCategory: (category: ConsentCategory) => Promise<void>;
    withdrawConsent: () => Promise<void>;
}

class CookieConsentService {
    private cookieFirstContext: CookieFirstContext | null = null;

    public consentVariable: ObservableVariable<Consent | null>;

    public initializedVariable: ObservableVariable<boolean>;

    public acceptAllProgress: ObservableVariable<boolean>;

    public acceptProgress: ObservableVariable<boolean>;

    public declineAllProgress: ObservableVariable<boolean>;

    constructor() {
        this.acceptAllProgress = makeObservableVariable(false);
        this.acceptProgress = makeObservableVariable(false);
        this.declineAllProgress = makeObservableVariable(false);

        if (window.CookieFirst) {
            this.cookieFirstContext = window.CookieFirst;
            this.consentVariable = makeObservableVariable<Consent | null>(
                window.CookieFirst.consent
            );
            this.initializedVariable = makeObservableVariable<boolean>(true);
        } else {
            this.consentVariable = makeObservableVariable<Consent | null>(null);
            this.initializedVariable = makeObservableVariable<boolean>(false);
            const listener = () => {
                if (window.CookieFirst) {
                    this.cookieFirstContext = window.CookieFirst;
                    window.removeEventListener('cf_init', listener);
                    this.consentVariable.updateValue(
                        this.cookieFirstContext.consent
                    );
                    this.initializedVariable.updateValue(true);
                }
            };
            window.addEventListener('cf_init', listener);
        }

        window.addEventListener('cf_consent', event => {
            const consent = (event as unknown as { detail: Consent | null })
                .detail;
            this.consentVariable.updateValue(consent);
        });
    }

    acceptAll = async () => {
        this.acceptAllProgress.updateValue(true);
        try {
            return await this.cookieFirstContext?.acceptAllCategories();
        } finally {
            this.acceptAllProgress.updateValue(false);
        }
    };

    accept = async (consent: Consent) => {
        this.acceptProgress.updateValue(true);
        try {
            return await this.cookieFirstContext?.updateConsent(consent);
        } finally {
            this.acceptProgress.updateValue(false);
        }
    };

    declineAll = async () => {
        this.declineAllProgress.updateValue(true);
        try {
            return await this.cookieFirstContext?.declineAllCategories();
        } finally {
            this.declineAllProgress.updateValue(false);
        }
    };
}

export default CookieConsentService;
