import type { ComponentProps } from 'react';
import { memo } from 'react';
import Stack from './Stack';

const HStack = ({
    children,
    reversed,
    ...props
}: Omit<ComponentProps<typeof Stack>, 'direction'> & {
    /**
     * Reverse the order of children of this element.
     * @see {@link https://css-tricks.com/snippets/css/a-guide-to-flexbox/#aa-flex-direction}
     */
    reversed?: boolean;
}) => {
    return (
        <Stack direction={reversed ? 'rowReverse' : 'row'} {...props}>
            {children}
        </Stack>
    );
};

export default memo(HStack);
