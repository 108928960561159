import type { LegacyRef } from 'react';
import { forwardRef, memo } from 'react';
import type { BaseButtonProps } from './BaseButton';
import BaseButton from './BaseButton';
export interface ButtonProps extends Omit<BaseButtonProps, 'component'> {
    /**
     * The button type
     */
    type?: 'submit' | 'reset' | 'button';
}

const Button = forwardRef(
    (
        { children, type = 'button', ...props }: ButtonProps,
        ref: LegacyRef<HTMLButtonElement>
    ) => {
        return (
            <BaseButton {...props} component={<button type={type} ref={ref} />}>
                {children}
            </BaseButton>
        );
    }
);

export default memo(Button);
