import { useEffect } from 'react';
import useImmutableCallback from '../useImmutableCallback';

function useEventListener<DocumentEMap extends keyof DocumentEventMap>(params: {
    element: Document | null | undefined;
    type: DocumentEMap;
    listener: (evt: DocumentEventMap[DocumentEMap]) => void;
    options?: boolean | AddEventListenerOptions;
}): void;

function useEventListener<HtmlEMap extends keyof HTMLElementEventMap>(params: {
    element: HTMLElement | null | undefined;
    type: HtmlEMap;
    listener: (evt: HTMLElementEventMap[HtmlEMap]) => void;
    options?: boolean | AddEventListenerOptions;
}): void;

function useEventListener<WindowEMap extends keyof WindowEventMap>(params: {
    element: Window | null | undefined;
    type: WindowEMap;
    listener: (evt: WindowEventMap[WindowEMap]) => void;
    options?: boolean | AddEventListenerOptions;
}): void;

function useEventListener(params: {
    element: Document | HTMLElement | Window | null | undefined;
    type: string;
    listener: (evt: Event) => void;
    options?: boolean | AddEventListenerOptions;
}): void;

function useEventListener<
    DocumentEMap extends keyof DocumentEventMap,
    HtmlEMap extends keyof HTMLElementEventMap,
    WindowEMap extends keyof WindowEventMap,
>({
    element,
    type,
    listener,
    options,
}: {
    element: Document | HTMLElement | Window | null | undefined;
    type: DocumentEMap | HtmlEMap | WindowEMap | string;
    listener: (
        this: typeof element,
        evt:
            | DocumentEventMap[DocumentEMap]
            | HTMLElementEventMap[HtmlEMap]
            | WindowEventMap[WindowEMap]
            | Event
    ) => void;
    options?: boolean | AddEventListenerOptions;
}) {
    const immutableCallback = useImmutableCallback(listener);

    useEffect(() => {
        if (!element) return undefined;
        element.addEventListener(type, immutableCallback, options);
        return () => {
            element.removeEventListener(type, immutableCallback, options);
        };
    }, [element, options, type, immutableCallback]);
}

export default useEventListener;
