import SpinnerPage from '../../components/pages/SpinnerPage';
import type { Store } from '../../types/stores';

const useCheckStoreDomainRedirect = (stores?: Store[]) => {
    const currentUrl = new URL(window.location.href);
    if (!stores) {
        return null;
    }
    const storesData = stores.map(store => ({
        ...store,
        url: store.url
            .replace('%BRANCH%', APP_ENV.DEV_BRANCH ?? '')
            .replace(
                'https://dev.hostettler.de/%BRANCH%/',
                'http://localhost:3000/'
            ),
    }));

    if (!storesData.find(({ url }) => currentUrl.href.startsWith(url))) {
        const redirectToStore = storesData.find(({ url }) => {
            const { hostname } = new URL(url);
            return hostname === currentUrl.hostname;
        });

        if (redirectToStore) {
            window.location.replace(
                `${redirectToStore.url}/${redirectToStore.languages[0].language}`
            );
            return <SpinnerPage />;
        }
    }
};

export default useCheckStoreDomainRedirect;
