import { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import type { ReactNode } from 'react';
import { Text, TextBlock } from '@library';
import styles from './FormField.module.scss';

const FormField = ({
    children,
    label,
    hint,
    error,
    id,
}: {
    children: ReactNode;
    label?: ReactNode;
    hint?: ReactNode;
    error?: string;
    id?: string;
}) => {
    return (
        <div className={styles.formField}>
            {label && (
                <label htmlFor={id} className={styles.label}>
                    <TextBlock primary>{label}</TextBlock>
                </label>
            )}
            {children}
            {hint && (
                <div className={styles.additionalInfo}>
                    <TextBlock tertiary>{hint}</TextBlock>
                </div>
            )}
            {error && (
                <div
                    className={styles.error}
                    data-test-class="formFieldError"
                    data-test-state={id}
                >
                    <Text caption medium>
                        <FormattedMessage id={error} />
                    </Text>
                </div>
            )}
        </div>
    );
};

export default memo(FormField);
