import { memo as ReactMemo } from 'react';
import type ArrayToUnion from './types/ArrayToUnion';
export enum DownloadStatus {
    pending = 'pending',
    ready = 'ready',
    failed = 'failed',
}

export const languages = ['en', 'de', 'fr', 'it'] as const;

export type Language = ArrayToUnion<typeof languages>;

export const isLanguage = (value: unknown): value is Language =>
    languages.includes(value as Language);

export interface LocalizedString {
    de: string;
    en: string;
    fr: string;
    it: string;
}

export const memo: <T>(component: T) => T = ReactMemo;
