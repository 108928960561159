import LazyComponent from '../core/components/router/LazyComponent';

const OnboardingForm = LazyComponent(
    async () => import('./components/OnboardingForm')
);

const externalFormsRoutes = [
    {
        path: 'onboarding',
        element: <OnboardingForm />,
    },
];

export default externalFormsRoutes;
