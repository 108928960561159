import { memo, useMemo } from 'react';
import type { ReactNode } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useCheckStoreDomainRedirect from '../hooks/router/useCheckStoreDomainRedirect';
import useCheckUserStoreRedirect from '../hooks/router/useCheckUserStoreRedirect';
import useStoreLanguageRedirect from '../hooks/router/useStoreLanguageRedirect';
import useCheckUserPreferredLanguageRedirect from '../hooks/router/useCheckUserPreferredLanguageRedirect';
import useCurrentUser from '../hooks/useCurrentUser';
import type { Store, StoreId } from '../types/stores';
import useCurrentStoresQuery from '../queries/useCurrentStoresQuery';
import SpinnerPage from '../components/pages/SpinnerPage';
import assert from '../util/assert';
import type { Language } from '../types';
import useStoreInformation from '../../content/queries/useStoreInformation';
import { fetchMessagesForLanguage } from '../components/providers/IntlStoreProvider';
import { StoreContext } from './StoreContext';

const useStoreLanguageRedirects = (
    storeId: StoreId,
    language?: Language,
    stores?: Store[]
) => {
    const currentUser = useCurrentUser();
    const navigate = useNavigate();

    const redirectToCorrectDomain = useCheckStoreDomainRedirect(stores);
    const redirectToAvailableStore = useCheckUserStoreRedirect(
        storeId,
        stores,
        currentUser
    );

    const redirectToPreferredLanguage = useCheckUserPreferredLanguageRedirect(
        language,
        currentUser,
        stores
    );

    const redirectToAvailableLanguage = useStoreLanguageRedirect({
        storeId,
        language,
        stores,
    });

    if (redirectToCorrectDomain) {
        return redirectToCorrectDomain;
    }

    if (redirectToAvailableStore) {
        return redirectToAvailableStore;
    }

    if (redirectToPreferredLanguage) {
        navigate(redirectToPreferredLanguage);
    }

    if (redirectToAvailableLanguage) {
        return redirectToAvailableLanguage;
    }
};

export const useActiveStoreAndLanguage = () => {
    const route = useParams<{
        language: Language;
        store: StoreId;
    }>();

    const store = (APP_ENV.STORE || route.store) as StoreId | undefined;

    const language: Language =
        (sessionStorage.getItem('language') as Language | undefined) ||
        route.language ||
        'de';

    const urlLanguage: Language | undefined = route.language;

    if (!store) {
        throw new Error('Wrong URL');
    }

    return { store, language, urlLanguage };
};

const StoreProvider = ({ children }: { children: ReactNode }) => {
    const { store, language, urlLanguage = 'de' } = useActiveStoreAndLanguage();
    const { data, loading, error } = useCurrentStoresQuery();
    const storeEntity = data?.stores.find(item => item.id === store);

    useStoreInformation();
    fetchMessagesForLanguage(
        ['fr', 'de', 'en', 'it'].includes(urlLanguage) ? urlLanguage : 'de'
    ).catch(exception => {
        throw exception;
    });

    const redirect = useStoreLanguageRedirects(
        store,
        urlLanguage,
        data?.stores
    );

    const value = useMemo(() => {
        return { store: storeEntity, language, urlLanguage: urlLanguage };
    }, [language, storeEntity, urlLanguage]);

    if (error) {
        throw error;
    }
    if (redirect) {
        return redirect;
    }

    if (!data?.stores || (loading && !data.stores.length)) {
        return <SpinnerPage />;
    }

    assert(storeEntity, 'Store is undefined. Please check redirects');

    return (
        <StoreContext.Provider value={value}>{children}</StoreContext.Provider>
    );
};

export default memo(StoreProvider);
