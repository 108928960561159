import type { HTMLAttributes } from 'react';
import { forwardRef, memo } from 'react';
import type { ClickableAriaOptions } from './hooks/getClickableAriaOptions';
import getClickableAriaOptions from './hooks/getClickableAriaOptions';

const ClickableArea = forwardRef(
    (
        {
            onPress,
            tabIndex,
            children,
            ...props
        }: HTMLAttributes<HTMLDivElement> & ClickableAriaOptions,
        ref: React.Ref<HTMLDivElement>
    ) => {
        const baseProps = getClickableAriaOptions({
            onPress,
            tabIndex,
            ...props,
        });
        return (
            <div {...baseProps} {...props} ref={ref}>
                {children}
            </div>
        );
    }
);

export default memo(ClickableArea);
