import { useLocation } from 'react-router-dom';
import RedirectToStore from '../../components/router/RedirectToStore';
import type { Store, StoreId } from '../../types/stores';
import type { User } from '../../../user/types';

const useCheckUserStoreRedirect = (
    store: StoreId,
    stores?: Store[],
    currentUser?: User
) => {
    const { search } = useLocation();

    const params = new URLSearchParams(search);
    const redirectPath = params.get('redirectTo') || '';

    if (!stores) {
        return null;
    }

    if (currentUser) {
        if (!currentUser.stores.length) {
            return null;
        }

        if (!currentUser.stores.map(item => item.id).includes(store)) {
            const [userStore] = currentUser.stores;

            const language =
                userStore.languages.find(
                    item => item.language === currentUser.language
                ) || userStore.languages[0];

            const props = {
                store: userStore,
                language: language.language,
                redirectPath,
                search,
                checkAuthState: true,
            };

            return <RedirectToStore {...props} />;
        }

        return null;
    }

    if (!stores.map(item => item.id).includes(store)) {
        const [dataStore] = stores;
        const props = {
            store: dataStore,
            language: dataStore.languages[0].language,
            redirectPath,
            search,
        };

        return <RedirectToStore {...props} />;
    }
};

export default useCheckUserStoreRedirect;
