import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import {
    getAccessToken,
    getPermissionToken,
} from '../util/auth/credentialsStorage';
import useEffectWithoutMount from '../util/useEffectWithoutMount';
import { removeYhEPFormFromStorage } from '../../cart/util/YhEPCartStorage';
import initSentry from '../../main/initSentry';
import UserContext from '../components/UserContext';
import useCustomNavigate from './router/useCustomNavigate';
import useVisibilityStatus from './dom/useVisibilityStatus';

const useSyncLoginStateBetweenTabs = ({
    authToken,
    login,
    logout,
    isLoggedIn,
}: {
    authToken: string | undefined;
    login: (
        accessToken: string,
        stayLoggedIn: boolean,
        skipStorageChanges?: boolean
    ) => void;
    logout: () => void;
    isLoggedIn: boolean;
}) => {
    useEffectWithoutMount(() => {
        setIsNeedCheckAuthState(false);
    }, [isLoggedIn]);

    // check auth state only if user leaves page
    const [isNeedCheckAuthState, setIsNeedCheckAuthState] = useState(false);
    const isVisible = useVisibilityStatus(() => {
        setIsNeedCheckAuthState(true);
    });

    // check that token is changed. Ignore if we have permission token.
    useEffect(() => {
        const token = getAccessToken();
        const permissionToken = getPermissionToken();
        if (permissionToken) {
            return;
        }
        if (isVisible && isNeedCheckAuthState && token !== authToken) {
            if (token) {
                login(token, true, true);
            } else {
                logout();
            }
        }
    }, [
        login,
        logout,
        isVisible,
        isLoggedIn,
        setIsNeedCheckAuthState,
        isNeedCheckAuthState,
        authToken,
    ]);
    return setIsNeedCheckAuthState;
};

const useAuth = () => {
    const navigate = useCustomNavigate();
    const {
        user,
        accessToken: authToken,
        setAccessToken,
    } = useContext(UserContext);
    const [isRestrictedPage, setIsRestrictedPage] = useState(false);
    const isSentryInitialized = useRef(false);
    const isLoggedIn = !!user;

    const login = useCallback(
        (
            accessToken: string,
            stayLoggedIn: boolean,
            skipStorageChanges?: boolean
        ) => {
            isSentryInitialized.current = true;
            setAccessToken(accessToken, stayLoggedIn, skipStorageChanges);
        },
        [setAccessToken]
    );

    const logout = useCallback(() => {
        setAccessToken(undefined);
        if (isSentryInitialized.current) {
            (async () => {
                const sentry = await initSentry();
                /* istanbul ignore next -- @preserve */
                if (sentry) {
                    sentry.setUser(null);
                }
            })().catch(e => {
                /* istanbul ignore next -- @preserve */
                // Not important enough to throw an exception
                // eslint-disable-next-line no-console
                console.error(e);
            });
        }

        localStorage.removeItem('currentUserData');
        removeYhEPFormFromStorage();
    }, [setAccessToken]);

    useEffectWithoutMount(() => {
        if (!isLoggedIn && isRestrictedPage) {
            navigate('/');
        }
    }, [isLoggedIn, navigate, isRestrictedPage]);

    useSyncLoginStateBetweenTabs({
        authToken,
        login,
        logout,
        isLoggedIn,
    });

    return {
        login,
        logout,
        isLoggedIn,
        setIsRestrictedPage,
        localStorage,
        isSentryInitialized,
    };
};

export default useAuth;
