import type { ApolloClient } from '@apollo/client';
import { gql } from '@apollo/client';
import {
    getAccessToken,
    getAccessTokenPersistence,
    getLastUpdate,
} from './credentialsStorage';

export const TOKEN_UPDATE_INTERVAL = 60 * 60 * 1000;
const refreshTokenCheck = (
    client: ApolloClient<unknown>,
    setAccessToken: (token?: string, persisted?: boolean) => void
) => {
    const accessToken = getAccessToken();
    const lastUpdate = getLastUpdate();
    const persist = getAccessTokenPersistence();

    if (Date.now() - lastUpdate >= TOKEN_UPDATE_INTERVAL && accessToken) {
        client
            .mutate<{
                refreshToken: {
                    accessToken: string;
                };
            }>({
                mutation: gql`
                    mutation refreshToken {
                        refreshToken {
                            accessToken
                        }
                    }
                `,
            })
            .then(({ data }) => {
                if (data?.refreshToken) {
                    setAccessToken(data.refreshToken.accessToken, persist);
                } else {
                    setAccessToken(undefined);
                }
            })
            .catch(() => {
                setAccessToken(undefined);
            });
    }
};

const startRefreshTokenCheck = (
    client: ApolloClient<unknown>,
    setAccessToken: (token?: string, persisted?: boolean) => void
) => {
    refreshTokenCheck(client, setAccessToken);
    return setInterval(() => {
        refreshTokenCheck(client, setAccessToken);
    }, TOKEN_UPDATE_INTERVAL);
};

export default startRefreshTokenCheck;
