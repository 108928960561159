import { memo } from 'react';
import type { ReactNode } from 'react';
import { Heading, TextBlock } from '@library';
import styles from '../Button.module.scss';

const ButtonTypography = ({
    tertiary = false,
    textOnly = false,
    iconButton = false,
    uppercase = false,
    size,
    children,
    lineHeightAsFontSize,
}: {
    tertiary?: boolean;
    textOnly?: boolean;
    iconButton?: boolean;
    uppercase?: boolean;
    children: ReactNode;
    size?: 'small' | 'medium';
    lineHeightAsFontSize?: boolean;
}) => {
    if (size === 'small') {
        return (
            <TextBlock
                tertiary
                medium
                uppercase={uppercase}
                lineHeightAsFontSize={
                    lineHeightAsFontSize !== undefined
                        ? lineHeightAsFontSize
                        : true
                }
            >
                {children}
            </TextBlock>
        );
    }
    if (tertiary) {
        return <div className={styles.buttonTypography}>{children}</div>;
    }

    if (textOnly || iconButton) {
        return (
            <div className={styles.buttonTextOnly}>
                <TextBlock
                    tertiary
                    uppercase
                    center
                    lineHeightAsFontSize={lineHeightAsFontSize}
                >
                    {children}
                </TextBlock>
            </div>
        );
    }

    return (
        <div className={styles.buttonText}>
            <Heading
                h6
                medium
                uppercase
                center
                lineHeightAsFontSize={lineHeightAsFontSize}
            >
                {children}
            </Heading>
        </div>
    );
};
export default memo(ButtonTypography);
