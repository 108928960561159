import { clsx } from 'clsx';
import { memo } from 'react';
import useStoreAndLanguageParams from '../hooks/useStoreAndLanguageParams';
import CurrentStoreLink from './router/CurrentStoreLink';
import styles from './Header.module.scss';

const CompanyLogo = ({
    dark = false,
    className,
}: {
    dark?: boolean;
    className?: string;
}) => {
    const { store } = useStoreAndLanguageParams();

    return (
        <CurrentStoreLink
            to="/"
            className={clsx(styles.logoLink, className)}
            data-test-class="home-page-link"
        >
            <img
                className={styles.logoImage}
                src={`https://s3.eu-central-1.amazonaws.com/files.hostettler.com/store/${store}/logo/logo${
                    dark ? '-dark' : ''
                }.svg`}
                alt="store logo"
            />
        </CurrentStoreLink>
    );
};

export default memo(CompanyLogo);
