import { useContext, useMemo } from 'react';
import { CartContext } from '../../core/cartContext';
import useObservable from '../../core/hooks/useObservable';
import RemoteCart from '../services/RemoteCart';
import makeObservableVariable from '../../core/util/makeObservableVariable';

const useCartRequiresPrepay = () => {
    const { cart } = useContext(CartContext);

    const observable = useMemo(() => {
        if (cart instanceof RemoteCart) {
            return cart.cartRequiresPrepay;
        } else {
            return makeObservableVariable(false).onValueChange;
        }
    }, [cart]);

    return useObservable(observable) ?? false;
};

export default useCartRequiresPrepay;
