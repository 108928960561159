import type {
    ApolloClient,
    NormalizedCacheObject,
    OperationVariables,
} from '@apollo/client';
import type { WatchQueryOptions } from '@apollo/client/core/watchQueryOptions';
import type { ObservableQuery } from '@apollo/client/core/ObservableQuery';

const watchPolledQuery = <
    T,
    TVariables extends OperationVariables = OperationVariables,
>(
    client: ApolloClient<NormalizedCacheObject>,
    options: WatchQueryOptions<TVariables, T>
): {
    observable: ObservableQuery<T, TVariables>;
    stopPolling(): void;
    startPolling(pollInterval: number): void;
} => {
    const observable = client.watchQuery<T, TVariables>(options);

    let interval = options.pollInterval;
    let isCancelled = false;

    document.addEventListener('visibilitychange', () => {
        if (interval && !isCancelled) {
            if (document.visibilityState === 'hidden') {
                observable.stopPolling();
            } else {
                observable.startPolling(interval);
            }
        }
    });

    const startPollingFn = (pollInterval: number) => {
        interval = pollInterval;
        isCancelled = false;

        observable.startPolling(interval);
    };

    const stopPollingFn = () => {
        isCancelled = true;

        observable.stopPolling();
    };

    return {
        observable,
        startPolling: startPollingFn,
        stopPolling: stopPollingFn,
    };
};

export default watchPolledQuery;
