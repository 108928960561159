import type { ToastOptions } from 'react-hot-toast';
import { toast } from 'react-hot-toast';
import type { ToastProps } from './Toast';
import Toast from './Toast';

export const notify = ({
    icon,
    title,
    message,
    type,
    onClose,
    ...others
}: Pick<ToastProps, 'icon' | 'message' | 'title' | 'type'> &
    ToastOptions & {
        /**
         * Callback for on close event
         */
        onClose?: () => void;
    }) => {
    return toast(t => {
        return (
            <Toast
                {...{ icon, title, message, type }}
                onClose={() => {
                    toast.dismiss(t.id);
                    onClose?.();
                }}
            />
        );
    }, others);
};
