import { useEffect } from 'react';
import useImmutableCallback from '../../core/hooks/useImmutableCallback';
import type { Cart } from '../types';

const useCartUpdated = (callback: () => void, cart: Cart) => {
    const immutableCallback = useImmutableCallback(callback);

    useEffect(() => {
        const subscription = cart.cartUpdated.subscribe(immutableCallback);
        return () => {
            subscription.unsubscribe();
        };
    }, [cart.cartUpdated, immutableCallback]);
};

export default useCartUpdated;
