import type { ReactNode } from 'react';
import { memo } from 'react';
import useMatchMedia from '../hooks/useMatchMedia';
import LayoutContext from './LayoutContext';

const LayoutProvider = ({ children }: { children: ReactNode }) => {
    const isInTabletView = useMatchMedia(
        '(min-width: 768px and max-width:1199px)'
    );
    const isInMobileView = useMatchMedia('(max-width: 767px)');

    return (
        <LayoutContext.Provider value={{ isInMobileView, isInTabletView }}>
            {children}
        </LayoutContext.Provider>
    );
};

export default memo(LayoutProvider);
