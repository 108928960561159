import { memo, type ReactNode } from 'react';
import { Helmet } from 'react-helmet';
import { clsx } from 'clsx';
import { VStack, HStack } from '@library';
import useCurrentStore from '../../hooks/useCurrentStore';
import LanguageSelector from '../../../user/components/LanguageSelector';
import CompanyLogo from '../CompanyLogo';
import Container from '../Container';
import styles from './HeadlessPage.module.scss';

const HeadlessPage = memo(
    ({
        title,
        children,
        footer,
        stickyFooter,
    }: {
        title?: string;
        children: ReactNode;
        footer: ReactNode;
        stickyFooter?: boolean;
    }) => {
        const { companyName: storeTitle } = useCurrentStore();
        return (
            <div className={styles.container}>
                {title && (
                    <Helmet>
                        <title>
                            {title} - {storeTitle}
                        </title>
                    </Helmet>
                )}
                <VStack gap="small" expanded justify="spaceBetween">
                    <div>
                        <Container>
                            <VStack gap="small">
                                <HStack
                                    align="center"
                                    gap="small"
                                    justify="spaceBetween"
                                >
                                    <div className={styles.logo}>
                                        <CompanyLogo />
                                    </div>
                                    <LanguageSelector />
                                </HStack>
                                <div />

                                {children}
                            </VStack>
                        </Container>
                    </div>

                    {footer && (
                        <div
                            className={clsx(styles.footer, {
                                [styles.stickyFooter]: stickyFooter,
                            })}
                        >
                            {footer}
                        </div>
                    )}
                </VStack>
            </div>
        );
    }
);

export default HeadlessPage;
