import { memo } from 'react';

const EmailIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
    >
        <path
            d="M3.99995 8.99998L1.72412 1.60498C7.16813 3.18804 12.3019 5.68898 16.9041 8.99998C12.3022 12.3115 7.16877 14.813 1.72495 16.3966L3.99995 8.99998ZM3.99995 8.99998H10.25"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default memo(EmailIcon);
