import type {
    ComponentType,
    PropsWithChildren,
    ReactElement,
    ReactNode,
} from 'react';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import type { ErrorComponentProps } from '../ErrorBoundary';
import ErrorBoundary from '../ErrorBoundary';

export const FailedToRenderMessage = memo(() => {
    return <FormattedMessage id="Content can't be displayed" />;
});

export const FailedToRenderErrorBoundary = memo(
    <P, T extends P & ErrorComponentProps>({
        children,
        customMessage = <FailedToRenderMessage />,
        errorComponent,
    }: {
        children: ReactElement<P>;
        customMessage?: ReactNode;
        errorComponent?(args: T): ReactNode;
    }) => {
        return (
            <ErrorBoundary
                customMessage={customMessage}
                errorComponent={errorComponent}
            >
                {children}
            </ErrorBoundary>
        );
    }
);

const withErrorBoundary = <P, T extends P & ErrorComponentProps>(
    Component: ComponentType<P>,
    args?: {
        customMessage?: ReactNode;
        errorComponent?(args: T): ReactNode;
    }
): ComponentType<PropsWithChildren<P>> => {
    return (props: PropsWithChildren<P>) => {
        if (!args) {
            return (
                <FailedToRenderErrorBoundary>
                    <Component {...props} />
                </FailedToRenderErrorBoundary>
            );
        }

        const { customMessage, errorComponent } = args;

        if (errorComponent) {
            return (
                <FailedToRenderErrorBoundary
                    customMessage={customMessage}
                    errorComponent={errorComponent}
                >
                    <Component {...props} />
                </FailedToRenderErrorBoundary>
            );
        }

        return (
            <FailedToRenderErrorBoundary customMessage={customMessage}>
                <Component {...props} />
            </FailedToRenderErrorBoundary>
        );
    };
};

export default withErrorBoundary;
