import getCurrentLanguageFromPath from './util/getCurrentLanguageFromPath';

const lang = getCurrentLanguageFromPath(
    document.location.href,
    document.baseURI
);
document.documentElement.setAttribute('lang', lang);

const appRoot = document.getElementById('root');
appRoot?.setAttribute('notranslate', 'true');

export {};
