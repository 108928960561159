import { createContext } from 'react';

export enum NetworkStatus {
    ONLINE = 1,
    OFFLINE = 0,
}

interface NetworkStatusContextValue {
    networkStatus: NetworkStatus;
}

const NetworkStatusContext = createContext<NetworkStatusContextValue>({
    networkStatus: NetworkStatus.ONLINE,
});

export default NetworkStatusContext;
