import { createContext, useContext } from 'react';
import type { CustomDiscount, CustomDiscountInput } from '@apiTypes';
import assert from '../../core/util/assert';

export interface CustomDiscountContextValue {
    loading: boolean;
    isUpdating: boolean;
    discounts: CustomDiscount[];
    editMode: boolean;
    saveCustomDiscount: (values: CustomDiscountInput[]) => Promise<unknown>;
}

const CustomDiscountContextContext = createContext<
    Partial<CustomDiscountContextValue>
>({
    loading: false,
    isUpdating: false,
    editMode: false,
});

export const useCustomDiscountContext = () => {
    const value = useContext(CustomDiscountContextContext);

    assert(
        value.discounts,
        'Please check that CustomDiscountContext is present in the tree'
    );

    return value as CustomDiscountContextValue;
};

export default CustomDiscountContextContext;
