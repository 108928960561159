import {
    ApolloClient,
    ApolloLink,
    createHttpLink,
    InMemoryCache,
} from '@apollo/client';
import { asyncMap } from '@apollo/client/utilities';
import { setContext } from '@apollo/client/link/context';
import filterResponse from '../util/filterResponse';
import Storyblok from './storyblok';

const httpLink = createHttpLink({
    uri: APP_ENV.CMS_API_URL,
});

const authLink = setContext((_, { headers }) => {
    const token = APP_ENV.CMS_API_TOKEN;

    const storyblokStatus = Storyblok.status;
    const version = storyblokStatus.isPreview
        ? storyblokStatus.version
        : APP_ENV.CMS_API_VERSION;

    return {
        headers: {
            ...headers,
            token,
            version,
        },
    };
});

const getContentClient = (isLoggedIn: boolean) => {
    const permissionLink = new ApolloLink((operation, forward) => {
        const now = new Date();
        return asyncMap(forward(operation), async result => {
            if (isLoggedIn) {
                return filterResponse(result, {
                    isLogged: true,
                    now,
                });
            }
            return filterResponse(result, { isLogged: false, now });
        });
    });

    const cmsCache = new InMemoryCache({
        typePolicies: {
            FooterItem: {
                keyFields: ['id', 'lang'],
            },
            SaleItem: {
                keyFields: ['id', 'lang'],
            },
            PageItem: {
                keyFields: ['id', 'lang'],
            },
            PostItem: {
                keyFields: ['id', 'lang'],
                fields: {
                    content: {
                        merge: true,
                    },
                },
            },
            Query: {
                fields: {
                    PostItems: {
                        read: (
                            existing: { items?: unknown[] } | undefined,
                            { args }
                        ) => {
                            if (existing?.items) {
                                const { page, per_page: perPage } = args as {
                                    page: number;
                                    per_page: number;
                                };
                                const currentPage = page === 0 ? 0 : page - 1;
                                return {
                                    ...existing,
                                    items: existing.items.slice(
                                        currentPage * perPage,
                                        perPage
                                    ),
                                };
                            }
                            return existing;
                        },
                        keyArgs: [
                            'starts_with',
                            'language',
                            'sort_by',
                            'filter_query_v2',
                            'by_slugs',
                            'first_published_at_lt',
                        ],
                        merge: (
                            existing: { items?: unknown[] } | undefined,
                            incoming: { items?: unknown[] } | undefined,
                            { args }
                        ) => {
                            if (existing?.items && incoming?.items) {
                                const { page = 0, per_page: perPage } =
                                    args as {
                                        page: number;
                                        per_page: number;
                                    };
                                const merged = existing.items.slice(0);
                                for (
                                    let i = 0;
                                    i < incoming.items.length;
                                    i += 1
                                ) {
                                    merged[(page - 1) * perPage + i] =
                                        incoming.items[i];
                                }
                                return {
                                    ...existing,
                                    ...incoming,
                                    ...{ items: merged },
                                };
                            }

                            return {
                                ...existing,
                                ...incoming,
                            };
                        },
                    },
                },
            },
        },
    });

    const links = ApolloLink.from([authLink, permissionLink, httpLink]);

    return new ApolloClient({
        link: links,
        cache: cmsCache,
        connectToDevTools: false,
    });
};

export default getContentClient;
