const loadStoryblokBridge = (callback: () => void) => {
    const existingScript = document.getElementById('storyblok');
    if (!existingScript) {
        const script = document.createElement('script');
        script.src = 'https://app.storyblok.com/f/storyblok-v2-latest.js';
        script.id = 'storyblok';
        document.body.appendChild(script);
        script.onload = () => {
            callback();
        };
    }
    if (existingScript) callback();
};
export default loadStoryblokBridge;
