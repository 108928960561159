import { useContext } from 'react';
import { CartContext } from '../../core/cartContext';
import { AppContext } from '../../core/context';
import useLoginState from '../../core/hooks/useLoginState';

const usePriceQueryOptions = (forceLoggedOut?: boolean) => {
    const isLoggedIn = useLoginState();
    const isLoggedInResult = isLoggedIn && !forceLoggedOut;
    const { cartType, cart } = useContext(CartContext);
    const { customerPriceCacheKey } = useContext(AppContext);
    const loadingPriceQueryOptions =
        isLoggedInResult && (customerPriceCacheKey === undefined || !cart.id);

    return {
        variables: isLoggedInResult
            ? {
                  customerPriceCacheKey,
                  cartType,
                  isLoggedIn: isLoggedInResult,
                  cartId: cart.id,
              }
            : {
                  isLoggedIn: isLoggedInResult,
              },
        loadingPriceQueryOptions,
    };
};

export default usePriceQueryOptions;
