import { useContext } from 'react';
import { AppContext } from '../context';

const useCustomerPriceVisibility = (): [
    boolean,
    (showCustomerPrice: boolean) => void,
] => {
    const { showCustomerPrice, setShowCustomerPrice } = useContext(AppContext);

    return [showCustomerPrice, setShowCustomerPrice];
};

export default useCustomerPriceVisibility;
