import { memo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { clsx } from 'clsx';
import { Text } from '@library';
import useStoreAndLanguageParams from '../../core/hooks/useStoreAndLanguageParams';
import replaceLanguageInPath from '../../core/util/replaceLanguageInPath';
import useTabletView from '../../core/hooks/dom/useTabletView';
import ChevronDownIcon from '../../core/components/icons/ChevronDownIcon';
import FlexLayout from '../../core/components/layout/FlexLayout';
import Button from '../../core/components/Button';
import useCurrentStore from '../../core/hooks/useCurrentStore';
import SidePaneLanguageMenu from '../../core/components/header/SidePaneLanguageMenu';
import CustomLink from '../../core/components/CustomLink';
import withErrorBoundary from '../../core/components/errors/withErrorBoundary';
import styles from './LanguageSelector.module.scss';

const LanguageSelector = () => {
    const { pathname, search } = useLocation();
    const { language: currentLanguage } = useStoreAndLanguageParams();
    const store = useCurrentStore();

    const isTabletView = useTabletView();
    const [showLanguagePane, setShowLanguagePane] = useState(false);

    const { languages } = store;
    return (
        <div
            className={styles.languageSelector}
            data-test-class="languageSwitcherLinks"
        >
            {isTabletView ? (
                <>
                    <Button
                        textOnly
                        noPadding
                        onPress={() => setShowLanguagePane(true)}
                    >
                        <FlexLayout
                            gap="micro"
                            align="center"
                            wrap="noWrap"
                            standardColumns
                        >
                            <Text secondary uppercase lineHeightAsFontSize>
                                <span className={styles.languageItemText}>
                                    {currentLanguage}
                                </span>
                            </Text>
                            <ChevronDownIcon />
                        </FlexLayout>
                    </Button>
                    {showLanguagePane && (
                        <SidePaneLanguageMenu
                            languages={languages}
                            onClose={() => setShowLanguagePane(false)}
                        />
                    )}
                </>
            ) : (
                languages.map(({ language }) => (
                    <div className={styles.item} key={language}>
                        <CustomLink
                            data-test-class="changeLanguageLink"
                            className={clsx(styles.languageItem, {
                                [styles.languageItemActive]:
                                    language === currentLanguage,
                            })}
                            to={{
                                pathname: replaceLanguageInPath({
                                    pathname,
                                    language,
                                    isLockedStore: !!APP_ENV.STORE,
                                }),
                                search,
                            }}
                            replace
                        >
                            <Text
                                secondary
                                uppercase
                                medium={language === currentLanguage}
                                lineHeightAsFontSize
                            >
                                <span className={styles.languageItemText}>
                                    {language}
                                </span>
                            </Text>
                        </CustomLink>
                    </div>
                ))
            )}
        </div>
    );
};

export default memo(withErrorBoundary(LanguageSelector));
