import { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { Text } from '@library';
import SidePane from '../SidePane';
import type { Language } from '../../types/language';
import replaceLanguageInPath from '../../util/replaceLanguageInPath';
import useStoreAndLanguageParams from '../../hooks/useStoreAndLanguageParams';
import useCustomNavigate from '../../hooks/router/useCustomNavigate';
import SidePaneMenuItem from './SidePaneMenuItem';
import styles from './SidePaneLanguageMenu.module.scss';

const SidePaneLanguageContent = memo(
    ({
        languages,
        onClose,
    }: {
        onClose: () => void;
        languages: {
            language: Language;
        }[];
    }) => {
        const store = useStoreAndLanguageParams();

        const navigate = useCustomNavigate();
        const { pathname, search } = useLocation();

        return (
            <div className={styles.container}>
                {languages.map(({ language }) => (
                    <SidePaneMenuItem
                        key={language}
                        onClick={() => {
                            navigate(
                                {
                                    pathname: replaceLanguageInPath({
                                        pathname,
                                        language,
                                        isLockedStore: !!APP_ENV.STORE,
                                    }),
                                    search,
                                },
                                { replace: true }
                            );
                            onClose();
                        }}
                        icon={store.language === language ? 'checkmark' : null}
                        medium={language === store.language}
                        messageId={language}
                    />
                ))}
            </div>
        );
    }
);

const SidePaneLanguageMenu = ({
    languages,
    onClose,
}: {
    onClose: () => void;
    languages: {
        language: Language;
    }[];
}) => {
    return (
        <SidePane
            side="right"
            onClose={onClose}
            header={
                <Text secondary bold>
                    <FormattedMessage id="language selection" />
                </Text>
            }
        >
            <SidePaneLanguageContent languages={languages} onClose={onClose} />
        </SidePane>
    );
};

export default memo(SidePaneLanguageMenu);
