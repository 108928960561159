import { gql, useQuery } from '@apollo/client';
import useLoginState from '../../core/hooks/useLoginState';
import useStoreAndLanguageParams from '../../core/hooks/useStoreAndLanguageParams';
import type { PreorderSeasonDiscountTier } from '../types';

export interface ActivePreorderSeason {
    id: number;
    start: string;
    end: string;
    deliveryStart: string;
    deliveryEnd: string;
    nameEN: string;
    nameDE: string;
    nameFR: string;
    nameIT: string;
    discountTiers: PreorderSeasonDiscountTier[];
    disallowNonPreorderProducts?: boolean;
    logoUrl?: string;
    cmsRootFolder: string;
}

const useActivePreorderSeasons = (skip?: boolean) => {
    const { store, language } = useStoreAndLanguageParams();
    const isLoggedIn = useLoginState();

    const {
        data: { activePreorderSeasons } = { activePreorderSeasons: [] },
        error,
        loading,
    } = useQuery<{
        activePreorderSeasons: ActivePreorderSeason[];
    }>(
        gql`
            query activePreorderSeasons($store: ID!) {
                activePreorderSeasons(store: $store) {
                    id
                    start
                    end
                    nameEN
                    nameDE
                    nameFR
                    nameIT
                    discountTiers {
                        tier
                        nameDE
                        nameEN
                        nameFR
                        nameIT
                        threshold
                    }
                    disallowNonPreorderProducts
                    deliveryStart
                    deliveryEnd
                    logoUrl
                    cmsRootFolder
                }
            }
        `,
        {
            variables: {
                store,
            },
            skip: skip || !isLoggedIn,
        }
    );

    if (error) {
        throw error;
    }

    return {
        activePreorderSeasons: activePreorderSeasons.map(item => ({
            ...item,
            preorderName: item[
                `name${language.toUpperCase()}` as keyof ActivePreorderSeason
            ] as string,
        })),
        loading,
    };
};

export default useActivePreorderSeasons;
