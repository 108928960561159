import type { ReactNode } from 'react';
import { memo } from 'react';
import { clsx } from 'clsx';
import { FormattedMessage } from 'react-intl';
import { HStack, VStack } from '../layout';
import { Paragraph } from '../typography';
import { Button } from '../buttons';
import CheckmarkCircleIcon from '../icons/CheckmarkCircleIcon';
import CloseIcon from '../icons/CloseIcon';
import XCircleIcon from '../icons/XCircleIcon';
import styles from './Toast.module.scss';

type ToastType = 'success' | 'info' | 'system' | 'error';

export interface ToastProps {
    /**
     * The theme of toast
     */
    type: ToastType;
    /**
     *
     */
    title: ReactNode;
    /**
     *
     */
    message?: ReactNode;
    /**
        The icon
     */
    icon?: ReactNode;
    /**
     *
     */
    onClose: () => void;
}

const getIcon = ({ type }: Pick<ToastProps, 'type'>) => {
    switch (type) {
        case 'success': {
            return <CheckmarkCircleIcon theme={styles.iconTheme} />;
        }

        case 'error': {
            return <XCircleIcon theme={styles.iconTheme} />;
        }
        case 'system':
        case 'info': {
            return null;
        }
    }
};

const Toast = ({ icon, title, message, type, onClose }: ToastProps) => {
    const messageComponent = <Paragraph primary>{message}</Paragraph>;
    const toastIcon = icon || getIcon({ type });
    return (
        <div
            className={clsx(styles.toast, styles[type])}
            data-test-class="toast"
        >
            <VStack gap="small">
                <HStack gap="medium" align="center" justify="spaceBetween">
                    <HStack gap="responsiveMiniTabletSmall" align="center">
                        {toastIcon && (
                            <div className={styles.icon}>{toastIcon}</div>
                        )}
                        <VStack>
                            <Paragraph primary medium>
                                {title}
                            </Paragraph>
                            {message && (
                                <div className={styles.tabletView}>
                                    {messageComponent}
                                </div>
                            )}
                        </VStack>
                    </HStack>

                    <Button
                        inline
                        text
                        onClick={() => {
                            onClose();
                        }}
                        tertiary={type !== 'info'}
                    >
                        <span className={styles.tabletView}>
                            <FormattedMessage id="close" />
                        </span>
                        <span
                            className={styles.mobileView}
                            data-test-class="close"
                        >
                            <CloseIcon bold />
                        </span>
                    </Button>
                </HStack>
                {message && (
                    <div className={styles.mobileView}>{messageComponent}</div>
                )}
            </VStack>
        </div>
    );
};

export default memo(Toast);
