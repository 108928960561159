import { memo, forwardRef } from 'react';
import type { AriaAttributes, ChangeEvent, ReactNode } from 'react';
import { clsx } from 'clsx';
import styles from './TextInput.module.css';
import FormField from './FormField';

export interface TextInputProps
    extends AriaAttributes,
        React.HTMLProps<HTMLInputElement> {
    id?: string;
    name?: string;
    placeholder?: string;
    description?: ReactNode;
    value?: string;
    infoText?: ReactNode;
    disabled?: boolean;
    required?: boolean;
    greyBackground?: boolean;
    noGreyBorder?: boolean;
    noHoverBorder?: boolean;
    noActiveBorder?: boolean;
    noFilledBorder?: boolean;
    withPadding?: boolean;
    cursorPointer?: boolean;
    error?: boolean | string;
    errorText?: boolean;
    formInput?: boolean;
    material?: boolean;
    hasStartAdornment?: boolean;
    hasEndAdornment?: boolean;
    largeEndAdornment?: boolean;
    startAdornment?: ReactNode;
    endAdornment?: ReactNode;
    onChange?(event: ChangeEvent<HTMLInputElement>): void;
    borderRadius?: boolean;
    endPadding?: boolean;
    noBackground?: boolean;
    renderInput?: (props: { className: string; id?: string }) => ReactNode;
}

const TextInput = forwardRef(
    (
        {
            id,
            description,
            value,
            infoText,
            greyBackground = false,
            noGreyBorder = false,
            noHoverBorder = false,
            noActiveBorder = false,
            noFilledBorder = false,
            withPadding = false,
            cursorPointer = false,
            formInput = false,
            error = false,
            errorText = false,
            hasStartAdornment = false,
            hasEndAdornment = false,
            largeEndAdornment = false,
            material = true,
            borderRadius = false,
            endPadding = false,
            noBackground = false,
            startAdornment,
            endAdornment,
            renderInput,
            ...otherProps
        }: TextInputProps,
        ref: React.Ref<HTMLInputElement>
    ) => {
        const className = clsx(styles.input, styles.textInput, {
            [styles.greyBackground]: greyBackground,
            [styles.greyBorder]: !noGreyBorder,
            [styles.hoverBorder]: !noHoverBorder,
            [styles.activeBorder]: !noActiveBorder,
            [styles.filledBorder]: !noFilledBorder,
            [styles.padding]: withPadding,
            [styles.cursorPointer]: cursorPointer,
            [styles.material]: material,
            [styles.errorInput]: error,
            [styles.errorText]: errorText,
            [styles.formInput]: formInput,
            [styles.filled]: !!value,
            [styles.noBackground]: noBackground,
            [styles.withStartAdornment]: hasStartAdornment && startAdornment,
            [styles.withEndAdornment]: hasEndAdornment && endAdornment,
            [styles.largeEndAdornment]: largeEndAdornment,
            [styles.hasBorderRadius]: borderRadius,
            [styles.endPadding]: endPadding,
        });

        return (
            <div className={styles.textInputContainer}>
                <FormField
                    id={id}
                    label={description}
                    hint={infoText}
                    error={typeof error === 'string' ? error : ''}
                >
                    <div
                        className={clsx(styles.inputBox, {
                            [styles.hasLabel]: !!description,
                        })}
                    >
                        {hasStartAdornment && startAdornment && (
                            <div
                                className={clsx(
                                    styles.startAdornmentContainer,
                                    {
                                        [styles.startAdornmentContainerMaterial]:
                                            material,
                                    }
                                )}
                            >
                                {startAdornment}
                            </div>
                        )}
                        {renderInput ? (
                            renderInput({ className, id, ...otherProps })
                        ) : (
                            <input
                                {...otherProps}
                                id={id}
                                ref={ref}
                                className={className}
                                value={value}
                            />
                        )}
                        {hasEndAdornment && endAdornment && (
                            <div
                                className={clsx(styles.endAdornmentContainer, {
                                    [styles.endAdornmentContainerMaterial]:
                                        material,
                                })}
                            >
                                {endAdornment}
                            </div>
                        )}
                    </div>
                </FormField>
            </div>
        );
    }
);

export default memo(TextInput);
