import { lazy } from 'react';
import SpinnerPage from '../pages/SpinnerPage';

type MissingChunkLoadError = Error & {
    name: 'ChunkLoadError';
    type: 'missing';
};

const loadingErrors = [
    'Failed to fetch dynamically imported module',
    'Importing a module script failed',
    'error loading dynamically imported module',
];
// three type of chunk load error: timeout / error / missing
export function isChunkLoadError(
    error: unknown
): error is MissingChunkLoadError {
    return (
        error instanceof Error &&
        loadingErrors.some(message =>
            error.message.toLowerCase().includes(message.toLowerCase())
        )
    );
}

function isMissingCssChunkError(error: unknown) {
    return (
        error instanceof Error &&
        error.message.startsWith('Unable to preload CSS for')
    );
}

function forceReload() {
    window.location.reload();
}

function LazyComponent(factory: Parameters<typeof lazy>[0]) {
    /*
     * After deploying a new version, users that have the browser opened might experience an error when
     * views that were not previously loaded on the frontend side. By doing this, we allow the chunks to fail
     * loading and silently update the user, keeping the UX consistent.
     */
    const factoryWithChunkErrorHandler = async (attempts = 3) => {
        return new Promise((resolve, reject) => {
            factory()
                .then(resolve)
                .catch(error => {
                    if (attempts <= 0) {
                        if (
                            isChunkLoadError(error) ||
                            isMissingCssChunkError(error)
                        ) {
                            forceReload();
                            resolve({ default: SpinnerPage });
                        }
                        reject(error);
                    }
                    setTimeout(() => {
                        factoryWithChunkErrorHandler(attempts - 1)
                            .then(resolve)
                            .catch(reject);
                    }, 500);
                });
        });
    };

    return lazy(factoryWithChunkErrorHandler as typeof factory);
}

export default LazyComponent;
