import type { MutableRefObject } from 'react';
import { createContext } from 'react';
import type { Cart, CartType } from '../cart/types';

export interface CartContextValues {
    cart: Cart;
    cartType: CartType;
    cartUpdatedFlag: string;
    ineligibleQuantityItems: MutableRefObject<Map<string, number>>;
    canSubmit: boolean;
}

export const CartContext = createContext<CartContextValues>({
    cart: null as unknown as Cart,
    cartType: 'local',
    cartUpdatedFlag: '',
    canSubmit: false,
    ineligibleQuantityItems: {
        current: new Map(),
    },
});

// Need this for unit testing
CartContext.displayName = 'cart';
