import { memo } from 'react';
import type { IconProps } from './types';

const CartIcon = ({
    iconLabel,
    color,
    small = false,
    mini = false,
}: IconProps & { small?: boolean; mini?: boolean }) => {
    if (small || mini) {
        const size = small
            ? {
                  width: '17.388',
                  height: '17.388',
              }
            : {
                  width: '12.388',
                  height: '12.388',
              };
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                {...size}
                viewBox="0 0 17.388 15.003"
                data-icon={iconLabel}
                color={color}
            >
                <g transform="translate(0.719 0.72)">
                    <path
                        d="M7.7,12.354a.825.825,0,1,1-.825.825A.825.825,0,0,1,7.7,12.354Z"
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                    />
                    <circle
                        cx="0.824"
                        cy="0.824"
                        r="0.824"
                        transform="translate(12.125 12.354)"
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                    />
                    <path
                        d="M1,1H3.775l2.49,7.719h8.609"
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                    />
                    <line
                        x2="11.231"
                        transform="translate(5.156 4.283)"
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                    />
                </g>
            </svg>
        );
    }
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30.699"
            height="26.099"
            viewBox="0 0 30.699 26.099"
            data-icon={iconLabel}
            color={color}
        >
            <g transform="translate(0.719 0.72)">
                <path
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.44px"
                    className="a"
                    d="M499.942,386.6a1.564,1.564,0,1,1-1.564,1.564,1.565,1.565,0,0,1,1.564-1.564Z"
                    transform="translate(-487.246 -365.073)"
                />
                <path
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.44px"
                    className="a"
                    d="M504.456,386.6a1.562,1.562,0,1,1-1.562,1.562,1.562,1.562,0,0,1,1.562-1.562Z"
                    transform="translate(-481.797 -365.072)"
                />
                <path
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.44px"
                    className="a"
                    d="M493.333,376.845h5.262l4.879,15.938H519.8"
                    transform="translate(-493.332 -376.845)"
                />
                <line
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.44px"
                    className="a"
                    x2="18.964"
                    transform="translate(9.217 11.085)"
                />
                <line
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.44px"
                    className="a"
                    x2="21.299"
                    transform="translate(7.961 6.225)"
                />
            </g>
        </svg>
    );
};

export default memo(CartIcon);
