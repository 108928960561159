import { memo } from 'react';
import type { ReactNode } from 'react';
import { clsx } from 'clsx';
import styles from './HorizontalLayout.module.scss';

export const alignmentValueMap = {
    start: 'flex-start',
    end: 'flex-end',
    center: 'center',
    stretch: 'stretch',
    normal: 'normal',
    baseline: 'baseline',
};

export const justifyValueMap = {
    start: 'flex-start',
    end: 'flex-end',
    spaceBetween: 'space-between',
    center: 'center',
    normal: 'normal',
};
const HorizontalLayout = ({
    children,
    align = 'start',
    justify = 'start',
    wrap = false,
    fullWidth = false,
    fullHeight,
    reversed = false,
    stretchChildren = false,
    gap = 'none',
    testId,
    testClass,
    testState,
}: {
    children: ReactNode;
    align?: keyof typeof alignmentValueMap;
    justify?: keyof typeof justifyValueMap;
    wrap?: boolean;
    fullWidth?: boolean;
    fullHeight?: boolean;
    reversed?: boolean;
    stretchChildren?: boolean;
    gap?: 'none' | 'micro' | 'small' | 'medium' | 'large';
    testId?: string;
    testClass?: string;
    testState?: string;
}) => {
    return (
        <div
            style={{
                justifyContent: justifyValueMap[justify],
                alignItems: alignmentValueMap[align],
                flexWrap: wrap ? 'wrap' : 'nowrap',
            }}
            className={clsx(styles.horizontalContainer, {
                [styles.stretchChildren]: stretchChildren,
                [styles.fullWidth]: fullWidth,
                [styles.fullHeight]: fullHeight,
                [styles.reversed]: reversed,
                [styles[`${gap}Gap`]]: gap !== 'none',
            })}
            data-test-id={testId}
            data-test-class={testClass}
            data-test-state={testState}
        >
            {children}
        </div>
    );
};
/**
 * @deprecated
 * Please use HStack
 */
export default memo(HorizontalLayout);
