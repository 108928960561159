import filterByVisibility from './filterByVisibility';

const filterResponse = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    response: Record<string, any>,
    params: { now: Date; isLogged: boolean; allowPreOrder?: boolean }
) => {
    const filtered = Object.entries(response.data)
        .map(([key, value]) => {
            return [key, filterByVisibility(value, params)];
        })
        .reduce(
            (result, [key, value]) => {
                return {
                    ...result,
                    [key as string]: value,
                };
            },
            {} as Record<string, unknown>
        );

    return {
        data: filtered,
    };
};

export default filterResponse;
