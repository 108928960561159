import { useContext, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { notify } from '@library';
import { CartContext } from '../../core/cartContext';
import captureException from '../../core/util/captureException';
import RemoteCart from '../services/RemoteCart';
import { CartErrors } from '../types';

const useCartErrorNotifications = () => {
    const { cart } = useContext(CartContext);

    useEffect(() => {
        if (cart instanceof RemoteCart) {
            const subscription = cart.cartErrors.subscribe(cartError => {
                if (!cartError) {
                    return;
                }

                const { code, error } = cartError;

                switch (code) {
                    case CartErrors.BULK_QUANTITY_UPDATE_ERROR:
                        notify({
                            type: 'error',
                            title: (
                                <FormattedMessage id="Failed to update cart content" />
                            ),
                            message: (
                                <FormattedMessage id="Your cart changes were not submitted to the server. Please check your cart and try again." />
                            ),
                            id: CartErrors.BULK_QUANTITY_UPDATE_ERROR,
                        });

                        break;
                    case CartErrors.CLEAR_CART_ERROR:
                        notify({
                            type: 'error',
                            title: (
                                <FormattedMessage id="Failed to clear cart" />
                            ),
                            message: (
                                <FormattedMessage id="Your cart could not be cleared. Please try again later." />
                            ),

                            id: CartErrors.CLEAR_CART_ERROR,
                        });

                        break;
                    case CartErrors.QUANTITY_UPDATE_ERROR:
                        notify({
                            type: 'error',
                            title: (
                                <FormattedMessage id="Failed to update cart content" />
                            ),
                            message: (
                                <FormattedMessage id="Your cart changes were not submitted to the server. Please check your cart and try again." />
                            ),

                            id: CartErrors.QUANTITY_UPDATE_ERROR,
                        });

                        break;
                    case CartErrors.REFETCH_ERROR:
                        notify({
                            type: 'error',
                            title: (
                                <FormattedMessage id="Failed to synchronize cart" />
                            ),
                            message: (
                                <FormattedMessage id="The content of your cart could not be retrieved from the server and the displayed entries might be out of date. Please verify the cart content before proceeding." />
                            ),
                            id: CartErrors.REFETCH_ERROR,
                        });

                        break;
                }

                captureException(error);
            });

            return () => {
                if (!subscription.closed) {
                    subscription.unsubscribe();
                }
            };
        }
    }, [cart]);
};

export default useCartErrorNotifications;
