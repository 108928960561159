import { gql } from '@apollo/client';
import { useEffect } from 'react';
import usePolledQuery from '../hooks/apollo/usePolledQuery';

export const customerPriceCacheKeyQuery = gql`
    query customerPriceCacheKey {
        customerPriceCacheKey
    }
`;
const usePriceCacheKey = (isLoggedIn = false, isImpersonating = false) => {
    const query = usePolledQuery<{
        customerPriceCacheKey: string;
    }>(
        customerPriceCacheKeyQuery,
        {
            fetchPolicy: 'network-only',
            skip: !isLoggedIn,
        },
        true
    );

    useEffect(() => {
        if (!isImpersonating) {
            query.startPolling(180_000);

            return () => {
                query.stopPolling();
            };
        }

        return () => {};
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isImpersonating]);

    return query.data?.customerPriceCacheKey;
};

export default usePriceCacheKey;
