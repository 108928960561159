import { memo, type ReactNode } from 'react';
import { SystemMaintenanceTypeEnum } from '@apiTypes';
import useSystemMaintenance from '../../queries/useSystemMaintenances';
import MaintenanceModeFullPage from './MaintenanceModeFullPage';

const MaintenanceMode = ({ children }: { children: ReactNode }) => {
    const { systemMaintenance } = useSystemMaintenance();

    if (
        systemMaintenance &&
        systemMaintenance.type === SystemMaintenanceTypeEnum.Full
    ) {
        return (
            <MaintenanceModeFullPage systemMaintenance={systemMaintenance} />
        );
    }
    return <>{children}</>;
};

export default memo(MaintenanceMode);
