import { memo } from 'react';
import type { ApolloError } from '@apollo/client';
import { FormattedMessage } from 'react-intl';
import { AlertBanner, Text } from '@library';
import formatErrorMessage from '../util/formatErrorMessage';

const UnhandledError = ({ children }: { children?: Error | ApolloError }) => {
    let content: unknown = <FormattedMessage id="An Unknown Error Occurred." />;

    const errorMessage = formatErrorMessage(children);

    if (APP_ENV.VERBOSE_ERROR_BOX) {
        content = errorMessage;
    } else {
        // eslint-disable-next-line no-console
        console.error('Error caught');
        // eslint-disable-next-line no-console
        console.error(errorMessage);
    }

    return (
        <AlertBanner data-test-class="errorBox">
            <Text primary>{content as string} </Text>
        </AlertBanner>
    );
};

export default memo(UnhandledError);
