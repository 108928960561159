import { memo } from 'react';
import type { IconProps } from './types';

const ErrorIcon = ({ iconLabel, color }: IconProps) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="67"
            height="67"
            viewBox="0 0 67 67"
            data-icon={iconLabel}
            color={color}
        >
            <g transform="translate(-408 -439)">
                <g
                    className="a"
                    fill="none"
                    stroke="currentColor"
                    transform="translate(408 439)"
                >
                    <circle
                        className="b"
                        stroke="none"
                        cx="33.5"
                        cy="33.5"
                        r="33.5"
                    />
                    <circle
                        className="c"
                        fill="none"
                        cx="33.5"
                        cy="33.5"
                        r="33"
                    />
                </g>
                <g transform="translate(-5)">
                    <path
                        className="a"
                        fill="none"
                        stroke="currentColor"
                        d="M2451.171,440.342l19.459-19.459"
                        transform="translate(-2014.27 42.046)"
                    />
                    <path
                        className="a"
                        fill="none"
                        stroke="currentColor"
                        d="M2470.631,440.342l-19.459-19.459"
                        transform="translate(-2014.27 42.046)"
                    />
                </g>
            </g>
        </svg>
    );
};

export default memo(ErrorIcon);
