import { Navigate, useLocation } from 'react-router-dom';
import replaceLanguageInPath from '../../util/replaceLanguageInPath';
import type { Store } from '../../types/stores';
import type { Language } from '../../types';

const useStoreLanguageRedirect = ({
    storeId,
    language,
    stores,
}: {
    storeId: string;
    language?: Language;
    stores?: Store[];
}) => {
    const { pathname, search } = useLocation();

    if (!stores) {
        return null;
    }

    const currentStore = stores.find(item => item.id === storeId);

    if (currentStore) {
        if (!language) {
            return (
                <Navigate
                    to={{
                        pathname: replaceLanguageInPath({
                            pathname,
                            language: currentStore.languages[0].language,
                            isLockedStore: !!APP_ENV.STORE,
                            noLanguageInPath: true,
                        }),
                        search,
                    }}
                    replace
                />
            );
        }

        if (
            !currentStore.languages
                .map(item => item.language)
                .includes(language)
        ) {
            return (
                <Navigate
                    to={{
                        pathname: replaceLanguageInPath({
                            pathname,
                            language: currentStore.languages[0].language,
                            isLockedStore: !!APP_ENV.STORE,
                        }),
                        search,
                    }}
                    replace
                />
            );
        }
    }
};

export default useStoreLanguageRedirect;
