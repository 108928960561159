import { useEffect } from 'react';
import useImmutableCallback from '../useImmutableCallback';

const useGlobalKeydown = (callback: (event: KeyboardEvent) => void) => {
    const immutableCallback = useImmutableCallback(callback);

    useEffect(() => {
        window.addEventListener('keydown', immutableCallback);
        return () => {
            window.removeEventListener('keydown', immutableCallback);
        };
    }, [immutableCallback]);
};

export default useGlobalKeydown;
