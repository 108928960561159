import type {
    FieldMergeFunction,
    FieldPolicy,
    FieldReadFunction,
} from '@apollo/client';

interface CurrentUserFieldPolicy<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    TExisting = any,
    TIncoming = TExisting,
    TReadResult = TIncoming,
> extends FieldPolicy {
    read: FieldReadFunction<TExisting, TReadResult>;
    merge: FieldMergeFunction<TExisting, TIncoming>;
}

const persistedFieldNames = [
    'username',
    'impersonating',
    'customerNumber',
    'stores',
    'language',
    'branches',
    'dealerPricesEnabled',
];

const currentUserFieldPolicy: CurrentUserFieldPolicy = {
    read: (existing: Record<string, string> | undefined, { field }) => {
        if (
            !field?.selectionSet ||
            !field.selectionSet.selections.every(item =>
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                persistedFieldNames.includes((item as any)?.name.value)
            )
        ) {
            return existing;
        }

        const storageEntry = localStorage.getItem('currentUserData');

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let userData: Record<string, any> | undefined;

        if (storageEntry) {
            try {
                userData = JSON.parse(storageEntry);
            } catch (e) {
                userData = existing;
            }
        } else {
            userData = existing;
        }

        if (!existing || existing.username === userData?.username) {
            return userData;
        }

        return existing;
    },
    merge: (existing, incoming, { mergeObjects }) => {
        if (!incoming) {
            localStorage.removeItem('currentUserData');
            return incoming;
        }

        const merged = mergeObjects(existing, incoming);

        localStorage.setItem(
            'currentUserData',
            JSON.stringify({
                // eslint-disable-next-line no-underscore-dangle
                __typename: merged.__typename,
                username: merged.username,
                impersonating: merged.impersonating,
                customerNumber: merged.customerNumber,
                stores: merged.stores,
                language: merged.language,
                branches: merged.branches,
                dealerPricesEnabled: merged.dealerPricesEnabled,
            })
        );

        return merged;
    },
};

export default currentUserFieldPolicy;
