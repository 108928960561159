import flattenTree from './flattenTree';

interface Node<T extends Node<T>> {
    children: T[];
}

interface NodeOutput<T> {
    children: (T & NodeOutput<T>)[];
    allChildren?: (T & NodeOutput<T>)[];
}

const addAllChildToTree = <T extends Node<T>>(
    items: T[]
): (T & NodeOutput<T>)[] => {
    return items.map(node => {
        if (node.children.length === 0) {
            return {
                ...node,
                allChildren: [],
            };
        }
        const children = addAllChildToTree(node.children);
        return {
            ...node,
            children,
            allChildren: flattenTree(node.children),
        } as T & NodeOutput<T>;
    });
};

export default addAllChildToTree;
