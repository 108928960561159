import { storeAccessToken } from './credentialsStorage';

const checkUrlToken = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const urlToken = urlParams.get('accessToken');

    if (urlToken) {
        storeAccessToken(urlToken, false);

        urlParams.delete('accessToken');
        const paramsString = urlParams.toString();
        window.history.replaceState(
            null,
            '',
            `${window.location.pathname}${
                paramsString.length > 0 ? '?' : ''
            }${paramsString}`
        );
    }

    return urlToken;
};

export default checkUrlToken;
