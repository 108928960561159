import { useMemo } from 'react';
import { buildTree } from '../../core/util/categories';
import useCategories from '../../core/queries/useCategories';

const useCategoriesTree = (initial?: boolean) => {
    const { categories: items, loading } = useCategories(initial);

    const tree = useMemo(() => buildTree(items), [items]);

    return {
        tree,
        loading,
    };
};

export default useCategoriesTree;
