import { memo } from 'react';
import type { CSSProperties } from 'react';
import styles from './ProgressIndicators.module.scss';

export const SmallProgressIndicator = memo(() => {
    return (
        <div
            className={styles.smallProgressIndicator}
            data-test-class="progressIndicator"
        >
            <div className={styles.dotLoader} />
            <div className={styles.dotLoader} />
            <div className={styles.dotLoader} />
        </div>
    );
});

export const ProgressSpinner = memo(({ ...ariaProps }) => (
    <div
        className={styles.spinner}
        {...ariaProps}
        data-test-class="progressSpinner"
        data-testid="progressSpinner"
    />
));

export const LargeProgressSpinner = memo(({ ...ariaProps }) => (
    <div
        className={styles.largeSpinner}
        {...ariaProps}
        data-test-class="progressIndicator"
    />
));

export const FluidProgressIndicator = memo(() => {
    return (
        <div
            style={
                {
                    '--progress-indicator-size': '20px',
                } as CSSProperties
            }
            className={styles.loadingContainer}
            data-test-class="progressIndicator"
        >
            <SmallProgressIndicator />
        </div>
    );
});
