import type { GraphQLError } from 'graphql';
import LimitedArray from '../LimitedArray';

export interface RequestData {
    queryName: string;
    timeTaken: string;
    timestamp: number;
    data?: unknown;
    errors?: readonly GraphQLError[];
}

class RequestsLog {
    private requestsWithError: RequestData[] = [];

    private readonly successfulRequests = new LimitedArray<RequestData>(20);

    logSuccessfulRequest(request: RequestData) {
        this.successfulRequests.push(request);
    }

    logRequestWithError(request: RequestData) {
        this.requestsWithError.push(request);
    }

    flushOldErrors() {
        this.requestsWithError = this.requestsWithError.slice(-10);
    }

    getSuccessfulRequests() {
        return this.successfulRequests.slice();
    }

    getRequestsWithError() {
        return this.requestsWithError.slice();
    }
}

const requestsLog = new RequestsLog();

export default requestsLog;
