import type { LinkProps } from 'react-router-dom';
import { memo } from 'react';
import type { BaseButtonProps } from '@library';
import { BaseButton } from '@library';
import CustomLink from './CustomLink';

const CustomLinkButton = ({
    children,
    to,
    ...props
}: Omit<BaseButtonProps, 'loading' | 'component'> & LinkProps) => {
    return (
        <BaseButton
            {...props}
            component={
                // eslint-disable-next-line jsx-a11y/anchor-has-content
                <CustomLink to={to} />
            }
        >
            {children}
        </BaseButton>
    );
};

export default memo(CustomLinkButton);
