import { useState } from 'react';
import useEventListener from './useEventListener';

const useVisibilityStatus = (onChange?: (isVisible: boolean) => void) => {
    const [isVisible, setIsVisible] = useState(
        document.visibilityState === 'visible'
    );

    useEventListener({
        element: document,
        type: 'visibilitychange',
        listener: () => {
            const visible = document.visibilityState === 'visible';
            setIsVisible(visible);
            onChange?.(visible);
        },
    });

    return isVisible;
};

export default useVisibilityStatus;
