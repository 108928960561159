import { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import { Heading, VStack, Text, HStack, Button } from '@library';
import {
    useCookieConsent,
    useCookieConsentActions,
    useCookieConsentContext,
} from '../context/CookieConsentContext';
import CustomLinkButton from '../../core/components/CustomLinkButton';
import styles from './ConsentBanner.module.scss';
import CookiesImage from './CookiesImage';

const ConsentBanner = () => {
    const { consent, initialized } = useCookieConsent();
    const { setSettingsOpened } = useCookieConsentContext();

    const { acceptAll, acceptAllProgress, declineAll, declineAllProgress } =
        useCookieConsentActions();

    const [searchParams] = useSearchParams();
    const silentConsent = searchParams.get('silentConsent') === 'true';

    const open = consent === null && initialized && !silentConsent;

    if (!open) {
        return null;
    }
    return (
        <div className={styles.message} data-test-class="CookieBanner">
            <div className={styles.container}>
                <VStack gap="small">
                    <VStack gap="mini">
                        <HStack gap="small" align="center">
                            <CookiesImage />
                            <Heading h4>
                                <FormattedMessage id="Cookie Banner Header" />
                            </Heading>
                        </HStack>
                        <Text>
                            <FormattedMessage
                                id="Cookie Banner Text"
                                values={{
                                    link: chunks => (
                                        <CustomLinkButton
                                            text
                                            inline
                                            to="privacy"
                                            target="_blank"
                                        >
                                            {chunks}
                                        </CustomLinkButton>
                                    ),
                                }}
                            />
                        </Text>
                    </VStack>
                    <HStack gap="small" wrap>
                        <div className={styles.buttons}>
                            <div className={styles.button}>
                                <Button
                                    justify="center"
                                    onClick={() => {
                                        acceptAll().catch(ex => {
                                            throw ex;
                                        });
                                    }}
                                    loading={acceptAllProgress}
                                >
                                    <FormattedMessage id="Cookie AcceptButton" />
                                </Button>
                            </div>
                            <div className={styles.button}>
                                <Button
                                    justify="center"
                                    onClick={() => {
                                        declineAll().catch(ex => {
                                            throw ex;
                                        });
                                    }}
                                    loading={declineAllProgress}
                                >
                                    <FormattedMessage id="Cookie DeclineButton" />
                                </Button>
                            </div>
                        </div>
                        <div className={styles.settingsButton}>
                            <Button
                                outline
                                onClick={() => {
                                    setSettingsOpened(true);
                                }}
                                justify="center"
                            >
                                <FormattedMessage id="Cookie Banner AdjustSettingsButton" />
                            </Button>
                        </div>
                    </HStack>
                </VStack>
            </div>
        </div>
    );
};

export default memo(ConsentBanner);
