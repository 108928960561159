import { createContext } from 'react';
import type { Language } from '../types/language';
import type { Store } from '../types/stores';
export interface StoreContextValues {
    store?: Store;
    language: Language;
    urlLanguage: Language;
}

export const StoreContext = createContext<StoreContextValues>({
    language: 'de',
    urlLanguage: 'de',
});
