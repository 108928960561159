import { forwardRef, memo } from 'react';
import type { LinkProps } from 'react-router-dom';
import { Link, useSearchParams } from 'react-router-dom';

import isString from '../util/isString';

const CustomLink = memo(
    forwardRef<
        HTMLAnchorElement,
        LinkProps & React.RefAttributes<HTMLAnchorElement>
    >(({ children, to, ...props }, ref) => {
        const [params] = useSearchParams();
        const cartId = params.get('cartId');

        if (!cartId) {
            return (
                <Link to={to} ref={ref} {...props}>
                    {children}
                </Link>
            );
        }

        if (isString(to)) {
            const [path, search] = to.split('?');
            const searchParams = new URLSearchParams(search);

            searchParams.set('cartId', cartId);
            const updatedSearch = searchParams.toString();

            return (
                <Link to={`${path}?${updatedSearch}`} ref={ref} {...props}>
                    {children}
                </Link>
            );
        } else {
            const search = to.search?.toString() || '';
            const searchParams = new URLSearchParams(search);

            searchParams.set('cartId', cartId);
            const updatedSearch = searchParams.toString();

            return (
                <Link
                    to={{ ...to, search: updatedSearch }}
                    ref={ref}
                    {...props}
                >
                    {children}
                </Link>
            );
        }
    })
);

export default memo(CustomLink);
