import LazyComponent from '../core/components/router/LazyComponent';
import authRoutes from './authRoutes';

const AccountRoutes = LazyComponent(async () => import('./accountRoutes'));

const userRoutes = [
    { path: 'account/*', element: <AccountRoutes /> },
    ...authRoutes,
];

export default userRoutes;
