import { createContext, useContext } from 'react';

export interface LayoutContextValues {
    /**
     * Viewport is tablet sized
     */
    isInTabletView: boolean;
    /**
     * Viewport is mobile sized
     */
    isInMobileView: boolean;
}

const LayoutContext = createContext<LayoutContextValues>({
    isInMobileView: false,
    isInTabletView: false,
});

export const useLayoutContext = () => {
    return useContext(LayoutContext);
};
export default LayoutContext;
