import { memo } from 'react';

const CookiesImage = memo(() => {
    return (
        <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_8063_20900)">
                <path
                    d="M31.715 13.04C31.634 12.61 31.285 12.284 30.851 12.232C30.416 12.18 30.001 12.415 29.821 12.814C29.504 13.514 28.808 14.001 28.001 14C27.106 14.002 26.35 13.398 26.098 12.569C25.946 12.063 25.426 11.763 24.912 11.883C24.596 11.957 24.297 12 24 12C21.797 11.996 20.004 10.203 20 8C20 7.703 20.043 7.404 20.117 7.089C20.238 6.575 19.937 6.054 19.431 5.902C18.602 5.651 17.998 4.895 18 4C17.999 3.192 18.486 2.497 19.186 2.18C19.585 2 19.82 1.584 19.768 1.15C19.716 0.715 19.39 0.366 18.96 0.285C18.006 0.106 17.018 0 16 0C7.174 0.002 0.002 7.174 0 16C0.002 24.826 7.174 31.998 16 32C24.826 31.998 31.998 24.826 32 16C32 14.982 31.894 13.994 31.715 13.04ZM25.895 25.895C23.356 28.432 19.862 30 16 30C12.138 30 8.644 28.432 6.105 25.895C3.568 23.356 2 19.862 2 16C2 12.138 3.568 8.644 6.105 6.105C8.644 3.568 12.138 2 16 2C16.18 2 16.356 2.019 16.535 2.027C16.201 2.609 16.001 3.279 16 4C16.002 5.493 16.83 6.773 18.037 7.456C18.018 7.634 18 7.812 18 8C18.002 11.311 20.689 13.998 24 14C24.188 14 24.366 13.982 24.544 13.963C25.227 15.169 26.506 15.998 28 16C28.721 15.999 29.391 15.799 29.973 15.465C29.981 15.643 30 15.82 30 16C30 19.862 28.432 23.356 25.895 25.895Z"
                    fill="black"
                />
                <path
                    d="M12 10C13.1046 10 14 9.10457 14 8C14 6.89543 13.1046 6 12 6C10.8954 6 10 6.89543 10 8C10 9.10457 10.8954 10 12 10Z"
                    fill="black"
                />
                <path
                    d="M8 18C9.10457 18 10 17.1046 10 16C10 14.8954 9.10457 14 8 14C6.89543 14 6 14.8954 6 16C6 17.1046 6.89543 18 8 18Z"
                    fill="black"
                />
                <path
                    d="M18 26C19.1046 26 20 25.1046 20 24C20 22.8954 19.1046 22 18 22C16.8954 22 16 22.8954 16 24C16 25.1046 16.8954 26 18 26Z"
                    fill="black"
                />
                <path
                    d="M17 18C17.5523 18 18 17.5523 18 17C18 16.4477 17.5523 16 17 16C16.4477 16 16 16.4477 16 17C16 17.5523 16.4477 18 17 18Z"
                    fill="black"
                />
                <path
                    d="M25 22C25.5523 22 26 21.5523 26 21C26 20.4477 25.5523 20 25 20C24.4477 20 24 20.4477 24 21C24 21.5523 24.4477 22 25 22Z"
                    fill="black"
                />
                <path
                    d="M11 24C11.5523 24 12 23.5523 12 23C12 22.4477 11.5523 22 11 22C10.4477 22 10 22.4477 10 23C10 23.5523 10.4477 24 11 24Z"
                    fill="black"
                />
            </g>
            <defs>
                <clipPath id="clip0_8063_20900">
                    <rect width="32" height="32" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
});

export default CookiesImage;
