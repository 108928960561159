const getCurrentShippingMethods = (
    availableShippingMethods: {
        id: string;
        name: string;
        isDefault?: boolean;
    }[],
    selectedShippingMethod?: string
) => {
    const selectedAvailableShippingMethod = availableShippingMethods.find(
        item => item.id === selectedShippingMethod
    );
    if (selectedAvailableShippingMethod) {
        return selectedAvailableShippingMethod.id;
    }
    const defaultShippingMethod = availableShippingMethods.find(
        item => item.isDefault
    );
    if (defaultShippingMethod) {
        return defaultShippingMethod.id;
    }

    return availableShippingMethods[0]?.id;
};

export default getCurrentShippingMethods;
