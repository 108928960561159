import { gql } from '@apollo/client';
import type {
    CheckoutErrorType,
    DeliveryCharge,
    RawBranch,
    ShippingMethod,
    SmallOrderSurcharge,
} from '../types';
import useStoreAndLanguageParams from '../../core/hooks/useStoreAndLanguageParams';
import type { UserAddress } from '../../user/types';
import useCurrentCartId from '../../cart/hooks/useCurrentCartId';
import usePolledQuery from '../../core/hooks/apollo/usePolledQuery';

export interface CheckoutPriceSummary {
    totalPriceRounding: string;
    totalVat?: string;
    roundedTotalPrice: string;
    totalNetAdditionalCharges: string;
    deliveryCharge?: DeliveryCharge;
    smallOrderSurcharge?: SmallOrderSurcharge;
}

export const checkoutQuery = gql`
    query checkout($language: Language!, $store: ID!, $cartId: Int!) {
        checkout(language: $language, store: $store, cartId: $cartId) {
            branches {
                id
                isMainStock
                nameEN
                nameDE
                nameFR
                nameIT
            }
            shippingMethods {
                id
                name
                isDefault
                price
            }
            isDropShipmentEnabled
            requiresPrepay
            errorCodes
            checkoutPriceSummary {
                totalPriceRounding
                totalVat
                roundedTotalPrice
                totalNetAdditionalCharges
                deliveryCharge {
                    price
                    vatAmount
                }
                smallOrderSurcharge {
                    price
                    vatAmount
                }
            }
        }
        currentCustomer {
            id
            defaultPaymentTermId
            addresses {
                id
                country
                city
                address
                postcode
                name
                companyName
                address2
                isBillingAddress
                isShippingAddress
                isDefaultBillingAddress
                isDefaultShippingAddress
            }
        }
    }
`;

interface CheckoutQueryResultData {
    checkout: {
        branches?: RawBranch[];
        shippingMethods: ShippingMethod[];
        isDropShipmentEnabled?: boolean;
        requiresPrepay?: boolean;
        errorCodes: CheckoutErrorType[];
        checkoutPriceSummary: CheckoutPriceSummary;
    };
    currentCustomer: {
        addresses: UserAddress[];
        defaultPaymentTermId?: string;
    };
}

const useCheckoutQuery = () => {
    const { store, language } = useStoreAndLanguageParams();
    const cartId = useCurrentCartId();

    const { data, error, loading } = usePolledQuery<CheckoutQueryResultData>(
        checkoutQuery,
        {
            variables: {
                store,
                language,
                cartId,
            },
            pollInterval: 180_000,
            fetchPolicy: 'network-only',
            nextFetchPolicy: 'cache-first',
            errorPolicy: 'all',
            notifyOnNetworkStatusChange: true,
            skip: !cartId,
        }
    );

    return { initialized: !!data, loading, data, error };
};

export default useCheckoutQuery;
