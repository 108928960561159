import { memo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Text } from '@library';
import FlexLayout from '../../layout/FlexLayout';
import MenuIcon from '../../icons/MenuIcon';
import Button from '../../Button';
import SidePane from '../../SidePane';
import CompanyLogo from '../../CompanyLogo';
import styles from './SidePaneCategoryMenu.module.scss';
import SidePaneCategoryMenuContent from './SidePaneCategoryMenuContent';

const SidePanLogoHeader = memo(() => {
    //  TODO GET LOGO FROM STORYBLOk
    return (
        <div className={styles.logo}>
            <CompanyLogo />
        </div>
    );
});
const CategorySidePane = memo(({ onClose }: { onClose: () => void }) => {
    return (
        <SidePane onClose={onClose} header={<SidePanLogoHeader />}>
            <SidePaneCategoryMenuContent onClose={onClose} />
        </SidePane>
    );
});
const SidePaneCategoryMenu = () => {
    const [show, setShow] = useState(false);

    return (
        <>
            <Button
                textOnly
                noPadding
                autoSize
                onPress={() => {
                    setShow(true);
                }}
            >
                <div className={styles.triggerButton}>
                    <FlexLayout gap="mini" align="center">
                        <FlexLayout align="center" gap="none">
                            <MenuIcon />
                        </FlexLayout>
                        <Text secondary medium>
                            <FormattedMessage id="all categories" />
                        </Text>
                    </FlexLayout>
                </div>
            </Button>
            {show && (
                <CategorySidePane
                    onClose={() => {
                        setShow(false);
                    }}
                />
            )}
        </>
    );
};
export default memo(SidePaneCategoryMenu);
