import { useIntl } from 'react-intl';
import useStoreAndLanguageParams from '../../core/hooks/useStoreAndLanguageParams';
import type Category from '../../core/types/category';

// eslint-disable-next-line max-statements
const useSubmitSearchData = ({
    brands,
    categories,
    products,
    query,
    loading = false,
}: {
    query: string;
    products: { id: string }[];
    brands: {
        name: string;
        logo?: string;
        slug: string;
    }[];
    categories: Pick<Category, 'id' | 'name'>[];
    loading?: boolean;
}) => {
    const intl = useIntl();
    const { storeRootPath } = useStoreAndLanguageParams();
    const categoryLink = `${storeRootPath}/categories/${categories[0]?.id}/page/1`;

    let targetUrl = `${storeRootPath}/search?q=${query}`;
    let submitButtonMessage = intl.formatMessage(
        {
            id: 'show all results for {searchTerm}',
        },
        {
            searchTerm: `'${query}'`,
        }
    );

    const counts = {
        brands: brands.length,
        categories: categories.length,
        products: products.length,
    };

    const totalCount = counts.brands + counts.categories + counts.products;

    if (loading || !totalCount) {
        return [targetUrl, submitButtonMessage];
    }

    if (counts.products === 1) {
        const [product] = products;
        targetUrl = `${storeRootPath}/product/${product.id}`;
    }

    if (!counts.products) {
        if (!counts.brands) {
            targetUrl = categoryLink;
            submitButtonMessage = intl.formatMessage(
                {
                    id: 'show category results for {searchTerm}',
                },
                {
                    searchTerm: `'${query}'`,
                }
            );
        } else if (!counts.categories) {
            const [brand] = brands;
            targetUrl = `${storeRootPath}/brand/${brand.slug}`;
            submitButtonMessage = intl.formatMessage(
                {
                    id: 'show brand results for {searchTerm}',
                },
                {
                    searchTerm: `'${query}'`,
                }
            );
        }
    }

    return [targetUrl, submitButtonMessage];
};

export default useSubmitSearchData;
