import {
    memo,
    createContext,
    useContext,
    useMemo,
    useRef,
    useState,
} from 'react';
import type { ReactNode } from 'react';
import assert from '../../core/util/assert';
import useObservableVariableState from '../../core/hooks/useObservableVariableState';
import ConsentBanner from '../components/ConsentBanner';
import ConsentSettings from '../components/ConsentSettings';
import CookieConsentService from './CookieConsentService';

interface CookieConsentContextValue {
    service: CookieConsentService;
    settingsOpened: boolean;
    setSettingsOpened: (value: boolean) => void;
}

const CookieConsentContext = createContext<Partial<CookieConsentContextValue>>(
    {}
);

export const CookieConsentProvider = memo(
    ({ children }: { children: ReactNode }) => {
        const service = useRef(new CookieConsentService());
        const [settingsOpened, setSettingsOpened] = useState(false);

        const value: CookieConsentContextValue = useMemo(
            () => ({
                service: service.current,
                settingsOpened,
                setSettingsOpened,
            }),
            [service, settingsOpened]
        );
        return (
            <CookieConsentContext.Provider value={value}>
                {children}
                <ConsentBanner />
                <ConsentSettings
                    open={settingsOpened}
                    onOpenChange={setSettingsOpened}
                />
            </CookieConsentContext.Provider>
        );
    }
);

export const useCookieConsentContext = () => {
    const context = useContext(CookieConsentContext);

    assert(
        context.service,
        'Please check that CookieConsent is present in the tree'
    );

    return context as CookieConsentContextValue;
};

export const useCookieConsent = () => {
    const { service } = useCookieConsentContext();
    const [consent] = useObservableVariableState(service.consentVariable);
    const [initialized] = useObservableVariableState(
        service.initializedVariable
    );
    return {
        consent,
        initialized,
    };
};

export const useCookieConsentActions = () => {
    const { service } = useCookieConsentContext();
    const [acceptAllProgress] = useObservableVariableState(
        service.acceptAllProgress
    );
    const [declineAllProgress] = useObservableVariableState(
        service.declineAllProgress
    );
    const [acceptProgress] = useObservableVariableState(service.acceptProgress);
    return {
        acceptAll: service.acceptAll,
        acceptAllProgress,
        declineAll: service.declineAll,
        declineAllProgress,
        accept: service.accept,
        acceptProgress,
    };
};
