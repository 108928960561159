import { useContext } from 'react';
import type { CartPriceTotals } from '../types';
import { CartContext } from '../../core/cartContext';
import RemoteCart from '../services/RemoteCart';
import useCartPriceSummary from './useCartPriceSummary';
import useLocalCartPriceSummary from './useLocalCartPriceSummary';

const useCartTotalPrice = (): CartPriceTotals => {
    const { cart } = useContext(CartContext);
    const remoteCartPriceSummary = useCartPriceSummary({ cartId: cart.id });
    const localCartPriceSummary = useLocalCartPriceSummary();

    if (cart instanceof RemoteCart) {
        return remoteCartPriceSummary;
    }

    return localCartPriceSummary;
};

export default useCartTotalPrice;
