/**
 * Merge paginated results in the cache
 * See https://www.apollographql.com/docs/react/pagination/core-api/#improving-the-merge-function
 */
const mergePaginatedResults = <T = unknown>(
    existing: { items?: T[]; total: number } | null,
    incoming: { items?: T[]; total: number },
    { args }: { args: { offset?: number } | null }
) => {
    if (!incoming.items) {
        return { ...existing, ...incoming };
    }
    const offset = args?.offset ?? 0;
    const merged = existing?.items ? existing.items.slice(0) : [];
    for (let i = 0; i < incoming.items.length; ++i) {
        merged[offset + i] = incoming.items[i];
    }
    return { items: merged, total: incoming.total };
};

export default mergePaginatedResults;
