import { memo, useMemo, useState, useEffect } from 'react';
import type { ReactNode } from 'react';
import usePriceCacheKey from '../../queries/usePriceCacheKey';
import useCategories from '../../queries/useCategories';
import useCurrentStore from '../../hooks/useCurrentStore';
import useCurrentUser from '../../hooks/useCurrentUser';
import useAuth from '../../hooks/useAuth';
import { AppContext } from '../../context';
import useTabletViewMatchMedia from '../../hooks/dom/useTabletViewMatchMedia';
import initSentry from '../../../main/initSentry';
import useMobileViewMatchMedia from '../../hooks/dom/useMobileViewMatchMedia';

const AppProvider = ({ children }: { children: ReactNode }) => {
    const [showCustomerPrice, setShowCustomerPrice] = useState(
        !localStorage.getItem('hideCustomerPrice')
    );

    const {
        login,
        logout,
        isLoggedIn,
        setIsRestrictedPage,
        isSentryInitialized,
    } = useAuth();
    const currentUser = useCurrentUser();
    const store = useCurrentStore();

    const isImpersonating = currentUser?.impersonating ?? false;
    const customerPriceCacheKey = usePriceCacheKey(isLoggedIn, isImpersonating);

    useCategories(true);
    useEffect(() => {
        (async () => {
            const sentry = await initSentry();

            if (sentry) {
                if (currentUser) {
                    sentry.setUser({
                        id: currentUser.customerNumber,
                        username: currentUser.username,
                    });
                } else {
                    sentry.setUser(null);
                }
            }

            isSentryInitialized.current = true;
        })().catch(e => {
            // Not important enough to throw an exception
            // eslint-disable-next-line no-console
            console.error(e);
        });
    }, [currentUser, isSentryInitialized]);

    const isInTabletView = useTabletViewMatchMedia();
    const isInMobileView = useMobileViewMatchMedia();

    const contextValue = useMemo(() => {
        return {
            isLoggedIn: isLoggedIn && !!currentUser,
            logout,
            login,
            setRestrictedPage: setIsRestrictedPage,
            isImpersonating,
            currency: store.currency,
            customerPriceCacheKey,
            showCustomerPrice: currentUser?.dealerPricesEnabled
                ? showCustomerPrice
                : false,
            setShowCustomerPrice: (showPrice: boolean) => {
                if (showPrice) {
                    localStorage.removeItem('hideCustomerPrice');
                } else {
                    localStorage.setItem('hideCustomerPrice', '1');
                }

                setShowCustomerPrice(showPrice);
            },
            isInTabletView,
            isInMobileView,
        };
    }, [
        isLoggedIn,
        currentUser,
        logout,
        login,
        setIsRestrictedPage,
        isImpersonating,
        store.currency,
        customerPriceCacheKey,
        showCustomerPrice,
        isInTabletView,
        isInMobileView,
    ]);
    return (
        <AppContext.Provider value={contextValue}>
            {children}
        </AppContext.Provider>
    );
};

export default memo(AppProvider);
