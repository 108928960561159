import { memo, useEffect, useMemo, useState } from 'react';
import { useMatch } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { HStack, Text } from '@library';
import useCategories from '../queries/useCategories';
import useStoreAndLanguageParams from '../hooks/useStoreAndLanguageParams';
import type { StockListSchema } from '../queries/useStockLists';
import useStockLists from '../queries/useStockLists';
import useCategory from '../queries/useCategory';
import useConsistentReference from '../hooks/useConsistentReference';
import styles from './Header.module.scss';
import { SmallProgressIndicator } from './ProgressIndicators';
import HeaderCategoryItem from './HeaderCategoryItem';
import CategoryDropdown from './CategoryDropdown';

const getCategoryAncestryIds = (
    categoryAncestryIds: string[],
    selectedCategoryId?: string
) => {
    if (!selectedCategoryId) {
        return [];
    }
    return categoryAncestryIds.length === 0
        ? [selectedCategoryId]
        : categoryAncestryIds;
};

const useActiveCategoryPath = () => {
    const { storeRootPath } = useStoreAndLanguageParams();
    const match = useMatch(`${storeRootPath}/categories/:categoryId/*`);
    const { categoryId: selectedCategoryId, categoryAncestryIds } = useCategory(
        match?.params.categoryId
    );

    const activePath = getCategoryAncestryIds(
        categoryAncestryIds,
        selectedCategoryId
    );

    return {
        activeCategoryPath: useConsistentReference(activePath),
    };
};

const HeaderCategories = () => {
    const { categories, loading } = useCategories();

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [hoverId, setHoverId] = useState<string>('');
    const [nodeId, setNodeId] = useState<string>('');

    const [isDropdownOpenDebounced, setIsDropdownOpenDebounced] =
        useState(false);
    const { data } = useStockLists();
    const { activeCategoryPath } = useActiveCategoryPath();

    const stockListItemsMap = useMemo(() => {
        const empty = {} as Record<string, StockListSchema[]>;
        return (
            data?.StocklistsItems.items.reduce((map, item) => {
                return {
                    ...map,
                    [item.content.categoryId]: item.content.stocklists,
                };
            }, empty) || empty
        );
    }, [data?.StocklistsItems.items]);

    useEffect(() => {
        if (isDropdownOpen) {
            const handler = setTimeout(() => {
                setIsDropdownOpenDebounced(isDropdownOpen);
            }, 200);

            return () => {
                clearTimeout(handler);
            };
        }
        setIsDropdownOpenDebounced(isDropdownOpen);
        return undefined;
    }, [isDropdownOpen]);

    const onDropdownClose = () => {
        setIsDropdownOpen(false);
    };

    if (loading) {
        return (
            <div className={styles.categoriesLoading}>
                <SmallProgressIndicator />
            </div>
        );
    }

    return (
        <div
            role="menu"
            onMouseLeave={onDropdownClose}
            onBlur={onDropdownClose}
            data-test-class="header-categories"
        >
            <HStack gap="medium">
                {categories.length > 0 ? (
                    categories
                        .filter(({ parentId }) => !parentId)
                        .map(item => (
                            <HeaderCategoryItem
                                stockListItemsMap={stockListItemsMap}
                                key={item.id}
                                node={item}
                                setHoverId={setHoverId}
                                setNodeId={setNodeId}
                                setIsDropdownOpen={setIsDropdownOpen}
                                activeCategoryPath={activeCategoryPath}
                            />
                        ))
                ) : (
                    <Text primary>
                        <span className={styles.emptyCategoriesMessage}>
                            <FormattedMessage id="No categories available" />
                        </span>
                    </Text>
                )}
            </HStack>
            {isDropdownOpenDebounced && isDropdownOpen && (
                <CategoryDropdown
                    stockLists={stockListItemsMap[hoverId]}
                    nodeId={nodeId}
                    categoryId={hoverId}
                    setNodeId={setNodeId}
                    onClose={onDropdownClose}
                />
            )}
        </div>
    );
};

export default memo(HeaderCategories);
