import { memo } from 'react';
import useFooterData from '../../queries/useFooterData';
import LazyComponent from '../router/LazyComponent';
import ComponentSuspense from '../router/ComponentSuspense';
import withErrorBoundary from '../errors/withErrorBoundary';

const Footer = LazyComponent(async () => import('./Footer'));

const StoreFooter = () => {
    const { storyblokFooter, isStoryblokLoaded } = useFooterData();

    if (!isStoryblokLoaded) {
        return null;
    }

    if (storyblokFooter) {
        return (
            <ComponentSuspense height="470px">
                <Footer {...storyblokFooter} />
            </ComponentSuspense>
        );
    }

    return null;
};

export default memo(withErrorBoundary(StoreFooter));
