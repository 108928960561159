import { gql, useMutation } from '@apollo/client';
import { getOperationName } from '@apollo/client/utilities';
import { useState } from 'react';
import type {
    CustomDiscountInput,
    MutationSaveCartDiscountsArgs,
} from '@apiTypes';
import useImmutableCallback from '../../core/hooks/useImmutableCallback';
import notEmpty from '../../core/util/notEmpty';
import useCurrentCartId from '../../cart/hooks/useCurrentCartId';
import usePriceCache from './usePriceCache';
import { cartDiscountsQuery } from './useCartDiscounts';

const saveCustomerPreorderPricingMutation = gql`
    mutation saveCustomerDiscounts(
        $id: Int!
        $discounts: [CustomDiscountInput!]!
    ) {
        saveCartDiscounts(id: $id, discounts: $discounts)
    }
`;

const useSaveCartDiscounts = () => {
    const cartId = useCurrentCartId();
    const [isUpdating, setIsUpdating] = useState(false);

    const [mutation, options] = useMutation<MutationSaveCartDiscountsArgs>(
        saveCustomerPreorderPricingMutation
    );

    const { resetPrices } = usePriceCache();

    const saveCustomDiscount = useImmutableCallback(
        async (discounts: CustomDiscountInput[]) => {
            try {
                setIsUpdating(true);
                await mutation({
                    variables: {
                        discounts,
                        id: cartId,
                    },
                    refetchQueries: [
                        getOperationName(cartDiscountsQuery),
                    ].filter(notEmpty),
                    awaitRefetchQueries: true,
                });
                await resetPrices(true);
            } finally {
                setIsUpdating(false);
            }
        }
    );
    return { saveCustomDiscount, ...options, loading: isUpdating };
};

export default useSaveCartDiscounts;
