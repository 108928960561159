const YAMAHA_STORAGE_KEY = 'YhEPCartStorage';

export const saveYhEPFormToStorage = (fields: [string, string][]) => {
    localStorage.setItem(YAMAHA_STORAGE_KEY, JSON.stringify(fields));
};

export const getYhEPFormFromStorage = () => {
    const value = localStorage.getItem(YAMAHA_STORAGE_KEY);
    if (value) {
        return JSON.parse(value) as [string, string][];
    }
    return null;
};

export const removeYhEPFormFromStorage = () => {
    localStorage.removeItem(YAMAHA_STORAGE_KEY);
};
