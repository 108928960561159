import { memo } from 'react';
import useStockListCategories from '../../../queries/useStockListCategories';
import { SmallProgressIndicator } from '../../ProgressIndicators';
import SidePaneMenuLink from '../SidePaneMenuLink';

const SidePaneStockListItems = ({
    stockList,
    onClick,
}: {
    stockList: {
        name: string;
        to: string;
        categoryId: string;
        stockListParams: string;
        filters: {
            attribute: string;
            value: string;
        }[];
    };
    onClick: () => void;
}) => {
    const { items, loading } = useStockListCategories({
        filters: stockList.filters,
        categoryId: stockList.categoryId,
    });

    return (
        <>
            {loading ? (
                <SmallProgressIndicator />
            ) : (
                items?.map(item => {
                    return (
                        <SidePaneMenuLink
                            key={item.id}
                            onClick={onClick}
                            to={`/categories/${item.id}/page/1?${stockList.stockListParams}`}
                        >
                            {item.name}
                        </SidePaneMenuLink>
                    );
                })
            )}
        </>
    );
};

export default memo(SidePaneStockListItems);
