import { memo, Children } from 'react';
import type { ReactNode } from 'react';
import { clsx } from 'clsx';
import styles from './FlexLayout.module.scss';

export const alignmentValueMap = {
    start: 'flex-start',
    end: 'flex-end',
    center: 'center',
    stretch: 'stretch',
    normal: 'normal',
    baseline: 'baseline',
};

export const justifyValueMap = {
    start: 'flex-start',
    end: 'flex-end',
    spaceBetween: 'space-between',
    center: 'center',
    normal: 'normal',
};

export const wrapMap: Record<string, 'wrap' | 'nowrap'> = {
    wrap: 'wrap',
    noWrap: 'nowrap',
};

const StandardColumns = memo(
    ({ children, width }: { children: ReactNode; width?: string | number }) => {
        return (
            <>
                {Children.map(children, child => (
                    <div style={{ width }}>{child}</div>
                ))}
            </>
        );
    }
);

const FlexLayout = ({
    children,
    gap = 'none',
    // TODO: make this undefined and not do anything as default
    align = 'normal',
    // TODO: make this undefined and not do anything as default
    justify = 'normal',
    wrap,
    /* TODO: Remove, see comments in: FlexLayout.module.scss */
    standardColumns = false,
    direction = 'row' /* good, but we might want to have different components exposed */,
    /* TODO: Remove */
    fullWidth = false,
    /* TODO: Remove */
    fullHeight = false,
    /* TODO: Remove */
    columnWidth,
    ...ariaProps
}: {
    children: ReactNode;
    align?: keyof typeof alignmentValueMap;
    justify?: keyof typeof justifyValueMap;
    standardColumns?: boolean;
    wrap?: keyof typeof wrapMap;
    // TODO: Remove custom
    gap?: 'none' | 'micro' | 'mini' | 'small' | 'medium' | 'large' | 'custom';
    direction?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
    fullWidth?: boolean;
    fullHeight?: boolean;
    columnWidth?: string | number;
}) => {
    const defaultWrap =
        direction === 'column' || direction === 'column-reverse'
            ? 'noWrap'
            : 'wrap';
    return (
        <div
            /* TODO: convert to classes */
            style={{
                justifyContent: justifyValueMap[justify],
                alignItems: alignmentValueMap[align],
                flexWrap: wrapMap[wrap || defaultWrap],
                flexDirection: direction,
            }}
            className={clsx(styles.flexLayout, {
                [styles[`${gap}Gap`]]: gap !== 'none',
                [styles.fullWidth]: fullWidth,
                [styles.fullHeight]: fullHeight,
            })}
            {...ariaProps}
        >
            {standardColumns ? (
                <StandardColumns width={columnWidth}>
                    {children}
                </StandardColumns>
            ) : (
                children
            )}
        </div>
    );
};
/**
 * @deprecated
 * Please use VStack or HStack depends on needed direction
 */
export default memo(FlexLayout);
