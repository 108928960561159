import { gql } from '@apollo/client';
import { useContext } from 'react';
import type { Language } from '../../core/types/language';
import useCategories from '../../core/queries/useCategories';
import useCancelableQuery from '../../core/hooks/apollo/useCancelableQuery';
import PreorderModeContext from '../../preorder/PreorderModeContext';

const useCategoriesSearch = (
    store: string,
    language: Language,
    search: string
) => {
    const { categories, loading: categoriesLoading } = useCategories();
    const { productsFilters } = useContext(PreorderModeContext);

    const { data, loading, error, ...queryData } = useCancelableQuery<{
        categories: {
            items: {
                id: string;
                name: string;
            }[];
        };
    }>(
        gql`
            query categoriesSearch(
                $store: ID!
                $language: Language!
                $search: String
                $preorderSeasonId: Int
                $includePreorderSeasonId: Int
            ) {
                categories(
                    store: $store
                    language: $language
                    search: $search
                    preorderSeasonId: $preorderSeasonId
                    includePreorderSeasonId: $includePreorderSeasonId
                ) {
                    items {
                        id
                        key
                        name
                        path {
                            key
                            id
                            name
                            hiddenInTree
                        }
                    }
                }
            }
        `,
        {
            variables: {
                store,
                language,
                search,
                ...productsFilters,
            },
            skip: search.length < 2,
        },
        true
    );

    const filteredData = data
        ? {
              ...data,
              categories: {
                  ...data.categories,
                  items: data.categories.items.filter(item =>
                      categories.find(category => category.id === item.id)
                  ),
              },
          }
        : data;

    return {
        ...queryData,
        loading: loading || categoriesLoading,
        data: filteredData,
        error,
    };
};

export default useCategoriesSearch;
