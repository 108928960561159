import { memo, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import SidePaneMenuLink from '../SidePaneMenuLink';
import SidePaneMenuItem from '../SidePaneMenuItem';
import Divider from '../../Divider';
import type { StockListSchema } from '../../../queries/useStockLists';
import useStockLists from '../../../queries/useStockLists';
import getStockListUrl from '../../../util/getStocklistUrl';
import useSalePage from '../../../queries/useSalePage';
import useCategoriesTree from '../../../../brand/queries/useCategoriesTree';
import flattenTree from '../../../../brand/util/flattenTree';
import useLoginState from '../../../hooks/useLoginState';
import RestrictedContent from '../../router/RestrictedContent';
import StockListIcon from '../../icons/StockListIcon';
import FlexLayout from '../../layout/FlexLayout';
import withErrorBoundary from '../../errors/withErrorBoundary';
import SidePaneStockListItems from './SidePaneStocklistItems';
import CurrentItem from './CurrentItem';
import MenuLink from './MenuLink';
import type { MenuItem } from './types';
import styles from './SidePaneCategoryMenu.module.scss';

const MenuItemOrLink = memo(
    ({
        item,
        onClick,
        onClose,
    }: {
        item: MenuItem;
        onClick: (item: MenuItem) => void;
        onClose: () => void;
    }) => {
        const { name } = item;

        if (item.childrenExist) {
            return (
                <SidePaneMenuItem
                    onClick={() => onClick(item)}
                    icon="rightIcon"
                >
                    <FlexLayout gap="small">
                        <div>{name}</div>
                        <div>
                            {item.categoryId && !item.parentId && (
                                <StockListIcon />
                            )}
                        </div>
                    </FlexLayout>
                </SidePaneMenuItem>
            );
        }

        return <MenuLink item={item} onClick={onClose} />;
    }
);

const transformStockListToMenuItems = (
    stockListName: string,
    stockListItems: {
        id: string;
        content: {
            stocklists: StockListSchema[];
            categoryId: string;
        };
    }[]
) => {
    return stockListItems.reduce((res, stockList) => {
        res.push({
            type: 'item',
            name: stockListName,
            id: stockList.id,
            childrenExist: !!stockList.content.stocklists.length,
            to: '',
            parentId: null,
            categoryId: stockList.content.categoryId,
        });
        const items = stockList.content.stocklists.map(item => {
            const { targetPath, paramsStr } = getStockListUrl(
                stockList.content.categoryId,
                item.filters
            );
            const { _uid: id } = item;
            return {
                type: 'stockList',
                name: item.title,
                id,
                parentId: stockList.id,
                categoryId: stockList.content.categoryId,
                childrenExist: true,
                to: targetPath,
                stockListParams: paramsStr,
                filters: item.filters,
            } as MenuItem;
        });
        return [...res, ...items];
    }, [] as MenuItem[]);
};

const SideMenuSalePageItem = withErrorBoundary(
    memo(({ onClose }: { onClose(): void }) => {
        const { data } = useSalePage();

        if (!data) {
            return null;
        }

        return (
            <RestrictedContent loggedInOnly>
                <SidePaneMenuLink
                    to="/sale"
                    onClick={onClose}
                    messageId="sales page header"
                />
            </RestrictedContent>
        );
    })
);

const FixedMenuItems = memo(({ onClose }: { onClose: () => void }) => {
    return (
        <>
            <SidePaneMenuLink
                to="/brands"
                onClick={onClose}
                messageId="brands"
            />
            <RestrictedContent storeFlags="sparePartSearchEnabled" loggedInOnly>
                <SidePaneMenuLink
                    to="/spare-parts"
                    onClick={onClose}
                    messageId="spare parts search"
                />
            </RestrictedContent>
            <RestrictedContent stores={['ch']}>
                <SidePaneMenuLink
                    to="/motorcycle-search"
                    onClick={onClose}
                    messageId="motorcycle search"
                />
            </RestrictedContent>
            <SideMenuSalePageItem onClose={onClose} />
            <RestrictedContent storeFlags="dealerSearchEnabled" loggedOutOnly>
                <SidePaneMenuLink
                    to="/dealers"
                    onClick={onClose}
                    messageId="dealer locator"
                />
            </RestrictedContent>
        </>
    );
});

const SidePaneCategoryMenuContent = ({ onClose }: { onClose: () => void }) => {
    const { tree } = useCategoriesTree();

    const categories: MenuItem[] = useMemo(() => {
        return flattenTree(tree).map(item => {
            return {
                ...item,
                to: `/categories/${item.id}/page/1`,
                childrenExist: !!item.children.length,
                type: 'item',
            };
        });
    }, [tree]);

    const isLoggedIn = useLoginState();

    const { data: stockListData } = useStockLists(!isLoggedIn);

    const intl = useIntl();
    const stockListItems = useMemo(() => {
        return transformStockListToMenuItems(
            intl.formatMessage({ id: 'stock list' }),
            stockListData?.StocklistsItems.items ?? []
        );
    }, [intl, stockListData]);

    const [currentMenuItem, setCurrentMenuItem] = useState<
        MenuItem | undefined
    >(undefined);

    const onOpen = (category: MenuItem) => {
        setCurrentMenuItem(category);
    };

    const allMenuItems = useMemo(() => {
        return categories.reduce((result, category) => {
            if (!category.parentId) {
                const stockList = stockListItems.find(
                    item => item.categoryId === category.id
                );
                if (stockList) {
                    return [
                        ...result,
                        category,
                        stockList,
                        ...stockListItems.filter(
                            item => item.parentId === stockList.id
                        ),
                    ];
                }
            }
            return [...result, category];
        }, [] as MenuItem[]);
    }, [categories, stockListItems]);

    const parentMenuItem = useMemo(() => {
        return (
            currentMenuItem &&
            allMenuItems.find(
                category => category.id === currentMenuItem.parentId
            )
        );
    }, [allMenuItems, currentMenuItem]);

    const currentMenuItems = useMemo(() => {
        if (currentMenuItem) {
            return allMenuItems.filter(
                ({ parentId }) => parentId === currentMenuItem.id
            );
        }
        return allMenuItems.filter(({ parentId }) => !parentId);
    }, [currentMenuItem, allMenuItems]);

    const comeBack = () => {
        setCurrentMenuItem(parentMenuItem);
    };

    return (
        <div>
            {currentMenuItem ? (
                <CurrentItem
                    comeBack={comeBack}
                    currentCategory={currentMenuItem}
                    parentCategory={parentMenuItem}
                    onClose={onClose}
                />
            ) : (
                <div className={styles.divider}>
                    <Divider light lengthPercentage={100} />
                </div>
            )}

            {currentMenuItem?.type === 'stockList' ? (
                <SidePaneStockListItems
                    stockList={currentMenuItem}
                    onClick={onClose}
                />
            ) : (
                currentMenuItems.map(item => (
                    <MenuItemOrLink
                        key={item.id}
                        item={item}
                        onClick={onOpen}
                        onClose={onClose}
                    />
                ))
            )}

            {!currentMenuItem && <FixedMenuItems onClose={onClose} />}
        </div>
    );
};

export default memo(SidePaneCategoryMenuContent);
