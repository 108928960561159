import initSentry from '../../main/initSentry';

const captureException = (exception: unknown) => {
    setTimeout(() => {
        throw exception;
    }, 0);

    (async () => {
        const sentry = await initSentry();

        if (sentry) {
            sentry.withScope(scope => {
                sentry.captureException(exception, scope);
            });
        }
    })().catch(e => {
        // eslint-disable-next-line no-console
        console.error(e);
    });
};

export default captureException;
