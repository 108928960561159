import { memo } from 'react';
import { ErrorAlertBanner, Button } from '@library';
import { clearAccessToken } from '../util/auth/credentialsStorage';

const IncorrectConfigurationMessage = () => (
    <div>
        <ErrorAlertBanner>
            {`Incorrect configuration. This site is locked to ${APP_ENV.STORE} and Canonical store URL is disabled. Such configuration doesn't support users with different store than ${APP_ENV.STORE} `}
        </ErrorAlertBanner>
        <Button
            onClick={() => {
                clearAccessToken();
                window.location.reload();
            }}
        >
            Logout
        </Button>
    </div>
);

export default memo(IncorrectConfigurationMessage);
