import { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import SidePaneMenuItem from '../SidePaneMenuItem';
import Divider from '../../Divider';
import styles from './SidePaneCategoryMenu.module.scss';
import MenuLink from './MenuLink';
import type { MenuItem } from './types';

const CurrentItem = ({
    comeBack,
    parentCategory,
    currentCategory,
    onClose,
}: {
    currentCategory: MenuItem;
    parentCategory?: MenuItem;
    comeBack(): void;
    onClose(): void;
}) => (
    <div>
        <div className={styles.backItem}>
            <SidePaneMenuItem onClick={comeBack} icon="leftIcon">
                {parentCategory ? (
                    <FormattedMessage
                        id="back to {direction}"
                        values={{ direction: parentCategory.name }}
                    />
                ) : (
                    <FormattedMessage id="back to overview" />
                )}
            </SidePaneMenuItem>
        </div>

        <div className={styles.dividerBack}>
            <Divider light lengthPercentage={100} />
        </div>

        <div className={styles.parent}>
            {currentCategory.to.length ? (
                <MenuLink item={currentCategory} parent onClick={onClose} />
            ) : (
                <SidePaneMenuItem medium>
                    {currentCategory.name}
                </SidePaneMenuItem>
            )}
        </div>
    </div>
);

export default memo(CurrentItem);
