import { memo } from 'react';
import { clsx } from 'clsx';
import { Text } from '@library';
import useLoginState from '../hooks/useLoginState';
import type { StockListSchema } from '../queries/useStockLists';
import type Category from '../types/category';
import styles from './Header.module.scss';
import StockListIcon from './icons/StockListIcon';
import CurrentStoreLink from './router/CurrentStoreLink';

const HeaderCategoryItem = ({
    node,
    setNodeId,
    stockListItemsMap,
    setHoverId,
    setIsDropdownOpen,
    activeCategoryPath,
}: {
    node: Omit<Category, 'children'>;
    stockListItemsMap: Record<string, StockListSchema[] | undefined>;
    setHoverId: (hoverId: string) => void;
    setNodeId: (nodeId: string) => void;
    setIsDropdownOpen: (isDropdownOpen: boolean) => void;
    activeCategoryPath: string[];
}) => {
    const isLoggedIn = useLoginState();
    const { id, name } = node;

    const isCategoryActive = activeCategoryPath.includes(id);

    const targetPath = `/categories/${id}/page/1`;
    const showStocklist = stockListItemsMap[node.id] && isLoggedIn;

    return (
        <>
            <CurrentStoreLink
                data-test-class="header-category-links"
                className={clsx(styles.navCategoryLink, {
                    [styles.navCategoryLinkActive]: isCategoryActive,
                    [styles.stockListIcon]: showStocklist,
                })}
                to={targetPath}
                onMouseEnter={() => {
                    setIsDropdownOpen(true);
                    setHoverId(id);
                    setNodeId('');
                }}
                onClick={() => setIsDropdownOpen(false)}
                key={id}
            >
                <Text
                    primary
                    light={!isCategoryActive}
                    medium={isCategoryActive}
                >
                    <span
                        data-text={name}
                        className={styles.navCategoryLinkText}
                    >
                        {name}
                    </span>
                </Text>
            </CurrentStoreLink>
            {showStocklist && (
                <div
                    role="menu"
                    data-test-class="stockListDropdownTrigger"
                    className={styles.stockList}
                    onMouseEnter={() => {
                        setIsDropdownOpen(true);
                        setHoverId(node.id);
                        setNodeId('stock-list');
                    }}
                >
                    <StockListIcon />
                </div>
            )}
        </>
    );
};

export default memo(HeaderCategoryItem);
