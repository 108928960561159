import { useEffect, useState } from 'react';
import useEventListener from '../../core/hooks/dom/useEventListener';
import useImmutableCallback from '../../core/hooks/useImmutableCallback';
import useVisibilityStatus from '../../core/hooks/dom/useVisibilityStatus';

/*
  Lazy Observe changes in local storage.
  Callback will be fired when user reopen tab/window and local storage key is changed
 */
const useObserveStorageChangesLazy = (key: string, callback: () => void) => {
    const [isNeedCheck, setIsNeedCheck] = useState(false);
    const isVisible = useVisibilityStatus(() => {
        setIsNeedCheck(true);
    });

    const [isChanged, setIsChanged] = useState(false);
    useEventListener({
        element: window,
        type: 'storage',
        listener: event => {
            if (event.key === key) {
                setIsChanged(true);
            }
        },
    });

    const memoCallback = useImmutableCallback(callback);

    useEffect(() => {
        if (isVisible && isNeedCheck && isChanged) {
            memoCallback();
            setIsNeedCheck(false);
            setIsChanged(false);
        }
    }, [isVisible, isNeedCheck, isChanged, memoCallback]);
};

export default useObserveStorageChangesLazy;
