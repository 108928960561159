import { memo } from 'react';
import type { CSSProperties } from 'react';
import { clsx } from 'clsx';
import styles from './Divider.module.scss';

const Divider = ({
    vertical = false,
    dark = false,
    light = false,
    light9 = false,
    secondaryLight = false,
    lengthPercentage = 90,
}) => {
    return (
        <div
            style={
                {
                    '--length-percentage': `${lengthPercentage}%`,
                } as CSSProperties
            }
            className={clsx({
                [styles.divider]: !vertical,
                [styles.verticalDivider]: vertical,
                [styles.dark]: dark,
                [styles.light]: light,
                [styles.light9]: light9,
                [styles.secondaryLight]: secondaryLight,
            })}
        />
    );
};

export default memo(Divider);
