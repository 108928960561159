import { createContext } from 'react';
import type { CurrentUserQuery, User } from '../../user/types';

export interface UserContextValue {
    user?: User;
    customer?: CurrentUserQuery['currentCustomer'];
    accessToken?: string;
    setAccessToken(
        token?: string,
        persist?: boolean,
        skipStorageChanges?: boolean
    ): void;
}

const UserContext = createContext<UserContextValue>(
    {} as unknown as UserContextValue
);

export default UserContext;
