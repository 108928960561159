import { memo } from 'react';
import { Outlet, ScrollRestoration } from 'react-router-dom';
import { LayoutProvider, Toaster } from '@library';
import ErrorBoundary from '../../core/components/ErrorBoundary';
import AppProvider from '../../core/components/providers/AppProvider';
import IntlStoreProvider from '../../core/components/providers/IntlStoreProvider';
import UpdateNotifier from '../../core/components/UpdateNotifier';
import StoreProvider from '../../core/contexts/StoreProvider';
import { CookieConsentProvider } from '../../cookies/context/CookieConsentContext';
import { useSystemMaintenancePolled } from '../queries/useSystemMaintenances';
import MaintenanceMode from './MaintenanceMode/MaintenanceMode';

const AppProviders = memo(() => {
    useSystemMaintenancePolled();
    return (
        <ErrorBoundary global>
            <StoreProvider>
                <IntlStoreProvider>
                    <MaintenanceMode>
                        <LayoutProvider>
                            <UpdateNotifier />
                            <AppProvider>
                                <CookieConsentProvider>
                                    <Outlet />
                                </CookieConsentProvider>
                            </AppProvider>
                            <Toaster />
                        </LayoutProvider>
                    </MaintenanceMode>
                </IntlStoreProvider>
            </StoreProvider>
            <ScrollRestoration />
        </ErrorBoundary>
    );
});

export default AppProviders;
