import { gql } from '@apollo/client';
import { useContext } from 'react';
import useLoginState from '../../../core/hooks/useLoginState';
import useStoreAndLanguageParams from '../../../core/hooks/useStoreAndLanguageParams';
import PreorderModeContext from '../../../preorder/PreorderModeContext';
import type { CartType, CartListItem } from '../../types';
import usePolledQuery from '../../../core/hooks/apollo/usePolledQuery';

export const cartListQuery = gql`
    query cartList($cartType: CartType, $store: ID!, $preorderSeasonId: Int) {
        carts(
            type: $cartType
            store: $store
            preorderSeasonId: $preorderSeasonId
        ) {
            id
            name
            type
            default
            hidden
            canSubmit
        }
    }
`;

export const getCartListRefetchQuery = (variables: {
    cartType: string;
    store: string;
}) => {
    return {
        query: cartListQuery,
        variables,
    };
};

const useCartList = (cartType: CartType, pollInterval?: number) => {
    const { store } = useStoreAndLanguageParams();
    const isLoggedIn = useLoginState();
    const { preorderSeasonId } = useContext(PreorderModeContext);
    const { data, loading, error } = usePolledQuery<{
        carts: CartListItem[];
    }>(cartListQuery, {
        variables: { cartType, store, preorderSeasonId },
        pollInterval,
        skip: !isLoggedIn,
    });
    if (error) {
        throw error;
    }
    return {
        carts: data?.carts,
        loading,
    };
};

export default useCartList;
