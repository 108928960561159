import { useContext } from 'react';
import PreorderModeContext from '../../preorder/PreorderModeContext';
import { StoreContext } from '../contexts/StoreContext';
import useCurrentStore from './useCurrentStore';

const useStoreRootPath = () => {
    const { id } = useCurrentStore();
    const { language } = useContext(StoreContext);

    const isLocked = !!APP_ENV.STORE;

    const { isInPreorderMode, preorderSeasonId } =
        useContext(PreorderModeContext);

    const storeRootPath = isLocked ? `/${language}` : `/${id}/${language}`;

    const storeRootPathWithPreorder = isInPreorderMode
        ? `${storeRootPath}/preorder/${preorderSeasonId}`
        : storeRootPath;

    return {
        isLocked,
        storeRootPath: storeRootPathWithPreorder,
    };
};

export default useStoreRootPath;
