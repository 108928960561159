class LimitedArray<T = unknown> {
    private readonly array: Array<T>;

    constructor(private readonly maxSize: number) {
        this.array = [];
    }

    push(...items: T[]): number {
        for (const item of items) {
            if (this.array.length === this.maxSize) {
                this.array.splice(0, 1);
            }

            this.array.push(item);
        }

        return this.array.length;
    }

    slice() {
        return this.array.slice();
    }

    [Symbol.iterator]() {
        return this.array[Symbol.iterator]();
    }
}

export default LimitedArray;
