import { useContext } from 'react';
import { CartContext } from '../../core/cartContext';
import assert from '../../core/util/assert';

const useCurrentCartId = () => {
    const { cart } = useContext(CartContext);

    assert(
        cart,
        'Could not find cart. Please check that hook is under CartProvider and Logged In area'
    );

    return cart.id;
};

export default useCurrentCartId;
