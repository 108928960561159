import { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { Text } from '@library';
import useStoreAndLanguageParams from '../../../core/hooks/useStoreAndLanguageParams';
import Modal from '../../../core/components/Modal';
import Button from '../../../core/components/Button';
import styles from '../../../core/components/AlertMessage.module.css';
import AlertIcon from '../../../core/components/icons/AlertIcon';
import VerticalLayout from '../../../core/components/layout/VerticalLayout';
import type LocalCart from '../../services/LocalCart';
import useObservable from '../../../core/hooks/useObservable';
import useCustomNavigate from '../../../core/hooks/router/useCustomNavigate';

const LocalCartAlertModal = ({ cart }: { cart: LocalCart }) => {
    const { storeRootPath } = useStoreAndLanguageParams();
    const { pathname } = useLocation();
    const navigate = useCustomNavigate();
    const shouldDisplayLocalCartAlert = useObservable(
        cart.shouldShowLocalCartAlert
    );

    if (!shouldDisplayLocalCartAlert) {
        return null;
    }

    const closeModal = () => {
        cart.hideLocalCartAlert();
    };

    const isCartPage = pathname.includes('/cart');

    return (
        <Modal
            disableShortcuts={isCartPage}
            onClose={closeModal}
            data-test-class="localCartAlertModal"
        >
            <VerticalLayout gap="medium" align="center">
                <VerticalLayout gap="small" align="center">
                    <AlertIcon large />
                    <Text primary regular center>
                        <FormattedMessage id="local cart alert" />
                    </Text>
                </VerticalLayout>

                <div className={styles.confirmationModalActions}>
                    <VerticalLayout gap="small" stretchChildren>
                        {!isCartPage && (
                            <Button
                                primary
                                fullWidth
                                onPress={() => {
                                    navigate(`${storeRootPath}/cart`);
                                    closeModal();
                                }}
                                messageId="go to cart"
                            />
                        )}

                        <Button
                            secondary
                            fullWidth
                            onPress={closeModal}
                            messageId="dismiss"
                        />
                    </VerticalLayout>
                </div>
            </VerticalLayout>
        </Modal>
    );
};
export default memo(LocalCartAlertModal);
