import { useContext } from 'react';
import assert from '../util/assert';
import { StoreContext } from '../contexts/StoreContext';

const useCurrentStore = () => {
    const { store } = useContext(StoreContext);
    assert(store, 'store is required.');

    return store;
};

export default useCurrentStore;
