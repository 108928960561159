import './main/handleErrors';
import './main/checkUnsupportedBrowsers';
import './main/intiGA';
import './main/initHtmlAttributes';

import { StrictMode } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { createRoot } from 'react-dom/client';

// eslint-disable-next-line import/no-internal-modules
import '@library/theme/theme.css';
import './core/styles';
import PageSuspense from './core/components/router/PageSuspense';
import NetworkStatusProvider from './main/components/NetworkStatusProvider';
import ApolloClientProvider from './core/components/providers/ApolloClientProvider';
import webshopRoutes from './main/routes';

const container = document.getElementById('root');

const root = createRoot(container!);

const base = document.querySelector('head base');
const router = createBrowserRouter(webshopRoutes, {
    basename: base ? base.getAttribute('href') || undefined : undefined,
});

const app = (
    <NetworkStatusProvider>
        <PageSuspense>
            <ApolloClientProvider>
                <RouterProvider router={router} />
            </ApolloClientProvider>
        </PageSuspense>
    </NetworkStatusProvider>
);

root.render(
    import.meta.env.MODE === 'development' ? (
        <StrictMode>{app}</StrictMode>
    ) : (
        app
    )
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
(window as any).APP_INITIALIZED = true;
