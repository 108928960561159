import LazyComponent from '../core/components/router/LazyComponent';

const ResetPassword = LazyComponent(
    async () => import('./components/ResetPassword')
);
const PleaseLoginPage = LazyComponent(
    async () => import('../core/components/pages/PleaseLoginPage')
);

const authRoutes = [
    { path: 'password-reset', element: <ResetPassword /> },
    { path: 'access-denied', element: <PleaseLoginPage /> },
    {
        path: 'login',
        element: <PleaseLoginPage />,
    },
];

export default authRoutes;
