import { memo } from 'react';
import type { IconProps } from './types';

const ChevronDownIcon = ({
    onClick,
    className,
    iconLabel,
    color,
    large = false,
}: {
    large?: boolean;
    onClick?: (element: React.MouseEvent<SVGSVGElement>) => void;
    className?: string;
} & IconProps) => (
    <svg
        onClick={onClick}
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        width={large ? '11.5215' : '7.681'}
        height={large ? '6.8205' : '4.547'}
        viewBox="0 0 7.681 4.547"
        data-icon={iconLabel}
        color={color}
    >
        <path
            fill="none"
            stroke="currentColor"
            d="M-12625.49,477.343l3.486,3.486,3.487-3.486"
            transform="translate(12625.844 -476.99)"
        />
    </svg>
);

export default memo(ChevronDownIcon);
