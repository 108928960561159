import type { ApolloError, ServerError } from '@apollo/client';

const isApolloError = (error: unknown): error is ApolloError =>
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (error as any).graphQLErrors || (error as any).networkError;

const formatErrorMessage = (error: unknown) => {
    let content = `${error}`;

    if (isApolloError(error)) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if (error.networkError && (error.networkError as any).result) {
            content += `Status code ${
                (error.networkError as ServerError).statusCode
            }`;
            content += ' ';
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-explicit-any
            content += (error.networkError as any).result?.errors
                ?.map((item: { message: string }) => item.message)
                .join('\n');
        }

        if (error.graphQLErrors.length) {
            content += error.graphQLErrors.map(item => item.message).join('\n');
        }

        return content;
    } else if (error instanceof Error) {
        // eslint-disable-next-line no-console
        content += `Error class name: ${error.name}`;

        return content;
    }

    return error;
};

export default formatErrorMessage;
