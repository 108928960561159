import type { NavigateOptions, To } from 'react-router-dom';
import { useNavigate, useSearchParams } from 'react-router-dom';
import isString from '../../util/isString';

const useCustomNavigate = () => {
    const [params] = useSearchParams();
    const navigate = useNavigate();

    const cartId = params.get('cartId');

    if (!cartId) {
        return navigate;
    }

    return (to: To, options?: NavigateOptions) => {
        if (isString(to)) {
            const [path, search] = to.split('?');
            const searchParams = new URLSearchParams(search);

            searchParams.set('cartId', cartId);
            const updatedSearch = searchParams.toString();

            navigate(`${path}?${updatedSearch}`, options);
        } else {
            const search = to.search?.toString() || '';
            const searchParams = new URLSearchParams(search);

            searchParams.set('cartId', cartId);
            const updatedSearch = searchParams.toString();

            navigate({ ...to, search: updatedSearch }, options);
        }
    };
};

export default useCustomNavigate;
