import { memo, useContext, useMemo, useRef } from 'react';
import type { ReactNode } from 'react';
import type { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { useApolloClient } from '@apollo/client';
import { Navigate, useSearchParams } from 'react-router-dom';
import { getOperationName } from '@apollo/client/utilities';
import cartFactory from '../../../cart/services/cartFactory';
import { CartContext } from '../../cartContext';
import useStoreAndLanguageParams from '../../hooks/useStoreAndLanguageParams';
import useLoginState from '../../hooks/useLoginState';
import LocalCart from '../../../cart/services/LocalCart';
import LocalCartAlertModal from '../../../cart/components/CartActionsModal/LocalCartAlertModal';
import useObserveStorageChangesLazy from '../../../cart/queries/useObserveStorageChangesLazy';
import type { Cart, CartType } from '../../../cart/types';
import { CART_UPDATE_FLAG_KEY } from '../../../cart/types';
import { AppContext } from '../../context';
import { checkoutQuery } from '../../../checkout/queries/useCheckoutQuery';
import { getPriceSummaryRefetchQuery } from '../../../cart/queries/useCartPriceSummary';
import notEmpty from '../../util/notEmpty';
import type { StoreId } from '../../types/stores';
import triggerCartUpdatedFlag from '../../../cart/util/triggerCartUpdatedFlag';
import useCartErrorNotifications from '../../../cart/hooks/useCartErrorNotifications';
import useCartUpdated from '../../../cart/hooks/useCartUpdated';
import { purchaseBreakdownQuery } from '../../../cart/queries/useCartBrandTotal';
import { quantitySurcharge } from '../../../cart/queries/useQuantitySurcharge';
import useActiveCart from '../../../cart/hooks/useActiveCart';
import StockLocationProvider from './StockLocationProvider';

const useUpdateRelatedToCartQueries = ({
    cart,
    store,
    onCartUpdated: onCartUpdatedProp,
}: {
    cart: Cart;
    cartType: CartType;
    store: StoreId;
    onCartUpdated?: () => void;
}) => {
    const { customerPriceCacheKey } = useContext(AppContext);
    const client = useApolloClient();
    const isLoggedIn = useLoginState();
    useCartUpdated(() => {
        if (isLoggedIn) {
            Promise.all([
                client.refetchQueries({
                    include: [
                        getOperationName(checkoutQuery),
                        getOperationName(purchaseBreakdownQuery),
                        getOperationName(quantitySurcharge),
                    ].filter(notEmpty),
                }),
                client.query(
                    getPriceSummaryRefetchQuery({
                        cartId: cart.id,
                        store,
                        customerPriceCacheKey,
                    })
                ),
            ]).catch(error => {
                throw error;
            });
        }
        onCartUpdatedProp?.();
    }, cart);
};

const RedirectToDefaultCart = memo(() => {
    const [searchParams] = useSearchParams();
    searchParams.delete('cartId');
    return <Navigate to={{ search: searchParams.toString() }} />;
});

const CartErrorHandler = memo(({ children }: { children: ReactNode }) => {
    useCartErrorNotifications();

    return <>{children}</>;
});

const CartProvider = ({
    children,
    cartType,
    onCartUpdated,
}: {
    children: ReactNode;
    cartType?: CartType;
    onCartUpdated?: () => void;
}) => {
    const client = useApolloClient() as ApolloClient<NormalizedCacheObject>;
    const { store } = useStoreAndLanguageParams();
    const isLoggedIn = useLoginState();
    const ineligibleQuantitiesMap = useRef(new Map<string, number>());

    const { cartId, canSubmit, shouldRedirect } = useActiveCart(
        cartType || 'user'
    );

    const cartValue = useMemo(() => {
        const currentUserCartType = isLoggedIn ? 'user' : 'local';
        const currentCartType = cartType ?? currentUserCartType;
        const cart = cartFactory({
            client,
            store,
            cartType: currentCartType,
            cartId,
        });
        return {
            cart,
            cartUpdatedFlag: `${CART_UPDATE_FLAG_KEY}_${cart.id || cartType}`,
            cartType: currentCartType,
            ineligibleQuantityItems: ineligibleQuantitiesMap,
            canSubmit,
        };
    }, [
        isLoggedIn,
        cartType,
        client,
        store,
        cartId,
        ineligibleQuantitiesMap,
        canSubmit,
    ]);

    useUpdateRelatedToCartQueries({
        cart: cartValue.cart,
        store,
        cartType: cartValue.cartType,
        onCartUpdated,
    });

    useObserveStorageChangesLazy(cartValue.cartUpdatedFlag, () => {
        cartValue.cart.fetchCart();
    });

    useCartUpdated(() => {
        triggerCartUpdatedFlag(cartValue.cartUpdatedFlag);
    }, cartValue.cart);

    if (shouldRedirect) {
        return <RedirectToDefaultCart />;
    }

    if (cartType === 'customerPreorder') {
        return (
            <CartContext.Provider value={cartValue}>
                {children}
            </CartContext.Provider>
        );
    }

    return (
        <CartContext.Provider value={cartValue}>
            <CartErrorHandler>
                <StockLocationProvider>
                    {children}
                    {cartValue.cart instanceof LocalCart && (
                        <LocalCartAlertModal cart={cartValue.cart} />
                    )}
                </StockLocationProvider>
            </CartErrorHandler>
        </CartContext.Provider>
    );
};

export default memo(CartProvider);
