type Params = Record<string, string | string[] | undefined>;

const parseSearch = <T extends Params>(search: string) =>
    Array.from(new URLSearchParams(search).entries()).reduce(
        (a, [name, value]: [string, string]) => {
            const currentValue = a[name];
            if (currentValue) {
                return {
                    ...a,
                    [name]: Array.isArray(currentValue)
                        ? [...currentValue, value]
                        : [currentValue, value],
                };
            }
            return {
                ...a,
                [name]: value,
            };
        },
        {} as T
    );

export default parseSearch;
