import { useContext } from 'react';
import { StoreContext } from '../contexts/StoreContext';
import useCurrentStore from './useCurrentStore';
import useStoreRootPath from './useStoreRootPath';

const useStoreAndLanguageParams = (ignoreSessionStore?: boolean) => {
    const { id } = useCurrentStore();

    const { language, urlLanguage } = useContext(StoreContext);
    const { storeRootPath, isLocked } = useStoreRootPath();

    return {
        store: id,
        language: ignoreSessionStore ? urlLanguage : language,
        storeRootPath,
        isLocked,
    };
};

export default useStoreAndLanguageParams;
