import type { RouteObject } from 'react-router-dom';
import { importComponents } from '@library';
import AccessDeniedRedirect from '../core/components/router/AccessDeniedRedirect';
import HomeRedirect from '../core/components/router/HomeRedirect';
import RestrictedContent from '../core/components/router/RestrictedContent';
import LazyComponent from '../core/components/router/LazyComponent';

const { PostPage, StaticPage, PostsPage, HomePage } =
    importComponents(async () => {
        return import('./content.module');
    }, ['PostPage', 'StaticPage', 'PostsPage', 'HomePage']);

const PreorderPages = LazyComponent(
    async () => import('./components/PreorderPages')
);

const ContentRoutes: RouteObject[] = [
    {
        path: '',
        element: <HomePage />,
    },
    {
        path: 'news',
        children: [
            {
                index: true,
                element: <PostsPage />,
            },
            {
                path: ':page',
                element: <PostPage />,
            },
        ],
    },
    {
        path: '*',
        element: <StaticPage />,
    },
    {
        path: 'preorder-pages/*',
        element: (
            <RestrictedContent loggedInOnly fallback={AccessDeniedRedirect}>
                <RestrictedContent
                    preorder
                    shouldShowSpinnerPage
                    fallback={HomeRedirect}
                >
                    <PreorderPages />
                </RestrictedContent>
            </RestrictedContent>
        ),
    },
];

export default ContentRoutes;
