import type { ApolloClient } from '@apollo/client';
import { useApolloClient } from '@apollo/client';
import { getOperationName } from '@apollo/client/utilities';
import { useCallback, useContext } from 'react';
import useCurrentCartId from '../../cart/hooks/useCurrentCartId';
import { cartPriceSummaryQuery } from '../../cart/queries/useCartPriceSummary';
import { CartContext } from '../../core/cartContext';
import useStoreAndLanguageParams from '../../core/hooks/useStoreAndLanguageParams';
import { customerPriceCacheKeyQuery } from '../../core/queries/usePriceCacheKey';
import notEmpty from '../../core/util/notEmpty';

export const resetPriceInCache = async ({
    client,
    store,
    cartType,
    cartId,
    refetchTotals = false,
}: {
    client: ApolloClient<object>;
    store: string;
    cartType: string;
    refetchTotals?: boolean;
    cartId?: number;
}) => {
    const customerPriceCacheKeyData = client.readQuery<{
        customerPriceCacheKey: string;
    }>({ query: customerPriceCacheKeyQuery });

    const serializedState = client.cache.extract();

    const allCachedPrices = Object.keys(serializedState)
        .filter(item => item.startsWith('UserProductPrice:'))
        .map(item => {
            const parseSku = new RegExp(/"sku":"([A-z0-9-]*)"/gm);
            const match = parseSku.exec(item);

            return match ? match[1] : '';
        });

    await client.refetchQueries({
        updateCache(cache) {
            for (const sku of allCachedPrices) {
                const price = {
                    __typename: 'UserProductPrice',
                    store,
                    sku,
                    cacheKey: customerPriceCacheKeyData?.customerPriceCacheKey,
                    cartType,
                    cartId,
                };

                const id = client.cache.identify(price);
                cache.evict({
                    id,
                });
            }

            cache.evict({ id: 'ROOT_QUERY', fieldName: 'customerPrices' });
        },
        include: refetchTotals
            ? [getOperationName(cartPriceSummaryQuery)].filter(notEmpty)
            : [],
    });
};

const usePriceCache = () => {
    const client = useApolloClient();
    const { cartType } = useContext(CartContext);
    const cartId = useCurrentCartId();
    const { store } = useStoreAndLanguageParams();

    const resetPrices = useCallback(
        async (refetchTotals?: boolean) => {
            await resetPriceInCache({
                client,
                store,
                cartType,
                refetchTotals,
                cartId,
            });
        },
        [client, store, cartType, cartId]
    );

    return { resetPrices };
};

export default usePriceCache;
