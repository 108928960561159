import { useLocation } from 'react-router-dom';
import type { Language } from '../../types';
import replaceLanguageInPath from '../../util/replaceLanguageInPath';
import type { User } from '../../../user/types';
import type { Store } from '../../types/stores';

let isPreferredLanguageAppliedForUser: string | null = null;

const useCheckUserPreferredLanguageRedirect = (
    language?: Language,
    currentUser?: User,
    stores?: Store[]
) => {
    const { pathname, search } = useLocation();

    if (!stores) {
        return null;
    }

    const isLoggedIn = !!currentUser;

    if (isPreferredLanguageAppliedForUser === null) {
        isPreferredLanguageAppliedForUser = currentUser
            ? currentUser.username
            : '';
        return;
    }
    if (!isLoggedIn) {
        isPreferredLanguageAppliedForUser = '';
    }
    if (
        isLoggedIn &&
        isPreferredLanguageAppliedForUser !== currentUser.username
    ) {
        isPreferredLanguageAppliedForUser = currentUser.username;
        const preferredLanguage = currentUser.language;

        if (preferredLanguage !== language) {
            const newUrl = replaceLanguageInPath({
                pathname,
                language: preferredLanguage,
                isLockedStore: !!APP_ENV.STORE,
            });
            return { pathname: newUrl, search };
        }
    }
};

export default useCheckUserPreferredLanguageRedirect;
