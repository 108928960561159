import { memo, forwardRef } from 'react';
import { clsx } from 'clsx';
import type { LinkProps } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import useStoreAndLanguageParams from '../../hooks/useStoreAndLanguageParams';
import injectStoreToLocationDescription from '../../util/injectStoreToLocationDescription';
import CustomLink from '../CustomLink';

type CurrentStoreLinkProps = Omit<LinkProps, 'relative'> & {
    saveScrollPosition?: boolean;
    activeClassName?: string;
    children: React.ReactNode;
    relative?: boolean;
};

const CurrentStoreLink = forwardRef<HTMLAnchorElement, CurrentStoreLinkProps>(
    (
        {
            to,
            onClick,
            saveScrollPosition,
            activeClassName,
            relative = false,
            ...rest
        },
        ref
    ) => {
        const { storeRootPath } = useStoreAndLanguageParams();

        const props = {
            ref: ref,
            to: relative
                ? to
                : injectStoreToLocationDescription(storeRootPath, to),
            ...rest,
            onClick: (event: React.MouseEvent<HTMLAnchorElement>) => {
                if (onClick) {
                    onClick(event);
                }
            },
        };

        if (activeClassName) {
            return (
                <NavLink
                    {...props}
                    className={({ isActive }) => {
                        return clsx(
                            props.className,
                            isActive ? activeClassName : undefined
                        );
                    }}
                />
            );
        }

        return <CustomLink {...props} />;
    }
);

export default memo(CurrentStoreLink);
