import assert from '../core/util/assert';

if (APP_ENV.GTM_ID) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, max-params
    ((w: any, d: Document, s: string, l: string, i: string) => {
        // eslint-disable-next-line no-param-reassign
        w[l] = w[l] || [];
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
        // eslint-disable-next-line prefer-destructuring
        const f = d.getElementsByTagName(s)[0];
        const j = d.createElement(s);
        const dl = l !== 'dataLayer' ? `&l=${l}` : '';

        assert(j instanceof HTMLScriptElement, 'script element is required.');
        j.async = true;
        j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`;

        assert(f.parentNode, 'f.parentNode is required.');
        f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', APP_ENV.GTM_ID);
}
export {};
