import type { ReactNode } from 'react';
import { memo } from 'react';
import type { RouteObject } from 'react-router-dom';
import LazyComponent from '../core/components/router/LazyComponent';
import AccessDeniedRedirect from '../core/components/router/AccessDeniedRedirect';
import RestrictedContent from '../core/components/router/RestrictedContent';
import PageNotFound from '../core/components/router/PageNotFound';
import { useCanImpersonateCustomers } from './queries/useCapabilities';

const DeeplinkImpersonateCustomer = LazyComponent(
    async () => import('./components/DeeplinkImpersonateCustomer')
);
const DeeplinkImpersonateCustomerFailure = LazyComponent(
    async () => import('./components/DeeplinkImpersonateCustomerFailure')
);

const WithImpersonationPermissionCheck = memo(
    ({ children }: { children: ReactNode }) => {
        const canImpersonate = useCanImpersonateCustomers();

        if (!canImpersonate) {
            return <PageNotFound />;
        }

        return <>{children}</>;
    }
);

const WithDeeplinkPermissions = memo(
    ({ children }: { children: ReactNode }) => {
        return (
            <RestrictedContent loggedInOnly fallback={AccessDeniedRedirect}>
                <WithImpersonationPermissionCheck>
                    {children}
                </WithImpersonationPermissionCheck>
            </RestrictedContent>
        );
    }
);

const deeplinkRoutes: RouteObject[] = [
    {
        path: 'as-customer',
        children: [
            {
                path: '',
                element: (
                    <WithDeeplinkPermissions>
                        <DeeplinkImpersonateCustomer />
                    </WithDeeplinkPermissions>
                ),
            },
            {
                path: 'failed',
                element: (
                    <WithDeeplinkPermissions>
                        <DeeplinkImpersonateCustomerFailure />
                    </WithDeeplinkPermissions>
                ),
            },
        ],
    },
];

export default deeplinkRoutes;
