import { memo } from 'react';
import { clsx } from 'clsx';
import iconStyles from './Icon.module.scss';
import type { IconProps } from './types';

const CheckmarkIcon = ({
    iconLabel,
    color,
    circular = false,
    small = false,
}: IconProps & {
    circular?: boolean;
    small?: boolean;
}) => {
    return (
        <div
            className={clsx({
                [iconStyles.circleContainer]: circular,
                [iconStyles.small]: small,
            })}
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="11.694"
                height="8.589"
                viewBox="0 0 11.694 8.589"
                data-icon={iconLabel}
                color={color}
            >
                <path
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2px"
                    d="M-5024.82,579.91l3.812,3.812,6.468-6.468"
                    transform="translate(5025.527 -576.547)"
                />
            </svg>
        </div>
    );
};

export const Checkmark = memo(({ color }: IconProps) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="8.938"
            height="6.548"
            viewBox="0 0 8.938 6.548"
            color={color}
        >
            <path
                className="a"
                fill="none"
                stroke="currentColor"
                strokeWidth="1.5px"
                d="M-5024.82,579.289l2.921,2.922,4.957-4.957"
                transform="translate(5025.35 -576.724)"
            />
        </svg>
    );
});

export default memo(CheckmarkIcon);
