import { useContext } from 'react';
import UserContext from '../components/UserContext';
import assert from '../../core/util/assert';

const useCurrentUser = () => {
    return useContext(UserContext).user;
};

export const useCurrentUserLoggedInOnly = () => {
    const { user } = useContext(UserContext);
    assert(user, 'User is not logged in');
    return user;
};

export default useCurrentUser;
