import type { Observable } from '@apollo/client';
import type { ObservableVariable } from '../../core/util/makeObservableVariable';
import makeObservableVariable from '../../core/util/makeObservableVariable';
import type { Cart, CartItem } from '../types';

const LOCAL_CART_ALERT_LAST_TIME_KEY = 'localCartLastTimeKey';
const LOCAL_CART_ALERT_INTERVAL = 24 * 60 * 60 * 1000;

class LocalCart implements Cart {
    private readonly LOCAL_CART_KEY = 'localCartKey';

    private readonly cartBusyObservableValue: ObservableVariable<boolean>;

    private readonly itemsObservableValue: ObservableVariable<CartItem[]>;

    private readonly shouldShowLocalCartAlertObservableValue: ObservableVariable<boolean>;

    private readonly cartUpdatedObservableVariable: ObservableVariable<unknown>;

    readonly cartBusy: Observable<boolean>;

    readonly items: Observable<CartItem[]>;

    readonly shouldShowLocalCartAlert: Observable<boolean>;

    readonly cartUpdated: Observable<unknown>;

    constructor() {
        this.cartBusyObservableValue = makeObservableVariable(false);
        this.cartUpdatedObservableVariable = makeObservableVariable({}, true);
        this.cartUpdated = this.cartUpdatedObservableVariable.onValueChange;
        this.itemsObservableValue = makeObservableVariable([]);
        this.shouldShowLocalCartAlertObservableValue =
            makeObservableVariable(false);
        this.cartBusy = this.cartBusyObservableValue.onValueChange;
        this.items = this.itemsObservableValue.onValueChange;
        this.shouldShowLocalCartAlert =
            this.shouldShowLocalCartAlertObservableValue.onValueChange;

        this.itemsObservableValue.onValueChange.subscribe(value => {
            localStorage.setItem(this.LOCAL_CART_KEY, JSON.stringify(value));
        });

        this.fetchCartSilent();
    }

    hideLocalCartAlert() {
        this.shouldShowLocalCartAlertObservableValue.updateValue(false);
    }

    static clearAlert() {
        localStorage.removeItem(LOCAL_CART_ALERT_LAST_TIME_KEY);
    }

    fetchCartSilent() {
        const data = localStorage.getItem(this.LOCAL_CART_KEY);
        try {
            const parsedCart: CartItem[] = data ? JSON.parse(data) : [];
            if (!Array.isArray(parsedCart)) {
                throw new Error('invalid data');
            }
            this.itemsObservableValue.updateValue(parsedCart);
        } catch (e) {
            localStorage.setItem(this.LOCAL_CART_KEY, JSON.stringify([]));
        }
    }

    fetchCart() {
        this.fetchCartSilent();
        this.cartUpdatedObservableVariable.updateValue({});
    }

    private addToCart = (
        items: CartItem[],
        params: { sku: string; quantity: number }
    ) => {
        const { sku, quantity } = params;
        const newItem = {
            sku,
            quantity,
            comment: '',
        };

        return [...items, newItem];
    };

    private updateCartItem = (items: CartItem[], updatedItem: CartItem) => {
        const index = items.findIndex(item => item.sku === updatedItem.sku);
        const updatedItems = [...items.slice()];

        updatedItems.splice(index, 1, updatedItem);

        return [...updatedItems];
    };

    updateItemQuantity(sku: string, quantity: number): void {
        const items = this.itemsObservableValue.currentValue;
        const itemInCart = items.find(item => item.sku === sku);

        if (itemInCart && quantity > 0) {
            const updatedItem = {
                ...itemInCart,
                quantity,
            };
            this.itemsObservableValue.updateValue(
                this.updateCartItem(items, updatedItem)
            );
        } else if (quantity === 0) {
            this.itemsObservableValue.updateValue(
                items.slice().filter(item => item.sku !== sku)
            );
        } else {
            const result = this.addToCart(items, { sku, quantity });
            this.itemsObservableValue.updateValue(result);
        }

        const lastTimeShowed = +(
            localStorage.getItem(LOCAL_CART_ALERT_LAST_TIME_KEY) || 0
        );

        const shouldShow =
            Date.now() - lastTimeShowed >= LOCAL_CART_ALERT_INTERVAL;

        if (shouldShow) {
            localStorage.setItem(
                LOCAL_CART_ALERT_LAST_TIME_KEY,
                Date.now().toString()
            );

            this.shouldShowLocalCartAlertObservableValue.updateValue(true);
        }
        this.cartUpdatedObservableVariable.updateValue({});
    }

    updateBulkItemQuantities(
        items: { sku: string; quantity: number }[],
        replace = false
    ): void {
        let cartItems = replace
            ? []
            : this.itemsObservableValue.currentValue.slice();

        for (const { sku, quantity } of items) {
            const itemInCart = cartItems.find(item => item.sku === sku);
            if (itemInCart && quantity > 0) {
                const updatedItem = {
                    ...itemInCart,
                    quantity,
                };
                cartItems = this.updateCartItem(cartItems, updatedItem);
            } else if (quantity === 0) {
                cartItems = cartItems.slice().filter(item => item.sku !== sku);
            } else {
                cartItems = this.addToCart(cartItems, { sku, quantity });
            }
        }

        this.itemsObservableValue.updateValue(cartItems);

        const lastTimeShowed = +(
            localStorage.getItem(LOCAL_CART_ALERT_LAST_TIME_KEY) || 0
        );

        const shouldShow =
            Date.now() - lastTimeShowed >= LOCAL_CART_ALERT_INTERVAL;

        if (shouldShow) {
            localStorage.setItem(
                LOCAL_CART_ALERT_LAST_TIME_KEY,
                Date.now().toString()
            );

            this.shouldShowLocalCartAlertObservableValue.updateValue(true);
        }
        this.cartUpdatedObservableVariable.updateValue({});
    }

    async updateItemComment(sku: string, comment: string) {
        const items = this.itemsObservableValue.currentValue;
        const itemInCart = items.find(item => item.sku === sku);
        if (itemInCart) {
            const updatedItem = {
                ...itemInCart,
                comment,
            };
            this.itemsObservableValue.updateValue(
                this.updateCartItem(items, updatedItem)
            );
        }

        this.cartUpdatedObservableVariable.updateValue({});
    }

    clearCart(): void {
        this.itemsObservableValue.updateValue([]);
        this.cartUpdatedObservableVariable.updateValue({});
    }
}

export default LocalCart;
