import { memo } from 'react';
import { FluidProgressIndicator } from '../ProgressIndicators';
import styles from './SpinnerPage.module.scss';

const SpinnerPage = memo(() => {
    return (
        <div className={styles.container}>
            <FluidProgressIndicator />
        </div>
    );
});

export default SpinnerPage;
