import { memo } from 'react';
import { clsx } from 'clsx';
import { Text } from '@library';
import type { StockListSchema } from '../queries/useStockLists';
import getStockListUrl from '../util/getStocklistUrl';
import useStockListCategories from '../queries/useStockListCategories';
import styles from './CategoryDropdown.module.scss';
import LeafCategory from './LeafCategory';
import CurrentStoreLink from './router/CurrentStoreLink';

const StockListCategories = memo(
    ({
        filters,
        params,
        onClose,
        categoryId,
    }: {
        filters: {
            attribute: string;
            component: string;
            value: string;
            _uid: string;
        }[];
        categoryId: string;
        params: string;
        onClose: () => void;
    }) => {
        const { items } = useStockListCategories({ filters, categoryId });

        return (
            <>
                {items?.map(child => (
                    <div key={child.id} className={styles.subCategories}>
                        <LeafCategory
                            key={child.id}
                            params={params}
                            onClose={onClose}
                            category={child}
                        />
                    </div>
                ))}
            </>
        );
    }
);

const StockList = ({
    item,
    categoryId,
    onClose,
}: {
    item: StockListSchema;
    categoryId: string;
    onClose: () => void;
}) => {
    const { targetPath, paramsStr } = getStockListUrl(categoryId, item.filters);

    return (
        <div
            className={styles.stockList}
            // eslint-disable-next-line no-underscore-dangle
            key={item._uid}
            data-test-class="stockListLink"
        >
            <div className={styles.secondaryCategory}>
                <CurrentStoreLink
                    onClick={onClose}
                    className={clsx('category-link', styles.categoryItemLink)}
                    to={targetPath}
                >
                    <Text primary bold>
                        {item.title}
                    </Text>
                </CurrentStoreLink>
            </div>
            <StockListCategories
                categoryId={categoryId}
                onClose={onClose}
                params={paramsStr}
                filters={item.filters}
            />
        </div>
    );
};

export default memo(StockList);
