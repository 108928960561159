import type {
    NoInfer,
    OperationVariables,
    QueryHookOptions,
} from '@apollo/client';
import { useQuery } from '@apollo/client';
import type { DocumentNode } from 'graphql';
import { useCallback, useRef } from 'react';
import useVisibilityStatus from '../dom/useVisibilityStatus';

const usePolledQuery = <
    TData,
    TVariables extends OperationVariables = OperationVariables,
>(
    query: DocumentNode,
    options?: QueryHookOptions<NoInfer<TData>, NoInfer<TVariables>>,
    isCached?: boolean
) => {
    const currentPollInterval = useRef(options?.pollInterval);
    const isCancelled = useRef(false);

    const {
        data,
        loading,
        previousData,
        error,
        startPolling,
        stopPolling,
        refetch,
        ...rest
    } = useQuery(query, {
        ...options,
    });

    useVisibilityStatus(isVisible => {
        if (isVisible) {
            if (!isCancelled.current) {
                if (!options?.skip) {
                    void refetch();
                }
                startPolling(currentPollInterval.current ?? 360_000);
            }
        } else {
            stopPolling();
        }
    });

    const startPollingFn = useCallback(
        (pollInterval: number) => {
            currentPollInterval.current = pollInterval;
            isCancelled.current = false;

            startPolling(currentPollInterval.current);
        },
        [startPolling]
    );

    const stopPollingFn = useCallback(() => {
        isCancelled.current = true;

        stopPolling();
    }, [stopPolling]);

    if (!isCached) {
        return {
            data,
            error,
            loading,
            stopPolling: stopPollingFn,
            startPolling: startPollingFn,
            previousData,
            ...rest,
        };
    }

    const emptyData = !data;
    const isShowPrevious = (emptyData && loading) || options?.skip;

    return {
        ...rest,
        loading,
        error,
        data: isShowPrevious ? previousData : data,
        stopPolling,
        startPolling: startPollingFn,
        previousData,
    };
};

export default usePolledQuery;
