import { gql } from '@apollo/client';

const updateCartItemCommentMutation = gql`
    mutation updateCartItemCommentMutation(
        $store: ID!
        $cartId: Int!
        $sku: String!
        $comment: String!
    ) {
        updateCartItemComment(
            store: $store
            cartId: $cartId
            sku: $sku
            comment: $comment
        ) {
            success
        }
    }
`;

export default updateCartItemCommentMutation;
