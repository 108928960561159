import type { ReactNode } from 'react';
import type { RouteObject } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import { importComponents } from '@library';
import RestrictedContent from '../core/components/router/RestrictedContent';
import AccessDeniedRedirect from '../core/components/router/AccessDeniedRedirect';
import HomeRedirect from '../core/components/router/HomeRedirect';

const productListPaths = [
    'categories/:categoryId/page?/:page?',
    'page?/:page?',
];

const {
    KeywordSearchResults,
    CategoryProductsList,
    BrandSearchResults,
    MotorcycleSearchResults,
    SparePartsSearchResults,
    SalePage,
    SparePartsSearchPdfView,
} = importComponents(async () => {
    return import('./product.module');
}, [
    'KeywordSearchResults',
    'CategoryProductsList',
    'BrandSearchResults',
    'MotorcycleSearchResults',
    'SparePartsSearchResults',
    'SalePage',
    'SparePartsSearchPdfView',
]);

const getProductListRoutes = (element: ReactNode) => {
    return productListPaths.map(path => {
        return {
            path,
            element,
        };
    });
};

const productListRoutes: RouteObject[] = [
    {
        path: 'categories/:categoryId/page?/:page?',
        element: <CategoryProductsList />,
    },
    {
        path: 'search',
        children: getProductListRoutes(<KeywordSearchResults />),
    },
    {
        path: 'vehicle-search/*',
        children: getProductListRoutes(<MotorcycleSearchResults />),
    },
    {
        path: 'brand/:brandSlug/*',
        children: getProductListRoutes(<BrandSearchResults />),
    },
    {
        path: 'spare-parts-search/*',
        element: (
            <RestrictedContent
                storeFlags="sparePartSearchEnabled"
                fallback={HomeRedirect}
            >
                <RestrictedContent
                    loggedInOnly
                    restrictedPage
                    fallback={AccessDeniedRedirect}
                >
                    <Outlet />
                </RestrictedContent>
            </RestrictedContent>
        ),
        children: getProductListRoutes(<SparePartsSearchResults />),
    },
    {
        path: 'spare-parts-search-view/*',
        element: (
            <RestrictedContent
                storeFlags="sparePartSearchEnabled"
                fallback={HomeRedirect}
            >
                <RestrictedContent
                    loggedInOnly
                    restrictedPage
                    fallback={AccessDeniedRedirect}
                >
                    <SparePartsSearchPdfView />
                </RestrictedContent>
            </RestrictedContent>
        ),
    },
    {
        path: 'sale/*',
        element: (
            <RestrictedContent
                loggedInOnly
                restrictedPage
                fallback={HomeRedirect}
                shouldShowSpinnerPage
            >
                <Outlet />
            </RestrictedContent>
        ),
        children: getProductListRoutes(<SalePage />),
    },
];

export default productListRoutes;
