interface Node<T extends Node<T>> {
    children: T[];
}

const flattenTree = <T extends Node<T>>(
    data: T[],
    maxLevel?: number,
    currentLevel?: number
): T[] => {
    return data.reduce((res, node) => {
        res.push(node);
        if (node.children.length === 0) {
            return res;
        }

        const currentLevelSafe = currentLevel ?? 1;
        if (maxLevel !== undefined && currentLevelSafe >= maxLevel) {
            return res;
        }
        const allChild = flattenTree(
            node.children,
            maxLevel,
            maxLevel !== undefined ? currentLevelSafe + 1 : undefined
        );
        return res.concat(allChild);
    }, [] as T[]);
};

export default flattenTree;
