import { gql } from '@apollo/client';
import useStoreAndLanguageParams from '../../core/hooks/useStoreAndLanguageParams';
import type { CartPriceTotals } from '../types';
import usePriceQueryOptions from '../../pricing/hooks/usePriceQueryOptions';
import usePolledQuery from '../../core/hooks/apollo/usePolledQuery';

export const cartPriceSummaryQuery = gql`
    query cartPriceSummary(
        $store: ID!
        $cartId: Int!
        $customerPriceCacheKey: ID
    ) {
        cartPriceSummary(
            store: $store
            cartId: $cartId
            cacheKey: $customerPriceCacheKey
        ) {
            store
            cacheKey
            retailPrice
            generalDealerPrice
            finalPrice
            dealerDiscountPercentage
        }
    }
`;

export const getPriceSummaryRefetchQuery = (variables: {
    store: string;
    cartId?: number;
    customerPriceCacheKey?: string;
}) => {
    return {
        query: cartPriceSummaryQuery,
        variables,
    };
};

const useCartPriceSummary = ({
    cartId,
    pollInterval,
    updateOnFirstRender,
}: {
    cartId?: number;
    pollInterval?: number;
    updateOnFirstRender?: boolean;
}): CartPriceTotals => {
    const { store } = useStoreAndLanguageParams();
    const { variables, loadingPriceQueryOptions } = usePriceQueryOptions();

    const { data, error } = usePolledQuery<{
        cartPriceSummary: CartPriceTotals;
    }>(
        cartPriceSummaryQuery,
        {
            variables: {
                ...variables,
                store,
                cartId,
            },
            skip: loadingPriceQueryOptions || !cartId,
            pollInterval,
            fetchPolicy: updateOnFirstRender ? 'cache-and-network' : undefined,
            nextFetchPolicy: updateOnFirstRender ? 'cache-first' : undefined,
        },
        true
    );

    if (error) {
        throw error;
    }

    return (
        data?.cartPriceSummary || {
            retailPrice: 0,
        }
    );
};

export default useCartPriceSummary;
