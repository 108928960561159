import { Toaster as ExternalToaster } from 'react-hot-toast';
import { memo } from 'react';
import styles from './Toaster.module.css';

const Toaster = () => {
    return (
        <ExternalToaster
            position="bottom-right"
            containerClassName={styles.toastContainer}
            toastOptions={{
                style: {
                    border: 'none',
                    padding: '0',
                    boxShadow: 'none',
                    maxWidth: 'none',
                    background: 'none',
                },
                duration: 6_000,
                className: styles.toast,
            }}
        />
    );
};

export default memo(Toaster);
