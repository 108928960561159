import { memo, Suspense } from 'react';
import type { SuspenseProps } from 'react';
import {
    FluidProgressIndicator,
    ProgressSpinner,
    SmallProgressIndicator,
} from '../ProgressIndicators';
import styles from './ComponentSuspense.module.css';

const Spinner = memo(
    ({ small, medium }: { small: boolean; medium: boolean }) => {
        if (small) {
            return <ProgressSpinner />;
        }
        if (medium) {
            return <SmallProgressIndicator />;
        }
        return <FluidProgressIndicator />;
    }
);

const ComponentSuspense = ({
    children,
    height = '100px',
    width = 'auto',
    small = false,
    medium = false,
}: Omit<SuspenseProps, 'fallback'> & {
    height?: string;
    width?: string;
    small?: boolean;
    medium?: boolean;
}) => {
    return (
        <Suspense
            fallback={
                <div
                    className={styles.container}
                    style={{
                        width,
                        height,
                    }}
                >
                    <Spinner small={small} medium={medium} />
                </div>
            }
        >
            {children}
        </Suspense>
    );
};

export default memo(ComponentSuspense);
