import type { ReactNode } from 'react';
import isString from '../../../core/util/isString';
import { capitalize } from '../layout/Stack';

interface BaseTypographyProps {
    children?: ReactNode;
    /**
     * Automatically uppercase all characters in the text.
     */
    uppercase?: boolean;
    /**
     * Center align text.
     */
    center?: boolean;
    /**
     * Use light font weight.
     */
    light?: boolean;
    /**
     * Use regular font weight.
     */
    regular?: boolean;
    /**
     * Use medium font weight.
     */
    medium?: boolean;
    /**
     * Use semi-bold font weight.
     */
    semiBold?: boolean;
    /**
     * Use bold font weight.
     */
    bold?: boolean;
    /**
     * Render text with strikethrough.
     */
    strikeThrough?: boolean;
    /**
     * Truncate overflowing text.
     */
    truncate?: boolean;
    /**
     * Line height to be same as font size.
     */
    lineHeightAsFontSize?: boolean;
    /**
     * Letter spacing for text.
     */
    letterSpacing?: 'normal' | 'mini' | 'small' | 'medium' | 'large' | 'huge';
    /**
     * Handle new lines symbols
     */
    multiline?: boolean;
    /**
     * Hyphenate text if it spans multiple lines.
     * @see {@link https://developer.mozilla.org/en-US/docs/Web/CSS/hyphens}
     */
    hyphenated?: boolean;
    /**
     * The error style of text
     */
    error?: boolean;
    /**
     * Italic style
     */
    italic?: boolean;
    /**
     * Break All word
     */
    wordBreakAll?: boolean;
    /**
     * The color of text
     */
    color?: 'success' | 'danger' | 'light' | 'info';
}

export interface HyphenatedTypographyProps {
    children?: string;
    /**
     * Hyphenate text if it spans multiple lines.
     * @see {@link https://developer.mozilla.org/en-US/docs/Web/CSS/hyphens}
     */
    hyphenated: true;
}

/**
 * Typography Props
 */
export type TypographyProps =
    | (BaseTypographyProps & {
          /**
           * Hyphenate text if it spans multiple lines.
           * @see {@link https://developer.mozilla.org/en-US/docs/Web/CSS/hyphens}
           */
          hyphenated?: false;
      })
    | (BaseTypographyProps & HyphenatedTypographyProps);

export const computeTypographyClasses = (
    props: TypographyProps,
    styles: Record<string, string>
) => {
    return Object.entries(props).reduce((result, [key, value]) => {
        if (isString(value)) {
            return { ...result, [styles[`${value}${capitalize(key)}`]]: value };
        }
        return { ...result, [styles[key]]: value };
    }, {});
};
